<style scoped>
@media only screen and (min-width: 600px) {
  .look_model {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
  }

  .look_model .swiper-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 86%;
    padding: 0 0 100px 0;
  }
  .look_model .swiper-container .swiper-slide {
    width: 100%;
  }
  .look_model .swiper-container .swiper-slide img {
    width: 60%;
    height: 60vh;
    border-radius: 0.2rem;
    object-fit: contain;
  }
  .number {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 0.32rem;
    white-space: nowrap;
  }
  .video_box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 702px;
    height: 421px;
    margin: 0.4rem auto 0;
    border-radius: 0.15rem;
    /* overflow: hidden; */
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.15rem;
  }
  .fun_btn {
    position: relative;
    padding: 10px 20px 10px 50px;
    background-color: rgba(0, 0, 0, 0.58);
    color: white;
    font-size: 20px;
    border-radius: 20px;
    margin: 0 80px;
    cursor: pointer;
  }
  .fun_btn_search_icon,
  .fun_btn_upload_icon {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
    background: url("../../assets/img/搜索.png") no-repeat top;
    background-size: 100% 100%;
  }

  .fun_btn_upload_icon {
    background: url("../../assets/img/下载.png") no-repeat top;
    background-size: 100% 100%;
  }
  .nextVideoTips {
    position: absolute;
    top: -30px;
    left: 0;
    color: white;
    font-size: 24px;
    white-space: nowrap;
  }
}

/* 移动端样式 */
@media only screen and (max-width: 600px) {
  .look_model {
    position: fixed;
    top: 0;
    left: 0;
    width: 7.5rem;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
  }
  .look_model .swiper-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 1rem 0;
  }
  .look_model .swiper-container .swiper-slide {
    width: 100%;
  }
  .look_model .swiper-container .swiper-slide img {
    width: 100%;
    height: 70vh;
    border-radius: 0.2rem;
    object-fit: contain;
  }
  .number {
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 0.32rem;
    white-space: nowrap;
  }
  .video_box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7.02rem;
    height: 4.21rem;
    margin: 0.4rem auto 0;
    border-radius: 0.15rem;
    overflow: hidden;
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.15rem;
  }
}
</style>
<template>
  <div class="look_model" @click.stop="goTolists">
    <div v-if="messageInfoDetails.category == 1">
      <div
        class="video_box"
        ref="obtain"
        @click.stop=""
        v-if="messageInfoDetails.tabType != 'video'"
      >
        <video
          class="video"
          ref="videoPlayer_4"
          :poster="messageInfoDetails.cover"
          controls
          controlslist="nodownload"
          preload
          x5-playsinline
          playsinline
          webkit-playsinline
          loop
          :src="messageInfoDetails.linkUrl[0]"
        >
        </video>
      </div>
      <div
        class="video_box"
        ref="obtain"
        @click.stop=""
        v-if="messageInfoDetails.tabType == 'video'"
      >
        <div class="nextVideoTips" v-if="isShowNextVideoTips">
          <div v-if="ali_jiashu_lang == 'cn'">
            即将播放：{{
              messageInfoDetails.videoArr[((messageInfoDetails.index + 1) > (messageInfoDetails.videoArr.length - 1 ) ? 0 : (messageInfoDetails.index + 1))].title
            }}
          </div>
          <div v-if="ali_jiashu_lang == 'en'">
            Coming soon：{{
              messageInfoDetails.videoArr[((messageInfoDetails.index + 1) > (messageInfoDetails.videoArr.length - 1 ) ? 0 : (messageInfoDetails.index + 1))].title_en
            }}
          </div>
        </div>
        <div id="video-box">
          <video
            id="typeVideo"
            class="video"
            ref="videoPlayer_4"
            :poster="videodemocover"
            controls
            controlslist="nodownload"
            preload
            x5-playsinline
            playsinline
            webkit-playsinline
            v-if="isShowVideoDemo"
            :src="videodemoVideoUrl"
          >
          </video>
        </div>
      </div>
    </div>
    <div v-if="messageInfoDetails.category == 2" @click.stop="">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide
          v-for="(item, index) in messageInfoDetails.linkUrl"
          :key="index"
        >
          <img :src="item" v-if="!imageUrl" />
          <img :src="imageUrl" v-if="imageUrl" />
          <div class="number">
            <div v-if="messageInfoDetails.linkUrl.length != 1">
              {{ index + 1 }} / {{ messageInfoDetails.linkUrl.length }}
            </div>
            <div class="fun_btn_box flex">
              <div
                class="fun_btn"
                @click.stop="goToImage(messageInfoDetails.imgurl)"
                :src="item"
                v-if="!imageUrl"
              >
                <div class="fun_btn_search_icon"></div>
                {{
                  ali_jiashu_lang == "cn" ? "查看原图" : "View Original Image"
                }}
              </div>
              <div
                class="fun_btn"
                @click.stop="
                  uploadImage(
                    messageInfoDetails.imgurl,
                    messageInfoDetails.name
                  )
                "
              >
                <div class="fun_btn_upload_icon"></div>
                {{ ali_jiashu_lang == "cn" ? "下载图片" : "Download" }}
              </div>
            </div>
          </div>
        </swiper-slide>
        <!-- <div
          @click.stop=""
          class="swiper-button-prev"
          slot="button-prev"
          v-if="!this.isMobile"
        ></div>
        <div
          @click.stop=""
          class="swiper-button-next"
          slot="button-next"
          v-if="!this.isMobile"
        ></div> -->
      </swiper>
    </div>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: ["messageInfoDetails", "isShowLookImgPcModel"],
  data() {
    return {
      ali_jiashu_lang: "cn",
      imageUrl: "", // 原图链接
      swiperOption: {
        loop: false,
        // 设置点击箭头
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
      },
      isMobile: false,
      isShowNextVideoTips: false, // 是否出现下一个视频名字提示
      isShowVideoDemo: true, // 视频容器
      videodemocover: "",
      videodemoVideoUrl: "",
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    // 判断当前使用端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    goTolists() {
      this.$emit("changeLookimgPcShow", false);
    },
    // 查看原图
    goToImage(imgUrl) {
      // window.open(imgUrl, '_blank', 'toolbar=yes, width=900, height=700')
      console.log(imgUrl);
      this.imageUrl = imgUrl;
    },
    // 下载图片
    uploadImage(imgUrl, name) {
      let _this = this;
      window.URL = window.URL || window.webkitURL;
      var xhr = new XMLHttpRequest();
      xhr.open("get", imgUrl, true);
      // 至关重要
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (this.status == 200) {
          //得到一个blob对象
          var blob = this.response;
          console.log("blob", blob);
          // 至关重要
          let oFileReader = new FileReader();
          oFileReader.onloadend = function (e) {
            let base64 = e.target.result;
          };
          oFileReader.readAsDataURL(blob);
          //====为了在页面显示图片，可以删除====
          var img = document.createElement("img");
          img.onload = function (e) {
            window.URL.revokeObjectURL(img.src); // 清除释放
          };
          let src = window.URL.createObjectURL(blob);
          console.log(src);
          _this.download(src, name);
        } else {
          console.log("sssss");
        }
      };
      xhr.send();
    },
    download(href, name) {
      let eleLink = document.createElement("a"); // 创建一个a标签
      eleLink.download = name; // 下载命名
      eleLink.href = href; // 下载地址
      eleLink.click(); // 模拟点击
      eleLink.remove(); // 模拟点击移除
    },

    // 监听视频剩十秒
    cuteDown(video) {
      let that = this;
      var timeDisplay;
      var duration;
      //用秒数来显示当前播放进度
      timeDisplay = Math.floor(video.currentTime); // 当前播放的时长
      duration = Math.floor(video.duration); // 总时长
      if (duration - timeDisplay < 10) {
        console.log("还有" + (duration - timeDisplay)  + "秒，播放完当前视频");
        that.isShowNextVideoTips = true;
      }else{
        that.isShowNextVideoTips = false;
      }
    },
    // 监听视频播放完了
    videoEnd(video) {
      let that = this;
      console.log("播放结束了");
      video.pause();
      that.isShowVideoDemo = false;
      if (
        that.messageInfoDetails.index <
        that.messageInfoDetails.videoArr.length - 1
      ) {
        that.messageInfoDetails.index += 1;
      } else {
        that.messageInfoDetails.index = 0;
      }
      that.isShowNextVideoTips = false;
      that.videodemocover =
        that.messageInfoDetails.videoArr[that.messageInfoDetails.index].cover;
      that.videodemoVideoUrl =
        that.messageInfoDetails.videoArr[that.messageInfoDetails.index].video;
      setTimeout(() => {
        that.isShowVideoDemo = true;
        setTimeout(() => {
          var video = document.getElementById("typeVideo");
          console.log(video);
          //使用事件监听方式捕捉事件
          video.addEventListener(
            "timeupdate",
            function () {
              that.cuteDown(video);
            },
            false
          );
          //使用事件监听方式捕捉事件
          video.addEventListener(
            "ended",
            function () {
              that.videoEnd(video);
            },
            false
          );
          video.play();
        }, 100);
      }, 100);
    },
  },
  mounted() {
    let that = this;
    console.log(that.messageInfoDetails);
    if (that.messageInfoDetails.tabType == "video") {
      console.log("1");
      var video = document.getElementById("typeVideo");
      console.log(video);
      //使用事件监听方式捕捉事件
      video.addEventListener(
        "timeupdate",
        function () {
          that.cuteDown(video);
        },
        false
      );

      //使用事件监听方式捕捉事件
      video.addEventListener(
        "ended",
        function () {
          that.videoEnd(video);
        },
        false
      );
    }
  },
  created() {
    this.ali_jiashu_lang =
      window.localStorage.getItem("ali_jiashu_lang") || "cn";
    console.log("pcpcpcpcmessageInfoDetails");
    console.log(this.messageInfoDetails);
    this.videodemocover = this.messageInfoDetails.cover[0];
    this.videodemoVideoUrl = this.messageInfoDetails.linkUrl[0];
    // console.log(this.isShowLookImgPcModel);
    if (this._isMobile()) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  beforeDestroy() {},
};
</script>