<style scoped>
@media only screen and (min-width: 600px) {
  .page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff7f0;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 99;
  }
  .details_bg {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 7.5rem;
    height: 7.73rem;
    background: url("https://img.alicdn.com/imgextra/i3/O1CN01ixbbg21Jb712EgPt3_!!6000000001046-2-tps-750-773.png")
      no-repeat top;
    background-size: 100% 100%;
    z-index: -1;
  }
  .details {
    width: 7.02rem;
    /* height: 5.45rem; */
    background-color: #fff;
    border-radius: 0.15rem;
    margin: 0.3rem auto 0;
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
    padding: 0 0 0.15rem;
    overflow: hidden;
  }
  .details_user {
    margin: 0.3rem 0 0 0.3rem;
  }
  .details_user_avater {
    width: 0.44rem;
    height: 0.44rem;
    border-radius: 50%;
    overflow: hidden;
  }
  .details_user_avater img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .details_user_name {
    font-size: 0.24rem;
    color: black;
    opacity: 0.9;
    line-height: 0.44rem;
    margin: 0 0 0 0.1rem;
  }
  .details_desc {
    width: 6.5rem;
    margin: 0.2rem auto 0.2rem;
    color: black;
    /* font-size: 0.24rem; */
    font-size: 15px;
    text-align: justify;
    line-height: 0.36rem;
    word-break: break-all;
  }
  .details_video {
    position: relative;
    /* width: 2.32rem; */
    /* height: 2.32rem; */
    border-radius: 0.15rem;
    overflow: hidden;
    margin: 0.2rem 0.25rem 0;
  }
  /* .details_video img {
    display: block;
    width: 100%;
    height: 100%;
  } */
  .details_mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .details_play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.65rem;
    height: 0.65rem;
    background: url("../../assets/img/play.png") no-repeat top;
    background-size: contain;
  }
  .details_img_box {
    margin: 0.2rem 0.25rem 0;
  }
  .details_img {
    width: 2.1rem;
    height: 2.1rem;
    border-radius: 0.15rem;
    margin: 0 0 0.15rem 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .details_img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .return_list_box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.38rem;
    /* background-color: rgba(0, 0, 0, 0.4); */
  }
  .return_list {
    position: absolute;
    bottom: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 2.8rem;
    height: 0.92rem;
    /* background: url("../../assets/img/return_list.png") no-repeat center; */
    background: url("https://img.alicdn.com/imgextra/i4/O1CN01lftXl01Zlg0J0cLqT_!!6000000003235-2-tps-280-92.png")
      no-repeat center;
    background-size: 100% 100%;
    color: white;
    font-size: 18px;
    line-height: 0.92rem;
    text-align: center;
    white-space: nowrap;
  }
}

/* 移动端样式 */
@media only screen and (max-width: 600px) {
  .page {
    position: fixed;
    top: 0;
    left: 0;
    width: 7.5rem;
    background-color: #fff7f0;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: 99;
  }
  .details_bg {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 7.5rem;
    height: 7.73rem;
    background: url("https://img.alicdn.com/imgextra/i3/O1CN01ixbbg21Jb712EgPt3_!!6000000001046-2-tps-750-773.png")
      no-repeat top;
    background-size: 100% 100%;
    z-index: -1;
  }
  .details {
    width: 7.02rem;
    /* height: 5.45rem; */
    background-color: #fff;
    border-radius: 0.15rem;
    margin: 0.3rem auto 2rem;
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
    padding: 0 0 0.15rem;
    overflow: hidden;
  }
  .details_user {
    margin: 0.3rem 0 0 0.3rem;
  }
  .details_user_avater {
    width: 0.44rem;
    height: 0.44rem;
    border-radius: 50%;
    overflow: hidden;
  }
  .details_user_avater img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .details_user_name {
    font-size: 0.24rem;
    color: black;
    opacity: 0.9;
    line-height: 0.44rem;
    margin: 0 0 0 0.1rem;
  }
  .details_desc {
    width: 6.5rem;
    margin: 0.2rem auto 0.2rem;
    color: black;
    /* font-size: 0.24rem; */
    font-size: 15px;
    text-align: justify;
    line-height: 0.36rem;
    word-break: break-all;
  }
  .details_video {
    position: relative;
    /* width: 2.32rem; */
    /* height: 2.32rem; */
    border-radius: 0.15rem;
    overflow: hidden;
    margin: 0.2rem 0.25rem 0;
    /* margin: 0.2rem auto 0; */
  }
  /* .details_video img {
    display: block;
    width: 100%;
    height: 100%;
  } */
  .details_img_box {
    margin: 0.2rem 0.25rem 0;
  }
  .details_img {
    /* width: 2.1rem; */
    /* height: 2.1rem; */
    border-radius: 0.15rem;
    margin: 0 0 0.15rem 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  }
  .details_img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .return_list_box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.38rem;
    /* background-color: rgba(0, 0, 0, 0.4); */
  }
  .return_list {
    position: absolute;
    bottom: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 2.8rem;
    height: 0.92rem;
    /* background: url("../../assets/img/return_list.png") no-repeat center; */
    background: url("https://img.alicdn.com/imgextra/i4/O1CN01lftXl01Zlg0J0cLqT_!!6000000003235-2-tps-280-92.png")
      no-repeat center;
    background-size: 100% 100%;
    color: white;
    font-size: 18px;
    line-height: 0.92rem;
    text-align: center;
    white-space: nowrap;
  }
  .video_box {
    position: relative;
    /* width: 6.5rem; */
    /* height: 4.21rem; */
    border-radius: 0.15rem;
    overflow: hidden;
  }
  .video {
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    border-radius: 0.15rem;
  }

  .details_mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 6.5rem;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .details_mask img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .details_play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.65rem;
    height: 0.65rem;
    background: url("../../assets/img/play.png") no-repeat top;
    background-size: 100% 100%;
  }
}
</style>
<template>
  <div class="page">
    <div class="details_bg"></div>
    <div class="details">
      <div class="details_user flex">
        <div class="details_user_avater">
          <img :src="messageInfoDetails.avatar" />
        </div>
        <div class="details_user_name">{{ messageInfoDetails.nickname }}</div>
      </div>
      <div class="details_desc">
        {{ messageInfoDetails.detail }}
      </div>
      <!-- 图片 -->
      <div
        class="details_img_box flex flex-wrap justify-content-between"
        v-if="messageInfoDetails.category == 2"
      >
        <div
          class="details_img"
          v-for="(item, index) in messageInfoDetails.linkUrl"
          :key="index"
        >
          <img :src="item" />
        </div>
      </div>

      <!-- 视频 -->
      <div class="details_video" v-else>
        <!-- <img
          src="https://img.alicdn.com/imgextra/i1/O1CN01p0aiaW1zOwLk8gpvz_!!6000000006705-2-tps-662-372.png"
        /> -->
        <div class="video_box" ref="obtain" @click.stop="">
          <!-- :poster="messageInfoDetails.cover" -->
          <video
            class="video"
            controls
            ontrolslist="nodownload"
            ref="videoPlayer_2"
            preload
            x5-playsinline
            playsinline
            webkit-playsinline
            loop
            :src="messageInfoDetails.linkUrl[0]" 
          >
          </video>
        </div>
        <div class="details_mask" v-if="isPlayVideoMask">
          <img :src="messageInfoDetails.cover" />
          <div class="details_play" @click="plsyVideo()"></div>
        </div>
      </div>
    </div>
    <div class="return_list_box">
      <div class="return_list" @click="goTolists()">
        {{ ali_jiashu_lang == "cn" ? "返回首页" : "Return" }}
      </div>
    </div>
  </div>
</template>

<script>
import * as dd from "dingtalk-jsapi";
export default {
  components: {},
  props: ["messageInfoDetails", "isShowdetailsModel"],
  data() {
    return {
      ali_jiashu_lang: "cn",
      isPlayVideoMask: true,
      messageInfoDetails: null
    };
  },
  methods: {
    goTolists() {
      this.messageInfoDetails = null
      var ua = navigator.userAgent.toLowerCase();
      var isWeixin = ua.indexOf("micromessenger") != -1;
      if (isWeixin) {
        this.getJsSDKConfig();
      }
      if (dd.env.platform != "notInDingTalk") {
        console.log("执行钉钉授权");
        this.sharedingding();
      }
      console.log("重置分享回首页");
      this.$emit("changeComponentShow", false);
    },
    // 点击播放视频
    plsyVideo() {
      this.isPlayVideoMask = false;
      this.$refs.videoPlayer_2.play();
    },
    // 获取微信sdk，注册分享
    getJsSDKConfig() {
      console.log("注册微信分享");
      let that = this;
      that.$axios
        .post("/api/auth/getWeiXinJSSdk", {
          url: location.href.split("#")[0],
        })
        .then((res) => {
          if (res.status === 200) {
            let JsSDKConfig = res.data;
            wx.config({
              beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
              debug: false,
              appId: JsSDKConfig.appId,
              timestamp: JsSDKConfig.timestamp,
              nonceStr: JsSDKConfig.nonceStr,
              signature: JsSDKConfig.signature,
              jsApiList: ["updateAppMessageShareData", "onMenuShareTimeline"],
            });
            wx.ready(function () {
              // console.log(window.localStorage.getItem("ali_jiashu_lang"));
              let title_text = "";
              let desc_text = "";
              if (window.localStorage.getItem("ali_jiashu_lang") == "en") {
                title_text = "More content on A Letter Home digital version.";
                desc_text = "Click to view";
              } else {
                title_text = "点击开启《2021阿里家书》";
                desc_text = "这一年的精彩瞬间，想第一时间与你分享";
              }
              // console.log(title_text,desc_text);
              // 转发
              wx.updateAppMessageShareData({
                title: title_text, // 分享标题
                desc: desc_text, // 分享描述
                link: "https://jiashu.alibaba.com#/", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl:
                  "https://img.alicdn.com/imgextra/i4/O1CN01hsTdS629If4vKEXIk_!!6000000008045-0-tps-1200-1200.jpg", // 分享图标
                success: function () {},
              });
              wx.onMenuShareTimeline({
                title: title_text, // 分享时的标题
                link: "https://jiashu.alibaba.com#/", // 分享时的链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl:
                  "https://img.alicdn.com/imgextra/i4/O1CN01hsTdS629If4vKEXIk_!!6000000008045-0-tps-1200-1200.jpg", // 分享时显示的图标
                //用户确认分享后执行的回调函数
                success: function () {},
                //用户取消分享后执行的回调函数
                cancel: function () {},
              });
            });
          }
        });
    },
    // 钉钉分享
    sharedingding() {
      console.log("注册钉钉分享");
      let title_text = "";
      let desc_text = "";
      if (window.localStorage.getItem("ali_jiashu_lang") == "en") {
        title_text = "More content on A Letter Home digital version.";
        desc_text = "Click to view";
      } else {
        title_text = "点击开启《2021阿里家书》";
        desc_text = "这一年的精彩瞬间，想第一时间与你分享";
      }
      // console.log(title_text,desc_text);
      window.dd.ready(function () {
        window.dd.biz.navigation.setRight({
          show: true,
          control: true,
          text: "分享",
          onSuccess: function () {
            window.dd.biz.util.share({
              type: 0, //分享类型，0:全部组件 默认；1:只能分享到钉钉；2:不能分享，只有刷新按钮
              url: "https://jiashu.alibaba.com#/",
              title: title_text,
              content: desc_text,
              image:
                "https://img.alicdn.com/imgextra/i4/O1CN01hsTdS629If4vKEXIk_!!6000000008045-0-tps-1200-1200.jpg",
              onSuccess: function () {},
              onFail: function () {},
            });
          },
        });
      });
    },
  },
  mounted() {},
  created() {
    console.log("留言详情", this.messageInfoDetails || '');
    this.ali_jiashu_lang =
      window.localStorage.getItem("ali_jiashu_lang") || "cn";
  },
  beforeDestroy() {},
};
</script>