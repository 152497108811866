var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page"},[_c('div',{staticClass:"details_bg"}),(_vm.messageInfoDetails)?_c('div',{staticClass:"details"},[_c('div',{staticClass:"details_user flex"},[_c('div',{staticClass:"details_user_avater"},[_c('img',{attrs:{"src":_vm.messageInfoDetails.avatar ||
            'https://res.xiaoxxx.com/test/H5/UCAN/20211211cbfc4bf66cccbc56555432.png'}})]),_c('div',{staticClass:"details_user_name"},[_vm._v(_vm._s(_vm.messageInfoDetails.nickname))])]),_c('div',{staticClass:"details_desc"},[_vm._v(" "+_vm._s(_vm.messageInfoDetails.detail)+" ")]),(
        _vm.messageInfoDetails.category == 2 &&
        _vm.messageInfoDetails.linkUrl.length != 0
      )?_c('div',{staticClass:"details_img_box"},_vm._l((_vm.messageInfoDetails.linkUrl),function(item,index){return _c('div',{key:index,staticClass:"details_img"},[_c('img',{attrs:{"src":item}})])}),0):_vm._e(),(
        _vm.messageInfoDetails.category == 1 &&
        _vm.messageInfoDetails.linkUrl.length != 0
      )?_c('div',{staticClass:"details_video"},[_c('div',{ref:"obtain",staticClass:"video_box",on:{"click":function($event){$event.stopPropagation();}}},[_c('video',{ref:"videoPlayer_1",staticClass:"video",attrs:{"poster":_vm.messageInfoDetails.cover,"controls":"","controlslist":"nodownload","preload":"","x5-playsinline":"","playsinline":"","webkit-playsinline":"","loop":"","src":_vm.messageInfoDetails.linkUrl[0]}})]),(_vm.isPlayVideoMask)?_c('div',{staticClass:"details_mask"},[_c('img',{attrs:{"src":_vm.messageInfoDetails.cover}}),_c('div',{staticClass:"details_play",on:{"click":function($event){return _vm.plsyVideo()}}})]):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"return_list_box"},[_c('div',{staticClass:"return_list",on:{"click":function($event){return _vm.goTolists()}}},[_vm._v(" "+_vm._s(_vm.ali_jiashu_lang == "cn" ? "返回首页" : "Return")+" ")])]),(_vm.isShowUploadsuccess)?_c('div',{staticClass:"upload_success_mask",on:{"click":function($event){$event.stopPropagation();_vm.isShowUploadsuccess == false}}},[_c('div',{staticClass:"upload_success_center"},[_c('div',{staticClass:"upload_success_icon"}),_c('div',{staticClass:"upload_success_text"},[_vm._v(" "+_vm._s(_vm.ali_jiashu_lang == "cn" ? "发布成功" : "Comment submitted")+" ")]),_c('div',{staticClass:"upload_success_btn",on:{"click":function($event){$event.stopPropagation();return _vm.closeSuccess()}}},[_vm._v(" "+_vm._s(_vm.ali_jiashu_lang == "cn" ? "分享给家人" : "Share it with loved ones")+" ")]),_c('div',{staticClass:"upload_success_close",on:{"click":function($event){$event.stopPropagation();_vm.isShowUploadsuccess = false}}})])]):_vm._e(),(_vm.isShowShare)?_c('div',{staticClass:"upload_success_mask share_mask",on:{"click":function($event){_vm.isShowShare = false}}},[_c('div',{class:_vm.ali_jiashu_lang == 'cn' ? 'share_icon' : 'share_icon share_icon_en'})]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }