<style scoped>
@media only screen and (min-width: 600px) {
  .look_model {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
  }

  .look_model .swiper-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 86%;
    padding: 0 0 100px 0;
  }
  .look_model .swiper-container .swiper-slide {
    width: 100%;
  }
  .look_model .swiper-container .swiper-slide img {
    width: 60%;
    height: 60vh;
    border-radius: 0.2rem;
    object-fit: contain;
  }
  .number {
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 0.32rem;
    white-space: nowrap;
  }
  .video_box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 702px;
    height: 421px;
    margin: 0.4rem auto 0;
    border-radius: 0.15rem;
    overflow: hidden;
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.15rem;
  }
}

/* 移动端样式 */
@media only screen and (max-width: 600px) {
  .look_model {
    position: fixed;
    top: 0;
    left: 0;
    width: 7.5rem;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
  }
  .look_model .swiper-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 1rem 0;
  }
  .look_model .swiper-container .swiper-slide {
    width: 100%;
  }
  .look_model .swiper-container .swiper-slide img {
    width: 100%;
    height: 70vh;
    border-radius: 0.2rem;
    object-fit: contain;
  }
  .number {
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 0.32rem;
    white-space: nowrap;
  }
  .video_box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7.02rem;
    height: 4.21rem;
    margin: 0.4rem auto 0;
    border-radius: 0.15rem;
    overflow: hidden;
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.15rem;
  }
}
</style>
<template>
  <div class="look_model" @click="goTolists">
    <div>
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item, index) in classImgarr" :key="index"
          ><img :src="item.cover" />
          <div class="number">{{ index + 1 }} / {{ classImgarr.length }}</div>
        </swiper-slide>
        <div
          @click.stop=""
          class="swiper-button-prev"
          slot="button-prev"
          v-if="!this.isMobile"
        ></div>
        <div
          @click.stop=""
          class="swiper-button-next"
          slot="button-next"
          v-if="!this.isMobile"
        ></div>
      </swiper>
    </div>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: ["classImgarr", "isShowClassImgModel", "classImgarrIndex"],
  data() {
    return {
      swiperOption: {
        loop: false,
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      isMobile: false,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    // 判断当前使用端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    goTolists() {
      this.$emit("changeSeeClassimgShow", false);
    },
  },
  mounted() {
    // swiper  翻转至指定抡博页面
    if (this.$refs.mySwiper) {
      this.$refs.mySwiper.swiper.slideTo(this.classImgarrIndex, 0, true);
    }
  },
  created() {
    // console.log(this.classImgarr);
    // console.log(this.isShowClassImgModel);
    // console.log(this.classImgarrIndex);
    if (this._isMobile()) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  beforeDestroy() {},
};
</script>