import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import Toast from "./components/commen/toast.js";
import $ from "jquery";
import { _isMobile } from "./untils/tools.js";
import axios from 'axios'
import less from 'less'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VideoPlayer from 'vue-video-player'
require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')

Vue.use(VideoPlayer)

Vue.use(ElementUI);

// axios.defaults.baseURL = 'http://test.api.jiashu.xiaoxxx.cn';
// axios.defaults.baseURL = 'https://jiashu.alibaba.com/index.php';
axios.defaults.baseURL = 'https://139.196.126.120/index.php';
axios.defaults.baseURL = 'https://jiashu2021.alibaba.com/index.php';



Vue.use(Toast);
Vue.config.productionTip = false;
Vue.prototype.$axios = axios

window.jQuery = $;
window.$ = $;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");

// 设置rem
function setRem() {
  var deviceWidth = document.documentElement.clientWidth;
  if (deviceWidth > 750) {
    deviceWidth = 7.5 * 50;
  }

  document.documentElement.style.fontSize = deviceWidth / 7.5 + "px";

  var lastTouchEnd = 0;
  document.documentElement.addEventListener(
    "touchend",
    function (event) {
      var now = Date.now();
      if (now - lastTouchEnd <= 300) {
        event.preventDefault();
      }
      lastTouchEnd = now;
    },
    false
  );

  if (document.getElementsByTagName("meta")["viewport"]) {
    document.getElementsByTagName("meta")["viewport"].content =
      "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0";
  } else {
    var meta = document.createElement("meta");
    meta.content =
      "width=device-width, initial-scale=1.0, maximum-scale=1.0,minimum-scale=1.0, user-scalable=0";
    meta.name = "viewport";
    document.getElementsByTagName("head")[0].appendChild(meta);
  }
}
setRem();

// 获取微信sdk，注册分享
function getJsSDKConfig_lists() {
  console.log("注册微信分享");
  let that = this;
  axios.post("/api/auth/getWeiXinJSSdk", {
    url: location.href.split("#")[0],
  })
    .then((res) => {
      if (res.status === 200) {
        let JsSDKConfig = res.data;
        wx.config({
          beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
          debug: false,
          appId: JsSDKConfig.appId,
          timestamp: JsSDKConfig.timestamp,
          nonceStr: JsSDKConfig.nonceStr,
          signature: JsSDKConfig.signature,
          jsApiList: ["updateAppMessageShareData", "onMenuShareTimeline"],
        });
        wx.ready(function () {
          // console.log(window.localStorage.getItem("ali_jiashu_lang"));
          let title_text = "";
          let desc_text = "";
          if (window.localStorage.getItem("ali_jiashu_lang") == "en") {
            title_text = "More content on A Letter Home digital version.";
            desc_text = "Click to view";
          } else {
            title_text = "点击开启《2021阿里家书》";
            desc_text = "这一年的精彩瞬间，想第一时间与你分享";
          }
          // console.log(title_text,desc_text);
          // 转发
          wx.updateAppMessageShareData({
            title: title_text, // 分享标题
            desc: desc_text, // 分享描述
            link: "https://jiashu.alibaba.com#/", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: "https://img.alicdn.com/imgextra/i4/O1CN01hsTdS629If4vKEXIk_!!6000000008045-0-tps-1200-1200.jpg", // 分享图标
            success: function () { },
          });
          wx.onMenuShareTimeline({
            title: title_text, // 分享时的标题
            link: "https://jiashu.alibaba.com#/", // 分享时的链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
            imgUrl: "https://img.alicdn.com/imgextra/i4/O1CN01hsTdS629If4vKEXIk_!!6000000008045-0-tps-1200-1200.jpg", // 分享时显示的图标
            //用户确认分享后执行的回调函数
            success: function () { },
            //用户取消分享后执行的回调函数
            cancel: function () { },
          });
        });
      }
    });
}
// 钉钉分享
function sharedingding_lists() {
  console.log("注册钉钉分享");
  let title_text = "";
  let desc_text = "";
  if (window.localStorage.getItem("ali_jiashu_lang") == "en") {
    title_text = "More content on A Letter Home digital version.";
    desc_text = "Click to view";
  } else {
    title_text = "点击开启《2021阿里家书》";
    desc_text = "这一年的精彩瞬间，想第一时间与你分享";
  }
  // console.log(title_text,desc_text);
  window.dd.ready(function () {
    window.dd.biz.navigation.setRight({
      show: true,
      control: true,
      text: "分享",
      onSuccess: function () {
        window.dd.biz.util.share({
          type: 0, //分享类型，0:全部组件 默认；1:只能分享到钉钉；2:不能分享，只有刷新按钮
          url: "https://jiashu.alibaba.com#/",
          title: title_text,
          content: desc_text,
          image: "https://img.alicdn.com/imgextra/i4/O1CN01hsTdS629If4vKEXIk_!!6000000008045-0-tps-1200-1200.jpg",
          onSuccess: function () { },
          onFail: function () { },
        });
      },
    });
  });
}

sharedingding_lists();
getJsSDKConfig_lists();

//判断是否是移动端--存入缓存
if (_isMobile()) {
  sessionStorage.setItem('isMobile', true)
} else {
  sessionStorage.setItem('isMobile', false)
}

router.beforeEach((to, from, next) => {
  next()
});