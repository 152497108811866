<template>
  <transition name="fade">
    <div v-if="isShowToast" class="toast-container" @touchmove.prevent>
      <span class="toast-msg">{{ content }}</span>
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      isShowToast: true,
      content: "",
    };
  },
};
</script>

<style scoped>
.toast-container {
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  z-index: 999;
}
.toast-msg {
  position: fixed;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 25px;
  border-radius: 10px;
  font-size: 18px;
  line-height: 30px;
  background: rgba(0, 0, 0, 0.8);
  color: rgba(255, 255, 255, 0.8);
  text-align: center;
  z-index: 999;
  /* white-space: nowrap; */
}
</style>
