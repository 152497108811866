<style scoped>
@media only screen and (min-width: 600px) {
  .look_model {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
  }

  .look_model .swiper-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 86%;
    padding: 0 0 100px 0;
  }
  .look_model .swiper-container .swiper-slide {
    width: 100%;
  }
  .look_model .swiper-container .swiper-slide img {
    width: 60%;
    height: 60vh;
    border-radius: 0.2rem;
    object-fit: contain;
  }
  .number {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 0.32rem;
    white-space: nowrap;
  }
  .video_box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 702px;
    height: 421px;
    margin: 0.4rem auto 0;
    border-radius: 0.15rem;
    overflow: hidden;
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.15rem;
  }
  .fun_btn {
    position: relative;
    padding: 10px 20px 10px 50px;
    background-color: rgba(0, 0, 0, 0.58);
    color: white;
    font-size: 20px;
    border-radius: 20px;
    margin: 0 80px;
    cursor: pointer;
  }
  .fun_btn_search_icon,
  .fun_btn_upload_icon {
    position: absolute;
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
    width: 22px;
    height: 22px;
    background: url("../../assets/img/搜索.png") no-repeat top;
    background-size: 100% 100%;
  }

  .fun_btn_upload_icon {
    background: url("../../assets/img/下载.png") no-repeat top;
    background-size: 100% 100%;
  }
}

/* 移动端样式 */
@media only screen and (max-width: 600px) {
  .look_model {
    position: fixed;
    top: 0;
    left: 0;
    width: 7.5rem;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
  }
  .look_model .swiper-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 1rem 0;
  }
  .look_model .swiper-container .swiper-slide {
    width: 100%;
  }
  .look_model .swiper-container .swiper-slide img {
    width: 100%;
    height: 70vh;
    border-radius: 0.2rem;
    object-fit: contain;
  }
  .number {
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 0.32rem;
    white-space: nowrap;
  }
  .video_box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 7.02rem;
    height: 4.21rem;
    margin: 0.4rem auto 0;
    border-radius: 0.15rem;
    overflow: hidden;
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.15rem;
  }
}
</style>
<template>
  <div class="look_model" @click.stop="goTolists">
    <div>
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item, index) in classImgarr" :key="index">
          <img :src="item.cover" v-if="!item.imageUrl" />
          <img :src="item.image" v-if="item.imageUrl" />
          <div class="number">
            <div v-if="classImgarr.length != 1">
              {{ index + 1 }} / {{ classImgarr.length }}
            </div>
            <br />
            <div class="fun_btn_box flex">
              <div
                class="fun_btn"
                @click.stop="goToImage(item.image, index)"
                v-if="!item.imageUrl && isShowSee"
              >
                <div class="fun_btn_search_icon"></div>
                {{
                  ali_jiashu_lang == "cn" ? "查看原图" : "View Original Image"
                }}
              </div>
              <div
                class="fun_btn"
                @click.stop="uploadImage(item.image, item.name)"
              >
                <div class="fun_btn_upload_icon"></div>
                {{ ali_jiashu_lang == "cn" ? "下载图片" : "Download" }}
              </div>
            </div>
          </div>
        </swiper-slide>
        <div
          @click.stop=""
          class="swiper-button-prev"
          slot="button-prev"
          v-if="!this.isMobile"
        ></div>
        <div
          @click.stop=""
          class="swiper-button-next"
          slot="button-next"
          v-if="!this.isMobile"
        ></div>
      </swiper>
    </div>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: ["classImgarr", "isShowPcClassImgModel", "classImgarrIndex"],
  data() {
    return {
      ali_jiashu_lang: "cn",
      swiperOption: {
        allowTouchMove: false,
        loop: false,
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      isMobile: false,
      isShowSee: true,
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    // 判断当前使用端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    goTolists() {
      this.$emit("changeSeeClassimgPcShow", false);
    },
    // 查看原图
    goToImage(imgUrl, index) {
      this.isShowSee = false;
      // window.open(imgUrl, '_blank', 'toolbar=yes, width=900, height=700')
      console.log(imgUrl);
      this.classImgarr[index].imageUrl = imgUrl;
      console.log(this.classImgarr);
      this.isShowSee = true;
    },
    // 下载图片
    uploadImage(imgUrl, name) {
      let _this = this;
      window.URL = window.URL || window.webkitURL;
      var xhr = new XMLHttpRequest();
      xhr.open("get", imgUrl, true);
      // 至关重要
      xhr.responseType = "blob";
      xhr.onload = function () {
        if (this.status == 200) {
          //得到一个blob对象
          var blob = this.response;
          console.log("blob", blob);
          // 至关重要
          let oFileReader = new FileReader();
          oFileReader.onloadend = function (e) {
            let base64 = e.target.result;
          };
          oFileReader.readAsDataURL(blob);
          //====为了在页面显示图片，可以删除====
          var img = document.createElement("img");
          img.onload = function (e) {
            window.URL.revokeObjectURL(img.src); // 清除释放
          };
          let src = window.URL.createObjectURL(blob);
          console.log(src);
          _this.download(src, name);
        } else {
          console.log("sssss");
        }
      };
      xhr.send();
    },

    download(href, name) {
      let eleLink = document.createElement("a"); // 创建一个a标签
      eleLink.download = name; // 下载命名
      eleLink.href = href; // 下载地址
      eleLink.click(); // 模拟点击
      eleLink.remove(); // 模拟点击移除
    },
  },
  mounted() {
    // swiper  翻转至指定抡博页面
    if (this.$refs.mySwiper) {
      this.$refs.mySwiper.swiper.slideTo(this.classImgarrIndex, 0, true);
    }
  },
  created() {
    this.ali_jiashu_lang =
      window.localStorage.getItem("ali_jiashu_lang") || "cn";
    console.log(this.classImgarr);
    console.log(this.isShowPcClassImgModel);
    console.log(this.classImgarrIndex);
    if (this._isMobile()) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  beforeDestroy() {},
};
</script>