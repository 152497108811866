<style scoped>
@media only screen and (min-width: 600px) {
  .page {
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    height: 100vh;
    min-width: 1280px;
    min-height: 620px;
  }
}

/* 移动端样式 */
@media only screen and (max-width: 600px) {
  .page {
    position: fixed;
    top: 0;
    left: 0;
    width: 7.5rem;
    background-color: #fff7f0;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .upload {
    position: relative;
    width: 7.02rem;
    /* height: 5.45rem; */
    background-color: #fff;
    border-radius: 0.15rem;
    margin: 0.3rem auto 0;
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
    padding: 0.3rem 0.25rem 0.3rem;
    box-sizing: border-box;
    /* overflow: hidden; */
  }
  .content {
    position: relative;
    width: 6.5rem;
    height: 3.5rem;
    margin: 0.2rem 0 0;
    border: 0.02rem solid rgba(0, 0, 0, 0.1);
    border-radius: 0.15rem;
  }
  /* 6.5 3.5 */
  .subject {
    color: #ec6504;
    font-size: 0.3rem;
    text-align: left;
  }
  .content_textarea {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 96%;
    height: 90%;
    border: none;
    outline: none;
    resize: none;
    font-size: 0.3rem;
  }
  .content_num {
    position: absolute;
    bottom: 0.15rem;
    right: 0.25rem;
    color: rgba(0, 0, 0, 0.6);
    font-size: 0.22rem;
  }
  .subject_lists_item {
    height: 0.55rem;
    padding: 0 0.16rem;
    margin: 0.2rem 0.3rem 0 0;
    white-space: nowrap;
    font-size: 0.28rem;
    line-height: 0.6rem;
    color: #ec6504;
    font-weight: 500;
    border: 0.02rem solid #ec6504;
    border-radius: 0.55rem;
    box-shadow: 0 0 0.02rem #ec6504;
  }
  .subject_active {
    background-color: #ec6504;
    color: white;
  }
  .upload_input_btn {
    position: relative;
    width: 1.35rem;
    height: 1.35rem;
    margin: 0.35rem 0 0;
    background-color: #fdefe5;
    border-radius: 0.2rem;
  }
  .upload_input {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.53rem;
    height: 0.54rem;
    background: url("../assets/img/upliad_input.png") no-repeat top;
    background-size: 100% 100%;
  }
  .submit_btn {
    /* width: 5.5rem; */
    width: 2.8rem;
    height: 0.86rem;
    margin: 0.8rem auto 0;
    border-radius: 0.1rem;
    color: white;
    font-size: 0.32rem;
    font-weight: 700;
    text-align: center;
    line-height: 0.86rem;
    background-color: #ec6504;
    box-shadow: 0 0 0.06rem #ec6504;
  }
  .submit_btngrayscale {
    background-color: #c9c9c9;
    box-shadow: 0 0 0.06rem #c9c9c9;
  }

  .img_arr {
    margin: 0.25rem 0 0;
  }
  .img_item {
    position: relative;
    width: 2.05rem;
    height: 1.7rem;
    margin: 0 0.1rem 0.35rem 0;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 0.15rem;
    /* overflow: hidden; */
  }
  .img_item img {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 0.15rem;
    object-fit: cover;
  }
  .del_img {
    position: absolute;
    top: -0.26rem;
    right: -0.2rem;
    width: 0.36rem;
    height: 0.36rem;
    margin: 0.1rem;
    background: url("../assets/img/delimg.png") no-repeat top;
    background-size: 100% 100%;
  }
  /* .img_item:nth-last-child(1) .del_img { */
  /* display: none; */
  /* } */
  .setup_cover {
    position: relative;
    height: 0.3rem;
    margin: 0.4rem 0 0;
    padding: 0 0 0 0.02rem;
    text-align: left;
  }
  .setup_cover_block {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    font-size: 0.28rem;
    color: #ff6900;
    font-weight: bold;
  }
  .setup_cover_black {
    color: black;
  }
  .cover_icon {
    position: absolute;
    top: 0;
    left: 0;
    height: 0.34rem;
    background-color: #ff6900;
    padding: 0.05rem 0.1rem;
    color: white;
    font-size: 0.22rem;
    line-height: 0.34rem;
    border-radius: 0.1rem;
  }
  .setup_cover_mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.1rem;
    font-size: 0.26rem;
    color: white;
    text-align: center;
    line-height: 1.7rem;
    white-space: nowrap;
  }
  .upload_video {
    margin: 0.35rem 0 0.3rem;
  }
  .video {
    position: relative;
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 0.15rem;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .video video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.15rem;
  }
  .play_video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .play_video_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.45rem;
    height: 0.45rem;
    background: url("../assets/img/play.png") no-repeat top;
    background-size: 100% 100%;
  }
  .cover {
    position: relative;
    width: 1.4rem;
    height: 1.4rem;
    margin: 0 0 0 0.2rem;
    background: url("../assets/img/设置封面.png") no-repeat top;
    background-size: 100% 100%;
    border-radius: 0.15rem;
    overflow: hidden;
  }
  .cover_text {
    position: absolute;
    top: 67%;
    left: 50%;
    transform: translateX(-50%);
    font-weight: 500;
    color: #ff6900;
    font-size: 0.24rem;
    white-space: nowrap;
    text-align: center;
    z-index: 1;
  }
  .cover img {
    position: absolute;
    top: 0;
    left: 0;
    /* z-index: 2; */
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 0.15rem;
  }

  .uploadInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .upload_loading_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
  .upload_loading_mask_content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .upload_loading_mask_icon {
    position: absolute;
    top: 0;
    left: calc(50% - 0.9rem);
    width: 1.8rem;
    height: 1.8rem;
    background: url("../assets/img/uploading.png") no-repeat top;
    background-size: 100% 100%;
    animation: uploadinganimate 2s infinite linear;
  }
  @keyframes uploadinganimate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }

  .upload_loading_mask_text {
    margin-top: 2.4rem;
    font-size: 0.26rem;
    color: white;
    white-space: nowrap;
  }
  .upload_success_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
  .upload_success_center {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5.4rem;
    height: 3.6rem;
    background: url("https://img.alicdn.com/imgextra/i1/O1CN01UtSvHg22KlabUWKbF_!!6000000007102-2-tps-540-366.png")
      no-repeat top;
    background-size: 100% 100%;
  }
  .upload_success_icon {
    width: 0.52rem;
    height: 0.52rem;
    margin: 0.6rem auto 0;
    background: url("../assets/img/形状1873.png") no-repeat top;
    background-size: 100% 100%;
  }
  .upload_success_text {
    height: 0.38rem;
    margin: 0.6rem auto 0;
    color: black;
    text-align: center;
    white-space: nowrap;
    font-size: 0.32rem;
    line-height: 0.38rem;
  }
  .upload_success_btn {
    display: inline-block;
    height: 0.38rem;
    padding: 0.2rem 0.4rem;
    margin: 0.2rem auto 0;
    border-radius: 0.15rem;
    color: white;
    font-size: 0.32rem;
    line-height: 0.38rem;
    background-color: #ff6900;
  }
  .upload_success_close {
    position: absolute;
    bottom: -1.38rem;
    left: 50%;
    transform: translateX(-50%);
    width: 0.78rem;
    height: 0.78rem;
    margin: 0.6rem auto 0;
    background: url("../assets/img/关闭.png") no-repeat top;
    background-size: 100% 100%;
  }
  .share_icon {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    width: 3.72rem;
    height: 2.03rem;
    background: url("../assets/img/share.png") no-repeat top;
    background-size: 100% 100%;
  }
  .return_list_box {
    position: absolute;
    bottom: -3rem;
    left: 0;
    width: 100%;
    height: 1.38rem;
    /* background-color: rgba(0, 0, 0, 0.4); */
  }
  .return_list {
    position: absolute;
    bottom: 0.28rem;
    left: 50%;
    transform: translateX(-50%);
    width: 2.8rem;
    height: 0.92rem;
    /* background: url("../assets/img/return_list.png") no-repeat center; */
    /* background: url("https://img.alicdn.com/imgextra/i4/O1CN01lftXl01Zlg0J0cLqT_!!6000000003235-2-tps-280-92.png") */
    /* no-repeat center; */
    /* background-size: 100% 100%; */
    border: 2px solid #ff6900;
    color: #ff6900;
    font-size: 18px;
    line-height: 0.92rem;
    font-weight: bold;
    text-align: center;
    white-space: nowrap;
    box-sizing: border-box;
    border-radius: 0.5rem;
  }

  .upload_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .type_box {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
  }
  .uploadInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .upload_loading_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .upload_image,
  .upload_videocover {
    position: relative;
    width: 2.1rem;
    height: 2.1rem;
  }
  .upload_image {
    background: url("https://img.alicdn.com/imgextra/i2/O1CN01BLfuuH23h2SyqlTJs_!!6000000007286-2-tps-210-210.png")
      no-repeat top;
    background-size: 100% 100%;
  }
  .upload_videocover {
    background: url("https://img.alicdn.com/imgextra/i2/O1CN01yocn711xW9Ls7MkwX_!!6000000006450-2-tps-210-210.png")
      no-repeat top;
    background-size: 100% 100%;
  }
  .upload_image_text {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ec6504;
    font-size: 14px;
    white-space: nowrap;
    font-weight: bold;
  }
}
</style>
<template>
  <div class="page">
    <div class="upload">
      <div class="subject">{{ subject_text }}</div>
      <div class="content">
        <textarea
          class="content_textarea"
          v-model="conetnt"
          :placeholder="subject_text_placeholder"
          @change="inputContent()"
        ></textarea>
        <div
          class="content_num"
          :style="conetnt.length > 200 ? 'color:red' : ''"
        >
          {{ conetnt.length }}/200
        </div>
      </div>
      <div class="subject_lists flex flex-wrap">
        <div
          class="subject_lists_item"
          :class="check_subject_type == '1' ? ' subject_active' : ''"
          @click="check_subject('1')"
        >
          #{{
            ali_jiashu_lang == "cn"
              ? "晒晒我收到的家书"
              : "Here's my A Letter Home"
          }}
        </div>
        <div
          class="subject_lists_item"
          :class="check_subject_type == '2' ? ' subject_active' : ''"
          @click="check_subject('2')"
        >
          #{{
            ali_jiashu_lang == "cn"
              ? "2022想和家人做的事"
              : "Things I want to do with my family in 2022"
          }}
        </div>
        <div
          class="subject_lists_item"
          :class="check_subject_type == '3' ? ' subject_active' : ''"
          @click="check_subject('3')"
        >
          #{{
            ali_jiashu_lang == "cn"
              ? "2021感谢有你"
              : "Thank you for everything in 2021"
          }}
        </div>
      </div>
      <div v-if="isShowCheck" style="margin: 0.2rem 0 0">
        <div
          class="img_item"
          v-if="uploading_linkurl.length < 9"
          @click="isShowOneCheck"
        >
          <div class="upload_input"></div>
        </div>
      </div>
      <div v-else>
        <div class="setup_cover" v-if="type == '2'">
          <div
            v-if="ali_jiashu_lang == 'cn'"
            @click.stop="isShowSetupcover = !isShowSetupcover"
            :class="
              isShowSetupcover
                ? 'setup_cover_block setup_cover_black'
                : 'setup_cover_block'
            "
          >
            {{ isShowSetupcover ? "设置封面" : "设置封面&emsp;>" }}
          </div>
          <div
            v-if="ali_jiashu_lang == 'en'"
            @click.stop="isShowSetupcover = !isShowSetupcover"
            :class="
              isShowSetupcover
                ? 'setup_cover_block setup_cover_black'
                : 'setup_cover_block'
            "
          >
            {{ isShowSetupcover ? "Set cover" : "Set cover&emsp;>" }}
          </div>
        </div>
        <div class="upload_imgarr" v-if="type == '2'">
          <div class="img_arr flex flex-wrap justify-content-start">
            <div
              class="img_item"
              v-for="(item, index) in uploading_linkurl"
              :key="index"
            >
              <img :src="item" />
              <div
                class="del_img"
                @click.stop="delimg(index)"
                v-if="!isShowSetupcover"
              ></div>
              <div
                class="cover_icon"
                v-if="item == uploading_cover && !isShowSetupcover"
              >
                封面
              </div>
              <div
                class="setup_cover_mask"
                @click="setupCover(index)"
                v-if="isShowSetupcover"
              >
                请选择
              </div>
            </div>
            <div class="img_item" v-if="uploading_linkurl.length < 9">
              <div class="upload_input"></div>
              <input
                @click.stop=""
                type="file"
                class="uploadInput"
                @change="addImg"
                ref="inputer"
                multiple
                accept="image/*"
              />
            </div>
          </div>
        </div>
        <div class="upload_video" v-if="type == '1'">
          <div class="video_url flex">
            <div class="video">
              <video
                ref="videoPlayer"
                preload
                x5-playsinline
                playsinline
                webkit-playsinline
                loop
                :src="uploading_linkurl[0]"
              >
              </video>

              <div class="upload_input" v-if="!uploading_linkurl[0]"></div>
              <div
                v-if="uploading_linkurl[0]"
                :style="isPlayVideoMask ? '' : 'opacity: 0;'"
                class="play_video"
                @click="plsyVideo()"
              >
                <div class="play_video_icon"></div>
              </div>
              <input
                @click.stop=""
                type="file"
                class="uploadInput"
                @change="addVideo"
                ref="inputer"
                accept="video/*"
              />
            </div>
            <div class="cover">
              <div class="cover_text" v-if="!uploading_cover">
                {{ ali_jiashu_lang == "cn" ? "设置封面" : "Set cover" }}
              </div>
              <img :src="uploading_cover" v-if="uploading_cover" />
              <input
                @click.stop=""
                type="file"
                class="uploadInput"
                @change="addImgCover"
                ref="inputer"
                accept="image/*"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        :class="
          isShowSubmitBtnGray ? 'submit_btn' : 'submit_btn submit_btngrayscale'
        "
        @click="submitMessage()"
      >
        {{ this.ali_jiashu_lang == "cn" ? "提&emsp;&emsp;交" : "Submit" }}
      </div>

      <!-- 返回首页 -->
      <div class="return_list_box">
        <div class="return_list" @click="goTolistsNone()">
          {{ ali_jiashu_lang == "cn" ? "返回首页" : "Return" }}
        </div>
      </div>
    </div>

    <!-- 上传选择分类 -->
    <div class="upload_mask" v-if="isShowUpload" @click.stop="closeUpload()">
      <div class="type_box flex justify-content-between">
        <div class="upload_image" @click.stop="goToUpload('2')">
          <div class="upload_image_text">
            {{ ali_jiashu_lang == "cn" ? "发布图片" : "Post a photo" }}
          </div>
          <input
            @click.stop=""
            type="file"
            class="uploadInput"
            @change="addImg"
            ref="inputer"
            multiple
            accept="image/*"
          />
        </div>
        <div class="upload_videocover" @click.stop="goToUpload('1')">
          <div class="upload_image_text">
            {{ ali_jiashu_lang == "cn" ? "发布视频" : "Post a video" }}
          </div>
          <input
            @click.stop=""
            type="file"
            class="uploadInput"
            @change="addVideo"
            ref="inputer"
            accept="video/*"
          />
        </div>
      </div>
    </div>

    <!-- loading上传中 -->
    <div class="upload_loading_mask" v-if="isShowUploadLoading">
      <div class="upload_loading_mask_content">
        <div class="upload_loading_mask_icon"></div>
        <div class="upload_loading_mask_text">
          {{
            ali_jiashu_lang == "cn"
              ? "上传中，请勿离开当前页面"
              : "Uploading. Keep the page open"
          }}
        </div>
      </div>
    </div>

    <!-- 上传完成 -->
    <div
      class="upload_success_mask"
      v-if="isShowUploadsuccess"
      @click.stop="isShowUploadsuccess == false"
    >
      <div class="upload_success_center">
        <div class="upload_success_icon"></div>
        <div class="upload_success_text">
          {{ ali_jiashu_lang == "cn" ? "发布成功" : "Success" }}
        </div>
        <div class="upload_success_btn" @click.stop="closeSuccess()">
          {{
            ali_jiashu_lang == "cn" ? "分享给亲友" : "Share it with loved ones"
          }}
        </div>
        <div class="upload_success_close" @click.stop="goToLists()"></div>
        <!-- @click.stop="isShowUploadsuccess == false" -->
      </div>
    </div>

    <!-- 分享亲友提示 -->
    <div
      class="upload_success_mask share_mask"
      v-if="isShowShare"
      @click="isShowShare = false"
    >
      <div class="share_icon"></div>
    </div>
  </div>
</template>

<script>
import { getSignedUrl, create, getSTSToken, sensitive } from "@/api/api"; // 导入我们的api接口
import { formatTime } from "@/untils/tools"; // Function

export default {
  components: {},
  props: {},
  data() {
    return {
      ali_jiashu_lang: "cn",
      type: "2", // 1:视频， 2:图片
      subject_text: "#晒晒我收到的家书",
      check_subject_type: "1", // 1:我收到的家书 ， 2:想和家人做的事， 3:感谢有你
      subject_text_placeholder:
        "已经收到家书的亲们，新年好呀！期待你的开箱分享哦～",
      conetnt: "",
      uploading_linkurl: [],
      uploading_cover: "", // 封面图地址
      isShowSetupcover: false, // 是否显示请选择蒙层
      isPlayVideoMask: true, // 是否显示播放蒙层

      isShowUpload: false,
      isShowUploadLoading: false, // 是否显示上传loading
      bucket: "homebook-family",
      region: "oss-cn-shanghai",
      credentials: {
        access_key_id: null,
        access_key_secret: null,
        expiration: null,
        security_token: null,
      }, // STS凭证
      linkurlArr: [], // 已上传资源链接
      linkurlArrnum: 0, // 已上传资源链接
      isShowUploadsuccess: false, // 上传完成提示
      isShowShare: false, // 上传完成提示

      isShowCheck: true, // 是否显示选择分类
      isShowSubmitBtnGray: false, //提交按钮是否置灰

      isUpload: false , // 是否正在发布
    };
  },
  methods: {
    inputContent() {
      if (this.conetnt != "") {
        this.isShowSubmitBtnGray = true;
      }

      if (this.conetnt.length > 200) {
        this.isShowSubmitBtnGray = false;
      }
      if (this.uploading_linkurl.length == 0 && this.conetnt == "") {
        this.isShowSubmitBtnGray = false;
      }
    },
    //开启 选择类型 蒙层
    isShowOneCheck() {
      this.isShowUpload = true;
    },
    //关闭 选择类型 蒙层
    closeUpload() {
      this.isShowUpload = false;
    },
    goTolistsNone() {
      this.$router.replace({
        name: "lists",
      });
    },
    // 切换话题
    check_subject(str) {
      if (this.ali_jiashu_lang == "cn") {
        if (str == "1") {
          this.check_subject_type = "1";
          this.subject_text = "#晒晒我收到的家书";
          this.subject_text_placeholder =
            "已经收到家书的亲们，新年好呀！期待你的开箱分享哦～";
        }
        if (str == "2") {
          this.check_subject_type = "2";
          this.subject_text = "#2022想和家人做的事";
          this.subject_text_placeholder =
            "新的一年，晒晒想和家人一起解锁的事情吧！";
        }
        if (str == "3") {
          this.check_subject_type = "3";
          this.subject_text = "#2021感谢有你";
          this.subject_text_placeholder =
            "说说这一年呢你最想感谢的人，晒出你们的合影吧～";
        }
      }
      if (this.ali_jiashu_lang == "en") {
        if (str == "1") {
          this.check_subject_type = "1";
          this.subject_text = "#Here's my A Letter Home";
          this.subject_text_placeholder =
            "Share your A Letter Home gift box with the world";
        }
        if (str == "2") {
          this.check_subject_type = "2";
          this.subject_text = "#Things I want to do with my family in 2022";
          this.subject_text_placeholder =
            "Share the things you want to do together";
        }
        if (str == "3") {
          this.check_subject_type = "3";
          this.subject_text = "#Thank you for everything in 2021";
          this.subject_text_placeholder =
            "Which person are you most thankful for in 2021? Post a photo of you and them";
        }
      }
    },
    // 点击播放视频
    plsyVideo() {
      this.isPlayVideoMask = !this.isPlayVideoMask;
      if (this.isPlayVideoMask) {
        this.$refs.videoPlayer.pause();
      } else {
        this.$refs.videoPlayer.play();
      }
    },
    // 获取携带签名的图片/视频
    async getSignedUrl(obj, uploadtype) {
      let that = this;
      let res = await getSignedUrl({
        url: obj,
      });
      if (res.code == 0) {
        if (uploadtype == "nocover") {
          console.log(res);
          that.uploading_linkurl = that.uploading_linkurl.concat(res.data.url);
          if (this.type == 2) {
            that.uploading_cover = that.uploading_linkurl[0];
            console.log(that.uploading_cover);
          }
          console.log(that.uploading_linkurl);
          that.linkurlArrnum = 0;
        } else if (uploadtype == "cover") {
          that.uploading_cover = res.data.url[0];
          console.log(that.uploading_cover);
          that.linkurlArrnum = 0;
        } else if (uploadtype == "video") {
          console.log("video");
          that.uploading_linkurl = res.data.url;
          console.log(that.uploading_linkurl);
          that.linkurlArrnum = 0;
        }
        that.isShowUploadLoading = false;
        that.isShowSubmitBtnGray = true;
      } else {
        that.$toast(2000, "error");
      }
    },
    // 删除已上传图片
    delimg(index) {
      console.log(index);
      this.uploading_linkurl.splice(index, 1);
      // if(index == 0){
        // this.uploading_cover = this.uploading_linkurl[0];
      // }else{
        // this.uploading_cover = this.uploading_linkurl[0];
      // }
      if (this.uploading_linkurl.length != 0) {
        this.isShowSubmitBtnGray = true;
      }
      if (this.uploading_linkurl.length == 0 && this.conetnt == "") {
        this.isShowSubmitBtnGray = false;
      }
    },
    // 选择封面
    setupCover(index) {
      this.uploading_cover = this.uploading_linkurl[index];
      this.isShowSetupcover = false;
    },
    // 提交作品
    async submitMessage() {
      if (this.conetnt.length > 200) {
        this.isShowSubmitBtnGray = false;
        // this.$toast(
        //   2500,
        //   this.ali_jiashu_lang == "cn" ? "超出字数限制" : "Word limit exceeded"
        // );
        return;
      }
      let sensitiveres = await sensitive({
        word: this.conetnt,
      });
      if (sensitiveres.code == 0 && sensitiveres.data.words.length != 0) {
        this.$toast(
          2500,
          this.ali_jiashu_lang == "cn"
            ? "内容包含敏感词汇请重新编辑"
            : "Your comment contains sensitive words"
        );
        return;
      }
      let cover = "";
      let linkUrl = [];
      let topic = "";
      if (this.uploading_cover) {
        cover = this.uploading_cover.split("?security-token")[0];
      }

      if (this.uploading_linkurl.length > 0) {
        linkUrl = this.uploading_linkurl;
        for (let i = 0; i < linkUrl.length; i++) {
          linkUrl[i] = linkUrl[i].split("?security-token")[0];
        }
      }
      if (linkUrl.length == 0 && this.conetnt == "") {
        console.log("1"); // "详情或" + (this.type == 1 ? "视频" : "图片") + "至少填写一个"
        // this.isShowSubmitBtnGray = true
        return;
      }
      topic = this.subject_text;
      if (this.subject_text == "#Here's my A Letter Home") {
        topic = "#晒晒我收到的家书";
      }
      if (this.subject_text == "#Things I want to do with my family in 2022") {
        topic = "#2022想和家人做的事";
      }
      if (this.subject_text == "#Thank you for everything in 2021") {
        topic = "#2021感谢有你";
      }
      console.log("话题：", topic);
      console.log("详情：", this.conetnt);
      console.log("分类：", this.type);
      console.log("封面：", cover);
      console.log("文件：", linkUrl);
      if(this.isUpload){
        console.log('loading');
        return
      }
      this.isUpload = true
      let that = this;
      let res = await create({
        topic: topic,
        detail: this.conetnt,
        category: this.type,
        cover: cover,
        linkUrl: linkUrl,
      });
      if (res.code == 0) {
        localStorage.removeItem("uploadtype");
        localStorage.removeItem("uploadingLinkurl");
        // console.log("发布完成1.0，默认通过");
        // that.$router.replace({
        //   name: "details",
        //   params: {
        //     isUserUpload: true,
        //   },
        // });
        // console.log("发布完成2.0，默认不通过");
        this.$router.replace({
          name: "lists",
          params: {
            upload: true,
          },
        });
        this.isUpload = false
      } else {
        this.isUpload = false
        that.$toast(2000, "error");
      }
    },
    // 返回列表页面
    goToLists() {
      this.$router.replace({
        name: "lists",
        params: {
          upload: true,
        },
      });
    },
    // input——image——onchange
    addImg(event) {
      if (event.target.files.length > 0) {
        let file = event.target.files;
        // console.log('file',file);
        // file.forEach((element, index) => {
        //   let filetype = element.type.split('/')[1]
        //   let fileTypes = ['jpg','png','jpeg','bmp']
        //   if(fileTypes.indexOf(filetype) == -1) {
        //     this.$toast(2500, "文件格式不符合要求！");
        //     return
        //   }else{
        //     console.log('文件格式符合要求！');
        //   }
        // });
        let len = file.length + this.uploading_linkurl.length;
        if (len > 9) {
          this.$toast(
            1000,
            this.ali_jiashu_lang == "cn" ? "最多可上传9张" : "Max length 9"
          );
          return false;
        } else {
          this.isShowUpload = false;
          this.isShowUploadLoading = true;
          this.linkurlArr = [];
          getSTSToken()
            .then((res) => {
              if (res.code != 0) {
                this.$toast(2500, "用户登录已过期，请重新打开本页面");
                setTimeout(() => {
                  window.localStorage.removeItem("ali_jiashu_UserToken");
                  this.$router.replace({
                    name: "lists",
                  });
                }, 2500);
                return;
              }
              this.credentials = res.data.info;
              file.forEach((element, index) => {
                this.uploadfileImgarr(element, index, file.length, 2); // 参数： 文件、下标位置、总长度、类型（1:视频，2:图片）
              });
            })
            .catch((err) => {
              console.warn("获取阿里云STS失败！", err);
              this.$toast(2500, "用户登录已过期，请重新打开本页面");
              setTimeout(() => {
                window.localStorage.removeItem("ali_jiashu_UserToken");
                this.$router.replace({
                  name: "lists",
                });
              }, 2500);
            });
        }
      }
    },
    // input——image——onchange
    addImgCover(event) {
      if (event.target.files.length > 0) {
        let file = event.target.files;
        // console.log('file',file);
        // file.forEach((element, index) => {
        //   let filetype = element.type.split('/')[1]
        //   let fileTypes = ['jpg','png','jpeg','bmp']
        //   if(fileTypes.indexOf(filetype) == -1) {
        //     this.$toast(2500, "文件格式不符合要求！");
        //     return
        //   }else{
        //     console.log('文件格式符合要求！');
        //   }
        // });
        let len = file.length;
        this.isShowUpload = false;
        this.isShowUploadLoading = true;
        getSTSToken()
          .then((res) => {
            // console.log(file);
            // console.log(res);
            if (res.code != 0) {
              this.$toast(2500, "用户登录已过期，请重新打开本页面");
              setTimeout(() => {
                window.localStorage.removeItem("ali_jiashu_UserToken");
                this.$router.replace({
                  name: "lists",
                });
              }, 2500);
              return;
            }
            this.credentials = res.data.info;
            file.forEach((element, index) => {
              this.uploadfile(element, index, len, 2); // 参数： 文件、下标位置、总长度、类型（1:视频，2:图片）
            });
          })
          .catch((err) => {
            console.warn("获取阿里云STS失败！", err);
            this.$toast(2500, "用户登录已过期，请重新打开本页面");
            setTimeout(() => {
              window.localStorage.removeItem("ali_jiashu_UserToken");
              this.$router.replace({
                name: "lists",
              });
            }, 2500);
          });
      }
    },
    // input——video——onchange
    addVideo(event) {
      if (event.target.files.length > 0) {
        this.isShowUpload = false;
        this.isShowUploadLoading = true;
        this.linkurlArr = [];
        getSTSToken()
          .then((res) => {
            // console.log(res);
            if (res.code != 0) {
              this.$toast(2500, "用户登录已过期，请重新打开本页面");
              setTimeout(() => {
                window.localStorage.removeItem("ali_jiashu_UserToken");
                this.$router.replace({
                  name: "lists",
                });
              }, 2500);
              return;
            }
            this.credentials = res.data.info;
            let file = event.target.files;
            this.type = 1;
            this.uploadfileVideo(file[0], 0, 1, 1); // 参数： 文件、下标位置、总长度、类型（1:视频，2:图片）
          })
          .catch((err) => {
            console.warn("获取阿里云STS失败！", err);
            this.$toast(2500, "用户登录已过期，请重新打开本页面");
            setTimeout(() => {
              window.localStorage.removeItem("ali_jiashu_UserToken");
              this.$router.replace({
                name: "lists",
              });
            }, 2500);
          });
      } else {
        console.log("未选择");
      }
    },
    // 上传文件-封面图
    async uploadfile(work, index, count, type) {
      let that = this;
      console.log(type == 1 ? "视频" : "图片");
      const extensionName = work.name.substr(work.name.indexOf(".")); // 文件扩展名
      that.ossUploadVideoFile(work, extensionName, index, count, type);
    },
    // 上传-封面图
    ossUploadVideoFile(option, extensionName, index, count, type) {
      const that = this;
      const date = formatTime(new Date(), "YMD"); // 当前时间
      const dateTime = formatTime(new Date(), "YMDhms"); // 当前时间
      const randomStr = that.randomString(4); //  4位随机字符串
      const fileName =
        "file/" + date + "/" + dateTime + "_" + randomStr + extensionName; // 文件名字（相对于根目录的路径 + 文件名）
      // 执行上传
      this.commonUpload(option, fileName)
        .then((res) => {
          that.linkurlArr[index] = res.url;
          this.linkurlArrnum += 1;
          if (this.linkurlArrnum == count) {
            console.log(that.linkurlArr);
            this.getSignedUrl(that.linkurlArr, "cover");
            // localStorage.setItem( "uploadingLinkurl", JSON.stringify(that.linkurlArr));
            // localStorage.setItem("uploadtype", type);
            // that.isShowUploadLoading = false;
            // that.goToUpload();
            return;
          }
        })
        .catch((err) => {
          console.log(err);
          alert("哎呀，发送失败，请重启App后上传");
        });
    },
    // 上传文件-视频
    async uploadfileVideo(work, index, count, type) {
      let that = this;
      console.log(type == 1 ? "视频" : "图片");
      const extensionName = work.name.substr(work.name.indexOf(".")); // 文件扩展名
      that.ossUploadVideoFileVideo(work, extensionName, index, count, type);
    },
    // 上传-视频
    ossUploadVideoFileVideo(option, extensionName, index, count, type) {
      const that = this;
      const date = formatTime(new Date(), "YMD"); // 当前时间
      const dateTime = formatTime(new Date(), "YMDhms"); // 当前时间
      const randomStr = that.randomString(4); //  4位随机字符串
      const fileName =
        "file/" + date + "/" + dateTime + "_" + randomStr + extensionName; // 文件名字（相对于根目录的路径 + 文件名）
      // 执行上传
      this.commonUpload(option, fileName)
        .then((res) => {
          that.linkurlArr[index] = res.url;
          that.linkurlArrnum += 1;
          console.log(count);
          console.log(that.linkurlArrnum);
          if (that.linkurlArrnum == count) {
            console.log(that.linkurlArr);
            that.getSignedUrl(that.linkurlArr, "video");
            if (that.isShowCheck) {
              that.isShowCheck = false;
            }
            // localStorage.setItem( "uploadingLinkurl", JSON.stringify(that.linkurlArr));
            // localStorage.setItem("uploadtype", type);
            // that.isShowUploadLoading = false;
            // that.goToUpload();
            return;
          }
        })
        .catch((err) => {
          console.log(err);
          alert("哎呀，发送失败，请重启App后上传");
        });
    },
    // 上传文件-图片数组
    async uploadfileImgarr(work, index, count, type) {
      let that = this;
      console.log(type == 1 ? "视频" : "图片");
      const extensionName = work.name.substr(work.name.indexOf(".")); // 文件扩展名
      that.ossUploadVideoFileImgarr(work, extensionName, index, count, type);
    },
    // 上传-图片数组
    ossUploadVideoFileImgarr(option, extensionName, index, count, type) {
      const that = this;
      const date = formatTime(new Date(), "YMD"); // 当前时间
      const dateTime = formatTime(new Date(), "YMDhms"); // 当前时间
      const randomStr = that.randomString(4); //  4位随机字符串
      const fileName =
        "file/" + date + "/" + dateTime + "_" + randomStr + extensionName; // 文件名字（相对于根目录的路径 + 文件名）
      // 执行上传
      this.commonUpload(option, fileName)
        .then((res) => {
          console.log("that.linkurlArrnum", that.linkurlArrnum);
          console.log("count", count);
          that.linkurlArr[index] = res.url;
          that.linkurlArrnum += 1;
          if (that.linkurlArrnum == count) {
            console.log(that.linkurlArr);
            that.getSignedUrl(that.linkurlArr, "nocover");
            if (that.isShowCheck) {
              that.isShowCheck = false;
            }
            // that.isShowUploadLoading = false;
            return;
          }
        })
        .catch((err) => {
          console.log(err);
          alert("哎呀，发送失败，请重启App后上传");
        });
    },
    //创建随机字符串
    randomString(num) {
      const chars = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      let res = "";
      for (let i = 0; i < num; i++) {
        var id = Math.ceil(Math.random() * 35);
        res += chars[id];
      }
      return res;
    },
    // 普通上传
    async commonUpload(file, fileName) {
      if (!this.currentClient) {
        await this.createOssClient();
      }
      return new Promise((resolve, reject) => {
        return this.currentClient
          .put(fileName, file)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 获取STS Token
    getCredential() {
      return new Promise((resolve, reject) => {
        getSTSToken()
          .then((res) => {
            if (res.code != 0) {
              this.$toast(2500, "用户登录已过期，请重新打开本页面");
              setTimeout(() => {
                window.localStorage.removeItem("ali_jiashu_UserToken");
                this.$router.replace({
                  name: "lists",
                });
              }, 2500);
              return;
            }
            this.credentials = res.data.info;
          })
          .catch((err) => {
            console.warn("获取阿里云STS失败！", err);
            this.$toast(2500, "用户登录已过期，请重新打开本页面");
            setTimeout(() => {
              window.localStorage.removeItem("ali_jiashu_UserToken");
              this.$router.replace({
                name: "lists",
              });
            }, 2500);
          });
      });
    },
    // 创建oss客户端对象
    async createOssClient() {
      return new Promise((resolve, reject) => {
        if (!this.credentials.access_key_id) {
          console.log("重新获取");
          this.getCredential()
            .then((res) => {
              this.currentClient = new OSS({
                region: this.region,
                accessKeyId: this.credentials.access_key_id,
                accessKeySecret: this.credentials.access_key_secret,
                stsToken: this.credentials.security_token,
                bucket: this.bucket,
                secure: true,
              });
              resolve(this.currentClient);
            })
            .catch((err) => {
              reject(err);
              console.log("getCredentialErr", err);
            });
        } else {
          console.log("已有不获取");
          this.currentClient = new OSS({
            region: this.region,
            accessKeyId: this.credentials.access_key_id,
            accessKeySecret: this.credentials.access_key_secret,
            stsToken: this.credentials.security_token,
            bucket: this.bucket,
            secure: true,
          });
          resolve(this.currentClient);
        }
      });
    },
    // 关闭上传成功提示，展示分享提示
    closeSuccess() {
      this.isShowShare = true;
      this.isShowUploadsuccess = false;
    },
  },
  mounted() {
    // let type = localStorage.getItem("uploadtype") || "2";
  },
  created() {
    this.ali_jiashu_lang =
      window.localStorage.getItem("ali_jiashu_lang") || "cn";
    if (this.ali_jiashu_lang == "en") {
      this.subject_text = "#Here's my A Letter Home";
      this.subject_text_placeholder =
        "Share your A Letter Home gift box with the world";
    }
  },
  beforeDestroy() {},
};
</script>