<style scoped>
@media only screen and (min-width: 600px) {
  .look_model {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
  }

  .look_model .swiper-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 86%;
    padding: 0 0 100px 0;
  }
  .look_model .swiper-container .swiper-slide {
    width: 100%;
  }
  .look_model .swiper-container .swiper-slide img {
    width: 60%;
    height: 60vh;
    border-radius: 0.2rem;
    object-fit: contain;
  }
  .number {
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 0.32rem;
    white-space: nowrap;
  }
  .video_box {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 702px;
    height: 421px;
    margin: 0.4rem auto 0;
    border-radius: 0.15rem;
    overflow: hidden;
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.15rem;
  }
}

/* 移动端样式 */
@media only screen and (max-width: 600px) {
  .look_model {
    position: fixed;
    top: 0;
    left: 0;
    width: 7.5rem;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
  }
  .look_model .swiper-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    padding: 1rem 0;
  }
  .look_model .swiper-container .swiper-slide {
    width: 100%;
  }
  .look_model .swiper-container .swiper-slide img {
    width: 100%;
    height: 70vh;
    border-radius: 0.2rem;
    object-fit: contain;
  }
  .number {
    position: absolute;
    bottom: -0.5rem;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    font-size: 0.32rem;
    white-space: nowrap;
  }
  .video_box {
    position: fixed;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 7.02rem; */
    width: 100%;
    /* height: 4.21rem; */
    height: 90%;
    margin: 0.4rem auto 0;
    border-radius: 0.15rem;
    /* overflow: hidden; */
  }
  .video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.15rem;
  }
  .close_icon {
    position: fixed;
    top: 0.1rem;
    right: 00.1rem;
    width: 0.78rem;
    height: 0.78rem;
    background: url("../../assets/img/关闭.png") no-repeat top;
    background-size: 100% 100%;
  }

  .nextVideoTips {
    position: absolute;
    top: -30px;
    left: 0;
    color: white;
    font-size: 14px;
    white-space: nowrap;
    padding: 0 0 0 16px;
  }
}
</style>
<template>
  <div class="look_model" id="look_model" @click.stop="goTolists">
    <div v-if="messageInfoDetails.category == 1">
      <div
        class="video_box"
        ref="obtain"
        @click.stop=""
        v-if="messageInfoDetails.tabType != 'video'"
      >
        <video
          class="video"
          ref="videoPlayer_3"
          :poster="messageInfoDetails.cover"
          controls
          controlslist="nodownload"
          preload
          x5-playsinline
          playsinline
          webkit-playsinline
          loop
          :src="messageInfoDetails.linkUrl[0]"
        >
        </video>
      </div>
      <div
        class="video_box"
        ref="obtain"
        @click.stop=""
        v-if="messageInfoDetails.tabType == 'video'"
        style="height: 400px"
      >
        <div class="nextVideoTips" v-if="isShowNextVideoTips">
          <div v-if="ali_jiashu_lang == 'cn'">
            即将播放：{{
              messageInfoDetails.videoArr[((messageInfoDetails.index + 1) > (messageInfoDetails.videoArr.length - 1 ) ? 0 : (messageInfoDetails.index + 1))].title
            }}
          </div>
          <div v-if="ali_jiashu_lang == 'en'">
            Coming soon：{{
              messageInfoDetails.videoArr[((messageInfoDetails.index + 1) > (messageInfoDetails.videoArr.length - 1 ) ? 0 : (messageInfoDetails.index + 1))].title_en
            }}
          </div>
        </div>
        <div id="video-box">
          <video
            id="typeVideo"
            class="video"
            ref="videoPlayer_4"
            :poster="videodemocover"
            controls
            controlslist="nodownload"
            preload
            x5-playsinline
            playsinline
            webkit-playsinline
            v-if="isShowVideoDemo"
            :src="videodemoVideoUrl"
          ></video>
        </div>
      </div>
      <div class="close_icon" @click.stop="goTolists"></div>
    </div>
    <div v-if="messageInfoDetails.category == 2">
      <swiper :options="swiperOption" ref="mySwiper">
        <swiper-slide
          v-for="(item, index) in messageInfoDetails.linkUrl"
          :key="index"
          ><img :src="item" />
          <div class="number">
            {{ index + 1 }} / {{ messageInfoDetails.linkUrl.length }}
          </div>
        </swiper-slide>
        <div
          @click.stop=""
          class="swiper-button-prev"
          slot="button-prev"
          v-if="!this.isMobile"
        ></div>
        <div
          @click.stop=""
          class="swiper-button-next"
          slot="button-next"
          v-if="!this.isMobile"
        ></div>
      </swiper>
    </div>
  </div>
</template>

<script>
// 引入插件
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {
    swiper,
    swiperSlide,
  },
  props: ["messageInfoDetails", "isShowLookImgModel"],
  data() {
    return {
      swiperOption: {
        loop: false,
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      },
      isMobile: false,
      isShowNextVideoTips: false, // 是否出现下一个视频名字提示
      isShowVideoDemo: true, // 视频容器
      videodemocover: "",
      videodemoVideoUrl: "",
      ali_jiashu_lang: "cn",
    };
  },
  computed: {
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
  },
  methods: {
    // 判断当前使用端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    goTolists() {
      var look_model = document.getElementById("look_model");
      var video = document.getElementById("typeVideo");
      console.log(("1", look_model));
      console.log(("2", video));
      // video.pause()
      look_model.innerHTML = "";
      this.$emit("changeLookimgShow", false);
    },

    // 监听视频剩十秒
    cuteDown(video) {
      let that = this;
      var timeDisplay;
      var duration;
      //用秒数来显示当前播放进度
      timeDisplay = Math.floor(video.currentTime); // 当前播放的时长
      duration = Math.floor(video.duration); // 总时长
      if (duration - timeDisplay < 10) {
        console.log("还有" + (duration - timeDisplay) + "秒，播放完当前视频");
        that.isShowNextVideoTips = true;
      } else {
        that.isShowNextVideoTips = false;
      }
    },
    // 监听视频播放完了
    videoEnd(video) {
      let that = this;
      console.log("播放结束了");
      video.pause();
      that.isShowVideoDemo = false;
      if (
        that.messageInfoDetails.index <
        that.messageInfoDetails.videoArr.length - 1
      ) {
        that.messageInfoDetails.index += 1;
      } else {
        that.messageInfoDetails.index = 0;
      }
      that.isShowNextVideoTips = false;
      that.videodemocover =
        that.messageInfoDetails.videoArr[that.messageInfoDetails.index].cover;
      that.videodemoVideoUrl =
        that.messageInfoDetails.videoArr[that.messageInfoDetails.index].video;
      setTimeout(() => {
        that.isShowVideoDemo = true;
        setTimeout(() => {
          var video = document.getElementById("typeVideo");
          console.log(video);
          //使用事件监听方式捕捉事件
          video.addEventListener(
            "timeupdate",
            function () {
              that.cuteDown(video);
            },
            false
          );
          //使用事件监听方式捕捉事件
          video.addEventListener(
            "ended",
            function () {
              that.videoEnd(video);
            },
            false
          );
          video.play();
        }, 100);
      }, 100);
    },
  },
  mounted() {
    let that = this;
    console.log(that.messageInfoDetails);
    if (that.messageInfoDetails.tabType == "video") {
      console.log("1");
      var video = document.getElementById("typeVideo");
      console.log(video);
      //使用事件监听方式捕捉事件
      video.addEventListener(
        "timeupdate",
        function () {
          that.cuteDown(video);
        },
        false
      );

      //使用事件监听方式捕捉事件
      video.addEventListener(
        "ended",
        function () {
          that.videoEnd(video);
        },
        false
      );
    }
  },
  created() {
    console.log("精彩视频播放视频");
    console.log(this.messageInfoDetails);
    this.ali_jiashu_lang =
      window.localStorage.getItem("ali_jiashu_lang") || "cn";
    this.videodemocover = this.messageInfoDetails.cover[0];
    this.videodemoVideoUrl = this.messageInfoDetails.linkUrl[0];
    if (this._isMobile()) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  },
  beforeDestroy() {},
};
</script>