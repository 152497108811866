<style scoped>
@media only screen and (min-width: 600px) {
  .right_shadow,
  .video_item_mobile {
    display: none;
  }
  .page {
    /* position: relative; */
    /* top: 0%; */
    /* left: 50%; */
    /* transform: translate(-50%, 0%); */
    width: 100%;
    height: 100%;
    min-width: 1280px;
    min-height: 620px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .returnTop {
    display: none;
    position: fixed;
    right: 100px;
    bottom: 100px;
    width: 70px;
    height: 70px;
    background: url("https://img.alicdn.com/imgextra/i3/O1CN01Zu3oGT1uH5Ty3Qe5y_!!6000000006011-2-tps-95-95.png")
      no-repeat center;
    background-size: 100% 100%;
    cursor: pointer;
    transition: all 2s;
  }
  .share_family {
    position: absolute;
    bottom: 60px;
    right: 6%;
    transform: translateX(-50%);
    /* right: -100px; */
    /* bottom: 200px; */
    z-index: 9;
    cursor: pointer;
    animation: share_family_iconanimate 2s infinite linear;
  }
  .share_family_icon {
    width: calc(191px / 1.7);
    height: calc(202px / 1.7);
    background: url("https://img.alicdn.com/imgextra/i3/O1CN01PVg4Bc1WVEwHPRD8C_!!6000000002793-2-tps-191-202.png")
      no-repeat bottom;
    background-size: 100% 100%;
  }
  yframes share_family_iconanimate {
    0% {
      transform: translateX(-50%) translateY(0px);
    }
    45% {
      transform: translateX(-50%) translateY(-10px);
    }
    90% {
      transform: translateX(-50%) translateY(0px);
    }
    100% {
      transform: translateX(-50%) translateY(0px);
    }
  }
  .share_family_text {
    position: absolute;
    bottom: -4px;
    left: 48%;
    transform: translateX(-50%);
    width: calc(184px / 1.6);
    height: calc(57px / 1.6);
    white-space: nowrap;
    color: white;
    text-align: center;
    font-size: 16px;
    line-height: 0.7rem;
    font-weight: bold;
    background: url("https://img.alicdn.com/imgextra/i1/O1CN014V36kz27DVOH8B9j3_!!6000000007763-2-tps-184-57.png")
      no-repeat bottom;
    background-size: 100% 100%;
  }
  .share_family_text_en {
    font-size: 18px;
  }
  .pc_bottom_left {
    position: absolute;
    bottom: 0;
    left: 0;
    width: calc(355px / 2);
    height: calc(342px / 2);
    background: url("../assets/img/pcleft.png") no-repeat top;
    background-size: contain;
  }
  .pc_bottom_right {
    position: absolute;
    bottom: 0;
    right: 0;
    width: calc(417px / 2);
    height: calc(291px / 2);
    background: url("../assets/img/pcright.png") no-repeat top;
    background-size: contain;
  }
  .top_box {
    position: relative;
    /* width: 7.5rem; */
    width: 100%;
    /* height: 13rem; */
    height: 546px;
    margin: 0 auto 0;
    background: url("https://img.alicdn.com/imgextra/i3/O1CN01CGzyzb1Gyyo768RXM_!!6000000000692-2-tps-1920-797.png")
      no-repeat bottom;
    background-size: cover;
    overflow: hidden;
  }
  .video_box {
    position: relative;
    /* width: 960px; */
    /* height: 540px; */
    width: 800px;
    /* height: 434px; */
    height: 464px;
    margin: 16px auto 0;
    border-radius: 0.15rem;
    background-color: #fff;
    overflow: hidden;
    /* border: 0.2rem solid white; */
    border: 16px solid white;
    box-sizing: border-box;
  }
  .video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.15rem;
  }
  .tab_box {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-evenly;
    width: 50%;
  }
  .tab_bg {
    position: relative;
    /* width: 2.2rem; */
    width: 160px;
    /* height: 0.72rem; */
    height: 48px;
    cursor: pointer;
  }
  .tab_bg_active {
    background: url("../assets/img/tab_bg.png") no-repeat top;
    background-size: contain;
  }
  .tab_bg_en_active {
    /* width: 2.5rem; */
    background: url("../assets/img/tab_bg.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .tab_icon_1 {
    transform: translate(-50%, -50%);
    width: 1.74rem;
    height: 0.33rem;
    background: url("../assets/img/tab_1.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_1en {
    transform: translate(-50%, -50%);
    width: 2.36rem;
    height: 0.33rem;
    background: url("../assets/img/tab_1en.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_1_active {
    /* width: 1.74rem; */
    background: url("../assets/img/tab_1_active.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_1en_active {
    background: url("../assets/img/tab_1en_active.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_2 {
    transform: translate(-50%, -50%);
    width: 1.7rem;
    height: 0.37rem;
    background: url("../assets/img/tab_2.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_2en {
    transform: translate(-50%, -50%);
    width: 1.69rem;
    height: 0.37rem;
    background: url("../assets/img/tab_2en.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_2_active {
    /* width: 1.71rem; */
    background: url("../assets/img/tab_2_active.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_2en_active {
    background: url("../assets/img/tab_2en_active.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_3 {
    transform: translate(-50%, -50%);
    width: 1.74rem;
    height: 0.31rem;
    background: url("../assets/img/tab_3.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_3en {
    transform: translate(-50%, -50%);
    width: 1.74rem;
    height: 0.31rem;
    background: url("../assets/img/tab_3en.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_3_active {
    /* height: 0.32rem; */
    background: url("../assets/img/tab_3_active.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_3en_active {
    /* height: 0.32rem; */
    background: url("../assets/img/tab_3en_active.png") no-repeat top;
    background-size: 100% 100%;
  }

  .bottom_btn {
    position: fixed;
    bottom: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 2.8rem;
    height: 0.92rem;
    /* background: url("https://img.alicdn.com/imgextra/i4/O1CN01huP3Gc1vXPCzsG2Kl_!!6000000006182-2-tps-280-92.png")
      no-repeat top; */
    background-size: contain;
    cursor: pointer;
    z-index: 1;
  }
  .bottom_btn_div {
    position: absolute;
    top: 50%;
    left: 58%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 14px;
    font-weight: 700;
    white-space: nowrap;
  }
  .bottom_btn_icon {
    position: absolute;
    top: 50%;
    left: -22px;
    transform: translateY(-50%);
    width: 16px;
    height: 18px;
    background: url("https://img.alicdn.com/imgextra/i2/O1CN01lEgiAF1wfTo7AGCoJ_!!6000000006335-2-tps-24-26.png")
      no-repeat top;
    background-size: contain;
  }
  .message_lists {
    position: relative;
    /* width: 60%; */
    width: 800px;
    /* width: 7.5rem; */
    /* height: 90vh; */
    /* height: 100vh; */
    /* background-color: #fff; */
    margin: 0 auto;
    box-sizing: border-box;
  }
  .message_lists_content {
    /* box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 103%;
    height: 100%; */
  }
  .water-fall {
    width: 100%;
    height: 100%;
    background-color: #fff7f0;
    /* box-sizing: border-box; */
    /* margin-bottom: 2rem; */
  }
  .message_item {
    width: 30%;
    display: inline-block;
    background-color: #fff;
    border-radius: 0.15rem;
    /* margin-top: 0.2rem; */
    margin-top: 0;
    padding: 0 0 0.25rem;
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  .message_item_cover_box {
    position: relative;
    width: 100%;
    height: 192px;
    background-color: rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  .message_item_cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .message_item_cover_right_img {
    position: absolute;
    bottom: 0.16rem;
    right: 0.16rem;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0.1rem;
    font-size: 0.22rem;
    color: white;
    padding: 0.06rem 0.1rem;
  }
  .message_stars {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999;
    width: 18px;
    height: 18px;
    background: url("../assets/img/精华.png") no-repeat top;
    background-size: contain;
    /* border-radius: 0 0.15rem 0 0; */
    z-index: 1;
  }
  .message_item_cover_right_video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .message_content {
    /* width: 95%; */
    padding: 0 16px 0;
    box-sizing: border-box;
  }
  .message_content_topic {
    /* margin-top: 0.16rem; */
    margin-top: 0.2rem;
    color: #ff5b00;
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    line-height: 1.5;
  }
  .message_content_desc {
    margin-top: 0.16rem;
    color: black;
    font-size: 13px;
    text-align: justify;
    /* line-height: 0.3rem; */
    line-height: 1.5;
    word-break: break-all;
  }
  .userinfo {
    margin-top: 0.2rem;
  }
  .user_avater {
    width: 0.44rem;
    min-width: 0.44rem;
    height: 0.44rem;
    min-height: 0.44rem;
    border-radius: 50%;
    overflow: hidden;
  }
  .user_nickname {
    font-size: 0.24rem;
    line-height: 0.48rem;
    color: black;
    opacity: 0.8;
    margin: 0 0.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .user_del {
    font-size: 0.24rem;
    line-height: 0.48rem;
    color: #ec6504;
    opacity: 0.8;
    white-space: nowrap;
  }

  .class_lists {
    /* overflow: hidden; */
    /* height: 90vh; */
    /* height: 100vh; */
    margin: 0 auto;
    padding: 0 0 30px;
    box-sizing: border-box;
    /* overflow-y: scroll; */
  }
  .class_lists_tab {
    position: relative;
    /* width: 7.5rem; */
    width: 800px;
    margin: 0 auto;
  }

  .class_lists_tab_box {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 0;
    margin: 0;
    justify-content: space-around;
  }
  .right_shadow {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
    width: 8%;
    height: 100%;
    background: url("../assets/img/tab_right_shadow.png") no-repeat top;
    background-size: 100% 100%;
  }
  .class_lists_tab_item {
    position: relative;
    height: 0.8rem;
    /* font-size: 0.26rem; */
    font-size: 14px;
    color: #b54b00;
    line-height: 1rem;
    text-align: justify;
    padding: 0 10px 0;
    white-space: nowrap;
    cursor: pointer;
  }
  .class_lists_tab_active {
    color: #ff6900;
    font-weight: 700;
  }
  .class_lists_active {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0.5rem;
    height: 0.04rem;
    background-color: #ff6900;
    border-radius: 2px;
  }
  .more_image {
    position: relative;
    /* font-size: 0.24rem; */
    font-size: 16px;
    /* font-weight: 500; */
    color: #363636;
    text-align: left;
    margin: 30px 0 0 0.2rem;
  }
  .more_image_margin {
    /* margin: 16px 26px 0; */
    margin: 16px 16px 0;
    padding: 0 8px 16px;
    border-bottom: 1px solid #dddddd;
  }
  .left_border {
    position: absolute;
    top: 22%;
    /* left: -0.15rem; */
    left: 0px;
    transform: translateY(-50%);
    width: 0.06rem;
    height: 14px;
    background-color: #ec6504;
    border-radius: 0.06rem;
  }
  .image_flex {
    /* justify-content: center; */
    justify-content: flex-start;
    width: 100%;
    margin: 0 14px 0;
  }
  .image_item {
    width: 31.3%;
    margin: 0.22rem 11.5px 0 0;
    border-radius: 0.15rem;
    overflow: hidden;
    /* width: 29.3%; */
    /* margin: 0.22rem 2% 0; */
    /* border-radius: 0.15rem; */
    /* overflow: hidden; */
  }

  .video_lists {
    margin: 0 auto;
    /* height: 90vh; */
    width: 840px;
    /* height: 100vh; */
    padding: 0 0 60px;
    box-sizing: border-box;
    /* overflow-y: scroll; */
  }
  .video_item {
    /* width: 7.02rem; */
    width: 800px;
    /* height: 5.45rem; */
    background-color: #fff;
    border-radius: 0.15rem;
    margin: 16px auto 30px;
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
    padding: 0 0 16px;
    overflow: hidden;
    box-sizing: border-box;
  }
  .video_item_flexitem {
    width: 400px;
    margin: 20px 10px 0;
    padding: 16px 0;
  }
  .item_img {
    position: relative;
    /* width: 7.6rem; */
    width: 92%;
    height: 4.7rem;
    margin: 0 auto;
    border-radius: 0.15rem;
    overflow: hidden;
    cursor: pointer;
    /* display: flex; */
    /* flex-direction: column; */
    /* justify-content: center; */
  }
  .item_img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .video_mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .video_play {
    position: absolute;
    bottom: 0.2rem;
    right: 0.2rem;
    width: 0.65rem;
    height: 0.65rem;
    background: url("../assets/img/play.png") no-repeat top;
    background-size: contain;
  }
  .video_title {
    /* width: 7.6rem; */
    margin: 16px 16px 0;
    color: black;
    /* font-size: 0.26rem; */
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    line-height: 0.3rem;
  }
  .video_desc {
    /* width: 7.6rem; */
    margin: 0.2rem 16px 0;
    color: black;
    /* font-size: 0.24rem; */
    font-size: 14px;
    text-align: justify;
    /* line-height: 0.36rem; */
    line-height: 1.5;
  }
  .upload_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .type_box {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
  }
  .uploadInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .upload_loading_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .upload_loading_mask_content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .upload_loading_mask_icon {
    position: absolute;
    top: 0;
    left: calc(50% - 0.9rem);
    width: 1.8rem;
    height: 1.8rem;
    background: url("../assets/img/uploading.png") no-repeat top;
    background-size: 100% 100%;
    animation: uploadinganimate 2s infinite linear;
  }
  yframes uploadinganimate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  .upload_loading_mask_text {
    margin-top: 2.4rem;
    font-size: 0.26rem;
    color: white;
    white-space: nowrap;
  }

  .ewm_tips_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99;
  }

  .ewm_tips_content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(751px / 1.3);
    height: calc(521px / 1.3);
    background: url("https://img.alicdn.com/imgextra/i3/O1CN01fK98D71yXM0pLPAiw_!!6000000006588-2-tps-751-521.png")
      no-repeat top;
    background-size: contain;
  }
  .ewm_tips_content_en {
    background: url("https://img.alicdn.com/imgextra/i2/O1CN01DGM28m1VoBiLhCbTz_!!6000000002699-2-tps-751-521.png")
      no-repeat top;
    background-size: contain;
  }
  .ewm_tips_content_Submit {
    background: url("https://img.alicdn.com/imgextra/i1/O1CN01NYxiYr23aAWiQoD6f_!!6000000007271-2-tps-751-521.png")
      no-repeat top;
    background-size: contain;
  }
  .ewm_tips_content_Submit_en {
    background: url("https://img.alicdn.com/imgextra/i4/O1CN01VxEfXq1tww3bJgGol_!!6000000005967-2-tps-751-521.png")
      no-repeat top;
    background-size: contain;
  }

  .ewm_tips_content_close {
    position: absolute;
    top: -50px;
    right: -50px;
    width: calc(51px / 1.3);
    height: calc(51px / 1.3);
    background: url("../assets/img/叉叉.png") no-repeat top;
    background-size: contain;
  }
}

ul {
  width: 33.33333333333333333333333333333333%;
  overflow: hidden;
  padding: 0 0 100px 0;
}

ul.left-waterfall {
  float: left;
  position: relative;
}

ul.right-waterfall {
  float: left;
  position: relative;
}

li.item {
  position: relative;
  width: 96%;
  background-color: white;
  margin: 10px;
  overflow: hidden;
  border-radius: 0.15rem;
  padding: 0 0 0.15rem 0;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
}

/* 移动端样式 */
@media only screen and (max-width: 600px) {
  .share_family,
  .ewm_tips_mask,
  .returnTop,
  .video_item_pc {
    display: none;
  }
  .page {
    position: fixed;
    top: 0;
    left: 0;
    width: 7.5rem;
    background-color: #fff7f0;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .top_box {
    position: relative;
    width: 7.5rem;
    height: 7.74rem;
    margin: 0 auto 0;
    /* background: url("https://img.alicdn.com/imgextra/i1/O1CN01iCNqin1TqokqI3R11_!!6000000002434-0-tps-750-684.jpg") */
    background: url("https://img.alicdn.com/imgextra/i3/O1CN01N7nxdx1togvMfJdkp_!!6000000005949-2-tps-750-774.png")
      no-repeat top;
    background-size: 100% 100%;
    overflow: hidden;
  }
  .video_box {
    position: relative;
    width: 7.02rem;
    /* height: 4.21rem; */
    height: 3.99rem;
    margin: 1.2rem auto 0;
    border-radius: 0.15rem;
    /* background-color: #fff; */
    overflow: hidden;
    z-index: 1;
  }
  .video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 0.15rem;
    z-index: 1;
  }
  .tab_box {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: space-evenly;
    width: 7.02rem;
  }
  .tab_bg {
    position: relative;
    width: 2.2rem;
    height: 0.72rem;
  }
  .tab_bg_active {
    background: url("../assets/img/tab_bg.png") no-repeat top;
    background-size: contain;
  }
  .tab_bg_en_active {
    width: 2.5rem;
    background: url("../assets/img/tab_bg.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon {
    position: absolute;
    top: 50%;
    left: 50%;
  }
  .tab_icon_1 {
    transform: translate(-50%, -50%);
    width: 1.74rem;
    height: 0.33rem;
    background: url("../assets/img/tab_1.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_1en {
    transform: translate(-50%, -50%);
    width: 2.24rem;
    height: 0.33rem;
    background: url("../assets/img/tab_1en.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_1_active {
    /* width: 1.74rem; */
    background: url("../assets/img/tab_1_active.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_1en_active {
    background: url("../assets/img/tab_1en_active.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_2 {
    transform: translate(-50%, -50%);
    width: 1.7rem;
    height: 0.37rem;
    background: url("../assets/img/tab_2.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_2en {
    transform: translate(-50%, -50%);
    width: 1.69rem;
    height: 0.37rem;
    background: url("../assets/img/tab_2en.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_2_active {
    /* width: 1.71rem; */
    background: url("../assets/img/tab_2_active.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_2en_active {
    background: url("../assets/img/tab_2en_active.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_3 {
    transform: translate(-50%, -50%);
    width: 1.74rem;
    height: 0.31rem;
    background: url("../assets/img/tab_3.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_3en {
    transform: translate(-50%, -50%);
    width: 1.74rem;
    height: 0.31rem;
    background: url("../assets/img/tab_3en.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_3_active {
    background: url("../assets/img/tab_3_active.png") no-repeat top;
    background-size: 100% 100%;
  }
  .tab_icon_3en_active {
    background: url("../assets/img/tab_3en_active.png") no-repeat top;
    background-size: 100% 100%;
  }
  .bottom_btn {
    position: fixed;
    bottom: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 3rem;
    height: 0.92rem;
    /* background: url("https://img.alicdn.com/imgextra/i4/O1CN01huP3Gc1vXPCzsG2Kl_!!6000000006182-2-tps-280-92.png")
      no-repeat top; */
    background-size: 100% 100%;
    z-index: 1;
  }
  .bottom_btn_div {
    position: absolute;
    top: 50%;
    left: 58%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 0.28rem;
    font-weight: 700;
    white-space: nowrap;
  }
  .bottom_btn_div_cn {
    font-size: 0.34rem;
  }
  .bottom_btn_icon {
    position: absolute;
    top: 55%;
    left: -22px;
    transform: translateY(-50%);
    width: 16px;
    height: 18px;
    background: url("https://img.alicdn.com/imgextra/i2/O1CN01lEgiAF1wfTo7AGCoJ_!!6000000006335-2-tps-24-26.png")
      no-repeat top;
    background-size: contain;
  }
  .bottom_btn_icon_cn {
    top: 70%;
    left: -28px;
    width: 20px;
    height: 30px;
  }
  .left_btn {
    position: fixed;
    bottom: 2rem;
    right: 0.1rem;
    width: 1.38rem;
    height: 1.37rem;
    /* background: url("https://img.alicdn.com/imgextra/i2/O1CN01W7bAXc1kQZWeIPS8X_!!6000000004678-2-tps-138-137.png") */
    /* no-repeat top; */
    background-size: contain;
  }
  .left_btn_text {
    position: absolute;
    bottom: 20px;
    left: 51%;
    transform: translateX(-50%);
    font-size: 12px;
    font-weight: 500;
    color: white;
    white-space: nowrap;
  }

  .message_lists {
    position: relative;
    width: 100%;
    min-height: 84vh;
    background-color: #fff7f0;
    /* margin: 0 0 1rem; */
  }
  .message_lists_content {
    /* box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    border-top: 0.2rem solid #fff7f0; */
  }
  .water-fall {
    width: 100%;
    height: 100%;
    background-color: #fff7f0;
    /* box-sizing: border-box; */
    /* margin-bottom: 2rem; */
  }
  .message_item {
    background-color: #fff;
    border-radius: 0.15rem;
    /* margin-top: 0.2rem; */
    margin-top: 0;
    padding: 0 0 0.25rem;
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  .message_item_cover_box {
    position: relative;
    width: 100%;
    height: 3rem;
    background-color: rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
  .message_item_cover {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .message_item_cover_right_img {
    position: absolute;
    bottom: 0.16rem;
    right: 0.16rem;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 0.1rem;
    font-size: 0.22rem;
    color: white;
    padding: 0.06rem 0.1rem;
  }
  .message_stars {
    position: absolute;
    /* top: 9px; */
    /* right: 2px; */
    top: 0;
    right: 0;
    z-index: 999;
    width: 0.23rem;
    height: 0.23rem;
    background: url("../assets/img/精华.png") no-repeat top;
    background-size: contain;
    /* border-radius: 0 0.1rem 0 0; */
    z-index: 1;
  }
  .message_item_cover_right_video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .message_content {
    padding: 0 0.15rem 0;
  }
  .message_content_topic {
    /* margin-top: 0.16rem; */
    margin-top: 0.2rem;
    color: #ff5b00;
    font-size: 0.24rem;
    font-size: 14px;
    text-align: left;
    font-weight: 500;
    line-height: 1.5;
  }
  .message_content_desc {
    margin-top: 0.16rem;
    color: black;
    font-size: 13px;
    /* font-size: 0.24rem; */
    text-align: justify;
    /* line-height: 0.3rem; */
    line-height: 1.5;
    word-break: break-all;
  }
  .userinfo {
    margin-top: 0.2rem;
  }
  .user_avater {
    width: 0.44rem;
    min-width: 0.44rem;
    height: 0.44rem;
    min-height: 0.44rem;
    border-radius: 50%;
    overflow: hidden;
  }
  .user_nickname {
    font-size: 0.24rem;
    line-height: 0.48rem;
    color: black;
    opacity: 0.8;
    margin: 0 0.1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .user_del {
    font-size: 0.24rem;
    line-height: 0.48rem;
    color: #ec6504;
    opacity: 0.8;
    white-space: nowrap;
  }

  .class_lists {
    overflow: hidden;
    padding: 0 0 1rem;
  }
  .class_lists_tab {
    position: relative;
    width: 7.5rem;
    margin: 0 auto;
  }

  .class_lists_tab_box {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    padding: 0;
    margin: 0;
  }
  .right_shadow {
    position: absolute;
    top: 50%;
    right: 0%;
    transform: translateY(-50%);
    width: 8%;
    height: 100%;
    background: url("../assets/img/tab_right_shadow.png") no-repeat top;
    background-size: 100% 100%;
  }
  .class_lists_tab_item {
    position: relative;
    height: 0.8rem;
    font-size: 0.26rem;
    color: #b54b00;
    line-height: 1rem;
    text-align: justify;
    padding: 0 0.25rem 0;
    white-space: nowrap;
  }
  .class_lists_tab_active {
    color: #ff6900;
    font-weight: 500;
  }
  .class_lists_active {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 0.5rem;
    height: 0.04rem;
    background-color: #ff6900;
    border-radius: 2px;
  }
  .more_image {
    position: relative;
    height: 14px;
    font-size: 14px;
    line-height: 1.2;
    /* font-weight: 700; */
    color: black;
    text-align: left;
    margin: 0.2rem 0 0 0.3rem;
  }

  .more_image_margin {
    margin: 0.16rem 0.2rem 0 0.4rem;
    /* padding: 0 0 0.16rem; */
    /* border-bottom: 1px solid #dddddd; */
  }
  .left_border {
    position: absolute;
    top: 50%;
    left: -0.15rem;
    transform: translateY(-50%);
    width: 0.06rem;
    height: 14px;
    background-color: #ec6504;
    border-radius: 0.06rem;
  }
  .image_flex {
    /* justify-content: center; */
    justify-content: flex-start;
  }
  .image_item {
    width: 45.5%;
    margin: 0.22rem 0 0 0.2rem;
    border-radius: 0.15rem;
    overflow: hidden;
  }

  .video_lists {
    padding: 0 0 1rem;
  }
  .video_item {
    width: 7.02rem;
    /* height: 5.45rem; */
    background-color: #fff;
    border-radius: 0.15rem;
    margin: 0.3rem auto 0;
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
    padding: 0 0 0.2rem;
    overflow: hidden;
  }
  .item_img {
    position: relative;
    width: 6.6rem;
    height: 3.7rem;
    border-radius: 0.15rem;
    overflow: hidden;
    margin: 0.2rem auto 0;
    cursor: pointer;
  }
  .item_img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .video_mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .video_play {
    position: absolute;
    bottom: 0.2rem;
    right: 0.2rem;
    width: 0.65rem;
    height: 0.65rem;
    background: url("../assets/img/play.png") no-repeat top;
    background-size: contain;
  }
  .video_title {
    width: 6.6rem;
    margin: 16px auto 0;
    color: black;
    /* font-size: 0.26rem; */
    font-size: 16px;
    font-weight: bold;
    text-align: left;
    line-height: 0.3rem;
  }
  .video_desc {
    width: 6.6rem;
    margin: 0.16rem auto 0;
    color: black;
    /* font-size: 0.24rem; */
    font-size: 14px;
    text-align: justify;
    /* line-height: 0.36rem; */
    line-height: 1.5;
  }
  .upload_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .type_box {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 70%;
  }
  .upload_image,
  .upload_video {
    position: relative;
    width: 2.1rem;
    height: 2.1rem;
  }
  .upload_image {
    background: url("https://img.alicdn.com/imgextra/i2/O1CN01BLfuuH23h2SyqlTJs_!!6000000007286-2-tps-210-210.png")
      no-repeat top;
    background-size: 100% 100%;
  }
  .upload_video {
    background: url("https://img.alicdn.com/imgextra/i2/O1CN01yocn711xW9Ls7MkwX_!!6000000006450-2-tps-210-210.png")
      no-repeat top;
    background-size: 100% 100%;
  }
  .upload_image_text {
    position: absolute;
    top: 75%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #ec6504;
    font-size: 14px;
    white-space: nowrap;
    font-weight: bold;
  }
  .uploadInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
  .upload_loading_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .upload_loading_mask_content {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .upload_loading_mask_icon {
    position: absolute;
    top: 0;
    left: calc(50% - 0.9rem);
    width: 1.8rem;
    height: 1.8rem;
    background: url("../assets/img/uploading.png") no-repeat top;
    background-size: 100% 100%;
    animation: uploadinganimate 2s infinite linear;
  }
  yframes uploadinganimate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  .upload_loading_mask_text {
    margin-top: 2.4rem;
    font-size: 0.26rem;
    color: white;
    white-space: nowrap;
  }

  .upload_success_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
  .share_icon {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    width: 3.72rem;
    height: 2.03rem;
    background: url("../assets/img/share.png") no-repeat top;
    background-size: 100% 100%;
  }
  .share_icon_en {
    width: 5.09rem;
    height: 2.08rem;
    background: url("../assets/img/share_en.png") no-repeat top;
    background-size: 100% 100%;
  }
  ul {
    width: 49%;
    overflow: hidden;
    padding: 0 0 100px 0;
  }
  ul.left-waterfall .item {
    position: relative;
    /* margin: 0.15rem 0.15rem 0.15rem 0.15rem; */
    /* margin: 0; */
    margin: 0.15rem 0 0.15rem 0.15rem;
    padding: 0 0 0.15rem 0;
  }

  ul.right-waterfall .item {
    position: relative;
    margin: 0.15rem 0 0.15rem 0.15rem;
    /* margin: 0.15rem 0.15rem 0.15rem 0rem; */
    padding: 0 0 0.15rem 0;
  }
}
.upload_mask {
  z-index: 999;
}
.isloading {
  position: absolute;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  color: #ff5b00;
  font-size: 20px;
  white-space: nowrap;
  font-weight: bold;
}
</style>
<template>
  <div class="page" ref="page">
    <!-- pc底部花左 -->
    <div class="pc_bottom_left" v-if="!isMobile"></div>
    <!-- pc底部花右 -->
    <div class="pc_bottom_right" v-if="!isMobile"></div>

    <!-- 分类TAB -->
    <div class="top_box">
      <div class="video_box" ref="obtain">
        <video-player
          id="video"
          class="video video-player vjs-custom-skin"
          ref="videoPlayer"
          :playsinline="true"
          :options="playerOptions"
          @pause="onPlayerPause($event)"
          @play="onPlayerPlay($event)"
        ></video-player>
        <!-- <video
          id="video"
          class="video"
          ref="videoPlayer"
          poster="https://img.alicdn.com/imgextra/i1/O1CN01gQRC7A1yZdzjQjTvk_!!6000000006593-2-tps-1920-1080.png"
          controls
          controlslist="nodownload"
          preload
          x5-playsinline
          playsinline
          webkit-playsinline
          autoplay
          loop
          src="https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/2021%E9%98%BF%E9%87%8C%E5%AE%B6%E4%B9%A6%E4%B8%BB%E9%A2%98%E3%80%8A%E6%83%B3%E5%92%8C%E4%BD%A0%E8%AF%B4%E3%80%8B.mp4"
        ></video> -->
      </div>
      <div class="tab_box">
        <div
          :class="isShowTab == 'message' ? 'tab_bg tab_bg_active' : 'tab_bg'"
          @click="tabCheck('message')"
          v-if="ali_jiashu_lang == 'cn'"
        >
          <div
            :class="
              isShowTab == 'message'
                ? 'tab_icon tab_icon_1 tab_icon_1_active'
                : 'tab_icon tab_icon_1'
            "
            v-if="ali_jiashu_lang == 'cn'"
          ></div>
        </div>
        <div
          :class="isShowTab == 'message' ? 'tab_bg tab_bg_en_active' : 'tab_bg'"
          @click="tabCheck('message')"
          v-if="ali_jiashu_lang == 'en'"
        >
          <div
            :class="
              isShowTab == 'message'
                ? 'tab_icon tab_icon_1en tab_icon_1en_active'
                : 'tab_icon tab_icon_1en'
            "
            v-if="ali_jiashu_lang == 'en'"
          ></div>
        </div>
        <div
          :class="isShowTab == 'class' ? 'tab_bg tab_bg_active' : 'tab_bg'"
          @click="isShowTab = 'class'"
        >
          <div
            :class="
              isShowTab == 'class'
                ? 'tab_icon tab_icon_2 tab_icon_2_active'
                : 'tab_icon tab_icon_2'
            "
            v-if="ali_jiashu_lang == 'cn'"
          ></div>
          <div
            :class="
              isShowTab == 'class'
                ? 'tab_icon tab_icon_2en tab_icon_2en_active'
                : 'tab_icon tab_icon_2en'
            "
            v-if="ali_jiashu_lang == 'en'"
          ></div>
        </div>
        <div
          :class="isShowTab == 'video' ? 'tab_bg tab_bg_active' : 'tab_bg'"
          @click="tabCheck('video')"
        >
          <div
            :class="
              isShowTab == 'video'
                ? 'tab_icon tab_icon_3 tab_icon_3_active'
                : 'tab_icon tab_icon_3'
            "
            v-if="ali_jiashu_lang == 'cn'"
          ></div>
          <div
            :class="
              isShowTab == 'video'
                ? 'tab_icon tab_icon_3en tab_icon_3en_active'
                : 'tab_icon tab_icon_3en'
            "
            v-if="ali_jiashu_lang == 'en'"
          ></div>
        </div>
      </div>
      <!-- pc分享给亲人挂件 -->
      <div class="share_family" @click="showPcSahre()">
        <div class="share_family_icon"></div>
        <div
          :class="
            ali_jiashu_lang == 'cn'
              ? 'share_family_text'
              : 'share_family_text share_family_text_en'
          "
        >
          {{ ali_jiashu_lang == "cn" ? "分享给家人" : "Share" }}
        </div>
      </div>
    </div>
    <!-- 互动留言-列表 -->
    <div class="message_lists" v-if="isShowTab == 'message'">
      <!-- 移动端 -->
      <div class="message_lists_content" v-if="isMobile">
        <ul class="left-waterfall" ref="left">
          <li
            class="item"
            v-for="(item, index) in leftMessagelistarr"
            :key="index"
            @click.stop="goToDetails(item)"
          >
            <div class="message_stars" v-if="item.essence == 1"></div>
            <div
              class="message_item_cover_box"
              @click.stop="showImageMask(item)"
              v-if="item.cover"
              :style="
                'background: url(' +
                item.cover +
                ') no-repeat center center; backgroundSize: cover;'
              "
            >
              <div
                class="message_item_cover_right_video"
                v-if="item.category == 1"
              >
                <div class="video_play"></div>
              </div>
              <div
                class="message_item_cover_right_img"
                v-if="item.category == 2 && item.linkUrl.length > 1"
              >
                1/{{ item.linkUrl.length }}
              </div>
            </div>
            <div class="message_content">
              <div class="message_content_topic" v-if="ali_jiashu_lang == 'cn'">
                {{ item.topic || "#xxxx" }}
              </div>
              <div class="message_content_topic" v-if="ali_jiashu_lang == 'en'">
                {{
                  item.topic == "#2021感谢有你"
                    ? "#Thank you for everything in 2021"
                    : ""
                }}
                {{
                  item.topic == "#2022想和家人做的事"
                    ? "#Things I want to do with my family in 2022"
                    : ""
                }}
                {{
                  item.topic == "#晒晒我收到的家书"
                    ? "#Here's my A Letter Home"
                    : ""
                }}
              </div>
              <div class="message_content_desc">
                {{ item.detail }}
              </div>
              <div class="userinfo flex flex-wrap-no">
                <div class="user_avater">
                  <img
                    style="display: block; width: 100%; height: 100%"
                    :src="item.avatar"
                  />
                </div>
                <div class="user_nickname">{{ item.nickname }}</div>
                <div
                  class="user_del"
                  v-if="(item.userId || item.user_id) == userInfoId"
                  @click.stop="deleteDetails(item)"
                >
                  删除
                </div>
              </div>
            </div>
          </li>
        </ul>
        <ul class="right-waterfall" ref="right">
          <li
            class="item"
            v-for="(item, index) in rightMessagelistarr"
            :key="index"
            @click.stop="goToDetails(item)"
          >
            <div class="message_stars" v-if="item.essence == 1"></div>
            <div
              class="message_item_cover_box"
              @click.stop="showImageMask(item)"
              v-if="item.cover"
              :style="
                'background: url(' +
                item.cover +
                ') no-repeat center center; backgroundSize: cover;'
              "
            >
              <div
                class="message_item_cover_right_video"
                v-if="item.category == 1"
              >
                <div class="video_play"></div>
              </div>
              <div
                class="message_item_cover_right_img"
                v-if="item.category == 2 && item.linkUrl.length > 1"
              >
                1/{{ item.linkUrl.length }}
              </div>
            </div>
            <div class="message_content">
              <div class="message_content_topic" v-if="ali_jiashu_lang == 'cn'">
                {{ item.topic || "#xxxx" }}
              </div>
              <div class="message_content_topic" v-if="ali_jiashu_lang == 'en'">
                {{
                  item.topic == "#2021感谢有你"
                    ? "#Thank you for everything in 2021"
                    : ""
                }}
                {{
                  item.topic == "#2022想和家人做的事"
                    ? "#Things I want to do with my family in 2022"
                    : ""
                }}
                {{
                  item.topic == "#晒晒我收到的家书"
                    ? "#Here's my A Letter Home"
                    : ""
                }}
              </div>
              <div class="message_content_desc">
                {{ item.detail }}
              </div>
              <div class="userinfo flex flex-wrap-no">
                <div class="user_avater">
                  <img
                    style="display: block; width: 100%; height: 100%"
                    :src="item.avatar"
                  />
                </div>
                <div class="user_nickname">{{ item.nickname }}</div>
                <div
                  class="user_del"
                  v-if="(item.userId || item.user_id) == userInfoId"
                  @click.stop="deleteDetails(item)"
                >
                  删除
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!-- PC端 -->
      <div class="message_lists_content" v-if="!isMobile">
        <ul class="left-waterfall" ref="left">
          <li
            class="item"
            v-for="(item, index) in leftMessagelistarr"
            :key="index"
          >
            <div class="message_stars" v-if="item.essence == 1"></div>
            <div
              class="message_item_cover_box"
              @click.stop="showImageMask(item)"
              v-if="item.cover"
              :style="
                'background: url(' +
                item.cover +
                ') no-repeat center center; backgroundSize: cover;'
              "
            >
              <div
                class="message_item_cover_right_video"
                v-if="item.category == 1"
              >
                <div class="video_play"></div>
              </div>
              <div
                class="message_item_cover_right_img"
                v-if="item.category == 2 && item.linkUrl.length > 1"
              >
                1/{{ item.linkUrl.length }}
              </div>
            </div>
            <div class="message_content">
              <div class="message_content_topic" v-if="ali_jiashu_lang == 'cn'">
                {{ item.topic || "#xxxx" }}
              </div>
              <div class="message_content_topic" v-if="ali_jiashu_lang == 'en'">
                {{
                  item.topic == "#2021感谢有你"
                    ? "#Thank you for everything in 2021"
                    : ""
                }}
                {{
                  item.topic == "#2022想和家人做的事"
                    ? "#Things I want to do with my family in 2022"
                    : ""
                }}
                {{
                  item.topic == "#晒晒我收到的家书"
                    ? "#Here's my A Letter Home"
                    : ""
                }}
              </div>
              <div class="message_content_desc">
                {{ item.detail }}
              </div>
              <div class="userinfo flex flex-wrap-no">
                <div class="user_avater">
                  <img
                    style="display: block; width: 100%; height: 100%"
                    :src="item.avatar"
                  />
                </div>
                <div class="user_nickname">{{ item.nickname }}</div>
              </div>
            </div>
          </li>
        </ul>
        <ul class="left-waterfall" ref="center">
          <li
            class="item"
            v-for="(item, index) in centerMessagelistarr"
            :key="index"
          >
            <div class="message_stars" v-if="item.essence == 1"></div>
            <div
              class="message_item_cover_box"
              @click.stop="showImageMask(item)"
              v-if="item.cover"
              :style="
                'background: url(' +
                item.cover +
                ') no-repeat center center; backgroundSize: cover;'
              "
            >
              <div
                class="message_item_cover_right_video"
                v-if="item.category == 1"
              >
                <div class="video_play"></div>
              </div>
              <div
                class="message_item_cover_right_img"
                v-if="item.category == 2 && item.linkUrl.length > 1"
              >
                1/{{ item.linkUrl.length }}
              </div>
            </div>
            <div class="message_content">
              <div class="message_content_topic" v-if="ali_jiashu_lang == 'cn'">
                {{ item.topic || "#xxxx" }}
              </div>
              <div class="message_content_topic" v-if="ali_jiashu_lang == 'en'">
                {{
                  item.topic == "#2021感谢有你"
                    ? "#Thank you for everything in 2021"
                    : ""
                }}
                {{
                  item.topic == "#2022想和家人做的事"
                    ? "#Things I want to do with my family in 2022"
                    : ""
                }}
                {{
                  item.topic == "#晒晒我收到的家书"
                    ? "#Here's my A Letter Home"
                    : ""
                }}
              </div>
              <div class="message_content_desc">
                {{ item.detail }}
              </div>
              <div class="userinfo flex flex-wrap-no">
                <div class="user_avater">
                  <img
                    style="display: block; width: 100%; height: 100%"
                    :src="item.avatar"
                  />
                </div>
                <div class="user_nickname">{{ item.nickname }}</div>
              </div>
            </div>
          </li>
        </ul>
        <ul class="right-waterfall" ref="right">
          <li
            class="item"
            v-for="(item, index) in rightMessagelistarr"
            :key="index"
          >
            <div class="message_stars" v-if="item.essence == 1"></div>
            <div
              class="message_item_cover_box"
              @click.stop="showImageMask(item)"
              v-if="item.cover"
              :style="
                'background: url(' +
                item.cover +
                ') no-repeat center center; backgroundSize: cover;'
              "
            >
              <div
                class="message_item_cover_right_video"
                v-if="item.category == 1"
              >
                <div class="video_play"></div>
              </div>
              <div
                class="message_item_cover_right_img"
                v-if="item.category == 2 && item.linkUrl.length > 1"
              >
                1/{{ item.linkUrl.length }}
              </div>
            </div>
            <div class="message_content">
              <div class="message_content_topic" v-if="ali_jiashu_lang == 'cn'">
                {{ item.topic || "#xxxx" }}
              </div>
              <div class="message_content_topic" v-if="ali_jiashu_lang == 'en'">
                {{
                  item.topic == "#2021感谢有你"
                    ? "#Thank you for everything in 2021"
                    : ""
                }}
                {{
                  item.topic == "#2022想和家人做的事"
                    ? "#Things I want to do with my family in 2022"
                    : ""
                }}
                {{
                  item.topic == "#晒晒我收到的家书"
                    ? "#Here's my A Letter Home"
                    : ""
                }}
              </div>
              <div class="message_content_desc">
                {{ item.detail }}
              </div>
              <div class="userinfo flex flex-wrap-no">
                <div class="user_avater">
                  <img
                    style="display: block; width: 100%; height: 100%"
                    :src="item.avatar"
                  />
                </div>
                <div class="user_nickname">{{ item.nickname }}</div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 文化活动-列表 -->
    <div class="class_lists" v-if="isShowTab == 'class'">
      <div class="class_lists_tab">
        <div class="class_lists_tab_box" ref="scrollRef">
          <div
            :class="
              active_tab == item.title || active_tab == item.title_en
                ? 'class_lists_tab_item class_lists_tab_active'
                : 'class_lists_tab_item'
            "
            v-for="(item, index) in classTabLists"
            :key="index"
            @click="checkClassTabLists(item, index)"
          >
            {{ ali_jiashu_lang == "cn" ? item.title : item.title_en }}
            <div
              class="class_lists_active"
              v-if="active_tab == item.title || active_tab == item.title_en"
            ></div>
          </div>
        </div>
        <div class="right_shadow"></div>
      </div>
      <div class="video_item video_item_mobile" v-if="isShowClassContent">
        <div class="video_desc" style="margin: 0.2rem auto 0.2rem">
          {{ classTabListsDetailsDesc }}
        </div>
        <div
          class="item_img"
          style="margin: 0.2rem auto 0"
          @click="showImageMask_class()"
        >
          <img :src="classTabListsDetailsCover" />
          <div class="video_mask">
            <div class="video_play"></div>
          </div>
        </div>
      </div>
      <div
        class="video_item video_item_pc flex justify-content-evenly"
        style="padding: 0 16px"
        v-if="isShowClassContent"
      >
        <div
          class="item_img"
          style="margin: 16px 0"
          @click="showImageMask_class()"
        >
          <img :src="classTabListsDetailsCover" />
          <div class="video_mask">
            <div class="video_play"></div>
          </div>
        </div>
        <div
          class="video_desc flex flex-wrap"
          style="
            width: 7.6rem;
            margin: 0.2rem 0.2rem;
            align-items: center;
            align-content: center;
          "
        >
          <div class="more_image" style="font-size: 22px; color: #b54b00">
            {{ classTabListsDetailsTitle }}
            <div
              class="left_border"
              style="
                height: 22px;
                top: 48%;
                left: -8px;
                background-color: #b54b00;
              "
            ></div>
          </div>
          <div style="margin: 6px 0 16px; font-size: 14px; line-hieght: 22px">
            {{ classTabListsDetailsDesc }}
          </div>
        </div>
      </div>
      <div class="video_item" v-if="isShowClassContent">
        <div class="more_image more_image_margin">
          {{ ali_jiashu_lang == "cn" ? "更多图片" : "More pictures" }}
          <div class="left_border"></div>
        </div>
        <div class="image_flex flex flex-wrap" v-if="image_lists.length != 0">
          <div
            class="image_item"
            v-for="(item, index) in image_lists"
            :key="index"
          >
            <img
              :src="item.cover"
              @click="showImageMaskTwo(image_lists, index)"
              style="
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              "
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 精彩视频-列表-移动端 video_item_mobile -->
    <div
      class="video_lists flex flex-wrap justify-space-between"
      v-if="isShowTab == 'video'"
    >
      <div
        class="video_item video_item_flexitem"
        v-for="(item, index) in videoLists"
        :key="index"
      >
        <div
          class="item_img"
          @click="
            showImageMask_video(item.video, item.cover, index, videoLists)
          "
        >
          <img :src="item.cover" />
          <div class="video_mask">
            <div class="video_play"></div>
          </div>
        </div>
        <div class="video_title">
          {{ ali_jiashu_lang == "cn" ? item.title : item.title_en }}
        </div>
        <div class="video_desc">
          {{ ali_jiashu_lang == "cn" ? item.desc : item.desc_en }}
        </div>
      </div>
    </div>
    <!-- 底部发布按钮 -->
    <div
      class="bottom_btn"
      @click="ShowUpload()"
      v-if="isShowTab == 'message' && ali_jiashu_lang == 'cn'"
    >
      <!-- <div class="bottom_btn_div bottom_btn_div_cn">
        <div class="bottom_btn_icon bottom_btn_icon_cn"></div>
        我要留言
      </div> -->
      <div class="isloading" v-if="isShowLoading">
        {{ ali_jiashu_lang == "cn" ? "正在加载..." : "Loading..." }}
      </div>
    </div>
    <div
      class="bottom_btn"
      @click="ShowUpload()"
      v-if="isShowTab == 'message' && ali_jiashu_lang == 'en'"
    >
      <!-- <div class="bottom_btn_div">
        <div class="bottom_btn_icon"></div>
        Post a comment
      </div> -->
    </div>
    <!-- 右侧回到顶部按钮 -->
    <div class="returnTop" ref="returnTop" @click="returnTop()"></div>
    <!-- 右侧发布按钮 -->
    <!-- <div
      class="left_btn"
      @click="ShowUpload()"
      v-if="isMobile && isShowTab != 'message'"
    >
      <div class="left_btn_text">
        {{ ali_jiashu_lang == "cn" ? "留言" : "Message" }}
      </div>
    </div> -->
    <!-- 中英切换切换 -->
    <div class="lang_box flex" @click="setLang()">
      <div
        :class="
          ali_jiashu_lang == 'cn'
            ? 'lang_bg lang_bg_left'
            : 'lang_bg lang_bg_right'
        "
      ></div>
      <div
        class="lang_cn"
        :style="ali_jiashu_lang == 'cn' ? 'color: white' : ''"
      >
        中文
      </div>
      <div
        class="lang_en"
        :style="ali_jiashu_lang == 'en' ? 'color: white' : ''"
      >
        EN
      </div>
    </div>
    <!-- 上传选择分类 -->
    <div class="upload_mask" v-if="isShowUpload" @click.stop="closeUpload()">
      <div class="type_box flex justify-content-between">
        <div class="upload_image" @click.stop="goToUpload('2')">
          <div class="upload_image_text">
            {{ ali_jiashu_lang == "cn" ? "发布图片" : "Post a photo" }}
          </div>
          <!-- <input
            @click.stop=""
            type="file"
            class="uploadInput"
            @change="addImg"
            ref="inputer"
            multiple
            accept="image/png,image/jpeg,image/gif,image/jpg"
          /> -->
        </div>
        <div class="upload_video" @click.stop="goToUpload('1')">
          <div class="upload_image_text">
            {{ ali_jiashu_lang == "cn" ? "发布视频" : "Post a video" }}
          </div>
          <!-- <input
            @click.stop=""
            type="file"
            class="uploadInput"
            @change="addVideo"
            ref="inputer"
            accept="video/*"
          /> -->
        </div>
      </div>
    </div>
    <!-- loading上传中 -->
    <div class="upload_loading_mask" v-if="isShowUploadLoading">
      <div class="upload_loading_mask_content">
        <div class="upload_loading_mask_icon"></div>
        <div class="upload_loading_mask_text">
          {{
            ali_jiashu_lang == "cn"
              ? "上传中，请勿离开当前页面"
              : "Uploading. Keep the page open"
          }}
        </div>
      </div>
    </div>

    <!-- 留言详情 -->
    <div class="detailsModel" v-if="isShowdetailsModel">
      <message-details
        :messageInfoDetails="messageInfoDetails"
        :isShowdetailsModel="isShowdetailsModel"
        @changeComponentShow="changeComponentShow"
      ></message-details>
    </div>
    <!-- 查看留言图片\视频 -->
    <div class="lookImgModel" v-if="isShowLookImgModel">
      <lookimg-model
        :messageInfoDetails="messageInfoDetails"
        :isShowLookImgModel="isShowLookImgModel"
        @changeLookimgShow="changeLookimgShow"
      ></lookimg-model>
    </div>
    <!-- PC查看留言图片\视频 -->
    <div class="lookImgModel" v-if="isShowLookImgPcModel">
      <lookpcimg-model
        :messageInfoDetails="messageInfoDetails"
        :isShowLookImgPcModel="isShowLookImgPcModel"
        @changeLookimgPcShow="changeLookimgPcShow"
      ></lookpcimg-model>
    </div>

    <!-- mo-文化活动-查看图片 -->
    <div class="seeImageModel" v-if="isShowClassImgModel">
      <classimg-model
        :classImgarr="classImgarr"
        :classImgarrIndex="classImgarrIndex"
        :isShowClassImgModel="isShowClassImgModel"
        @changeSeeClassimgShow="changeSeeClassimgShow"
      ></classimg-model>
    </div>

    <!-- pc-文化活动-查看图片 -->
    <div class="seeImageModel" v-if="isShowPcClassImgModel">
      <pcclassimg-model
        :classImgarr="classImgarr"
        :classImgarrIndex="classImgarrIndex"
        :isShowPcClassImgModel="isShowPcClassImgModel"
        @changeSeeClassimgPcShow="changeSeeClassimgPcShow"
      ></pcclassimg-model>
    </div>

    <!-- pc《分享给家人》提示扫码移动端 -->
    <div class="ewm_tips_mask" v-if="isShowPcShare" @click="closePcShare()">
      <div
        :class="
          ali_jiashu_lang == 'cn'
            ? 'ewm_tips_content'
            : 'ewm_tips_content ewm_tips_content_en'
        "
      >
        <div class="ewm_tips_content_close"></div>
      </div>
    </div>

    <!-- pc《我要发布》提示扫码移动端 -->
    <div class="ewm_tips_mask" v-if="isShowPcSubmit" @click="closePcShare()">
      <div
        :class="
          ali_jiashu_lang == 'cn'
            ? 'ewm_tips_content ewm_tips_content_Submit'
            : 'ewm_tips_content ewm_tips_content_en ewm_tips_content_Submit_en'
        "
      >
        <div class="ewm_tips_content_close"></div>
      </div>
    </div>

    <!-- 分享亲友提示 -->
    <div
      class="upload_success_mask share_mask"
      v-if="isShowShare"
      @click="closeShowShare()"
    >
      <div
        :class="
          ali_jiashu_lang == 'cn' ? 'share_icon' : 'share_icon share_icon_en'
        "
      ></div>
    </div>
  </div>
</template>

<script>
import {
  getCommentList,
  registerTourist,
  getSTSToken,
  getUserInfo,
  getUserComments,
  deleteComment,
} from "@/api/api"; // 导入我们的api接口
import * as dd from "dingtalk-jsapi";
import { formatTime } from "@/untils/tools"; // Function
import vueWaterfallEasy from "vue-waterfall-easy"; // 瀑布流
import messageDetails from "../components/model/details.vue"; // 留言详情页面
import lookimgModel from "../components/model/lookimg.vue"; // 查看图片页面
import lookpcimgModel from "../components/model/lookpcimg.vue";

import classimgModel from "../components/model/lookclassimg.vue"; // 查看图片页面
import pcclassimgModel from "../components/model/lookpcclassimg.vue";

export default {
  inject: ["reload"],
  components: {
    vueWaterfallEasy,
    messageDetails,
    lookimgModel,
    lookpcimgModel,
    classimgModel,
    pcclassimgModel,
  },
  props: {},
  data() {
    return {
      // 视频播放
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //可选择的播放速度
        autoplay: true, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            type: "",
            src: "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/2021%E9%98%BF%E9%87%8C%E5%AE%B6%E4%B9%A6%E4%B8%BB%E9%A2%98%E3%80%8A%E6%83%B3%E5%92%8C%E4%BD%A0%E8%AF%B4%E3%80%8B.mp4", //url地址
          },
        ],
        poster:
          "https://img.alicdn.com/imgextra/i1/O1CN01gQRC7A1yZdzjQjTvk_!!6000000006593-2-tps-1920-1080.png", //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true, //当前时间和持续时间的分隔符
          durationDisplay: true, //显示持续时间
          remainingTimeDisplay: false, //是否显示剩余时间功能
          fullscreenToggle: true, //全屏按钮
        },
      },
      isShowShare: false, // 分享亲友蒙层提示
      isShowLoading: false, //是否显示正在加载
      ali_jiashu_lang: "cn",
      isMobile: null,
      isShowUpload: false,
      isShowTab: "message", // 留言列表:message 文化活动:class 精彩视频:video
      classTabListsDetailsTitle: "", //分类对应标题文案（中英）
      classTabListsDetailsDesc: "", //分类对应简介文案（中英）
      classTabListsDetailsCover: "", //分类对应封面图
      classTabListsDetailsVideo: "", //分类对应简介文案
      classTabLists: [
        {
          title: "阿里日",
          title_en: "Ali Day",
        },
        {
          title: "双11",
          title_en: "Double 11",
        },
        {
          title: "年陈",
          title_en: "Aliversary",
        },
        {
          title: "社会责任",
          title_en: "Social Responsibility",
        },
        {
          title: "集体婚礼",
          title_en: "Group Wedding",
        },
        {
          title: "拔河大赛",
          title_en: "Tug of War",
        },
        {
          title: "音乐季",
          title_en: "Music Season",
        },
        {
          title: "活色生香",
          title_en: "Events",
        },
      ], // 文化活动tab栏数据
      classTabListsDetails: [
        {
          title: "阿里日",
          title_en: "Ali Day",
          desc: "阿里日是阿里精神的纪念日，阿里人共同欢聚的节日，也是阿里人对亲友、合作伙伴表达感恩的日子。是大家的支持和陪伴，让我们更加坚定地向梦想奔跑。",
          desc_en:
            "Ali Day is intended to commemorate the Alibaba spirit and bring Aliren together. On this day, we express our gratitude to our loved ones.",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01pZken11QWG858lFR4_!!6000000001983-2-tps-3204-1668.png",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E9%98%BF%E9%87%8C%E6%97%A5.mp4",
        },
        {
          title: "双11",
          title_en: "Double 11",
          desc: "双11是消费者的节日，也是我们业务的校验场，是阿里人的大团建。这一天，因盼望和跨越而不同。",
          desc_en:
            "The Double 11 Global Shopping Festival is not just about business; it's the biggest team building for Aliren.",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01RUwmHk1jRCS6yXWbV_!!6000000004544-2-tps-3198-1678.png",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E3%80%8A%E5%8F%8C11%E5%85%A8%E8%AE%B0%E5%BD%95%E5%BF%AB%E5%89%AA%E3%80%8B1112%E3%80%90%E7%A1%AE%E8%AE%A4%E7%89%88%E3%80%91.mp4",
        },
        {
          title: "年陈",
          title_en: "Aliversary",
          desc: "年陈，记录着每一个阿里人的成长。“一年香，三年醇，五年陈，十年馨”，每一个阿里人都会在时光里沉淀出属于自己的阿里味儿。",
          desc_en:
            "At Alibaba, we go through special rites of passage for our work anniversaries known as 'Aliversaries.'",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01s25HCA1OFeX7VMsxY_!!6000000001676-2-tps-3202-1664.png",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/2020Q4%E3%80%812021Q1%E9%98%BF%E9%87%8C%E5%B7%B4%E5%B7%B4%E4%BA%94%E5%B9%B4%E9%99%88%E6%8E%88%E6%88%92%E4%BB%AA%E5%BC%8F%E8%A7%86%E9%A2%91.mp4",
        },
        {
          title: "社会责任",
          title_en: "Social Responsibility",
          desc: "如果说“让天下没有难做的生意”是阿里巴巴为中小企业提升商业效率、促进商业发展的使命，“人人参与、才是公益”则是阿里巴巴解决社会问题、创造社会价值的初心。我们相信，行动，带来改变。",
          desc_en:
            "At Alibaba, we aspire to address social problems and create social value for all. We believe that for change to happen, every Aliren needs to take action.",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01fR7wVv1ik9DrW4Loq_!!6000000004450-2-tps-3088-1662.png",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E7%A4%BE%E4%BC%9A%E8%B4%A3%E4%BB%BB.mp4",
        },
        {
          title: "集体婚礼",
          title_en: "Group Wedding",
          desc: "每年阿里日，我们都会在园区举行一场盛大的集体婚礼。这个传统沿袭至今，已是第16个年头。无论顺境还是挑战，我们一起去热爱。",
          desc_en:
            "On Ali Day, 102 newly married Aliren and their partners gathered on Xixi Campus for the annual group wedding.",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN013tNOpK23xz7TV1cXR_!!6000000007323-2-tps-3212-1652.png",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E9%9B%86%E4%BD%93%E5%A9%9A%E7%A4%BC%E5%BC%80%E5%9C%BA%E4%B8%BB%E9%A2%98%E7%89%87%E3%80%8A%E5%B0%B1%E6%98%AF%E4%BD%A0%E3%80%8B0510%E6%92%AD%E5%87%BA%E7%89%88-2.mp4",
        },
        {
          title: "拔河大赛",
          title_en: "Tug of War",
          desc: "今年的客户日，我们邀请客户来园区，共同参加阿里巴巴第二届拔河大赛，大家拧成一股绳，为未来而战！",
          desc_en:
            "Customer reps were invited to participate in our Tug of War competition on Xixi Campus on September 10 to celebrate Customer Day.",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN013zRzDK1WM51PgIAh3_!!6000000002773-2-tps-3232-1678.png",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/910%E5%AE%A2%E6%88%B7%E6%97%A5%E6%8B%94%E6%B2%B3%E6%AF%94%E8%B5%9B.mp4",
        },
        {
          title: "音乐季",
          title_en: "Music Season",
          desc: "这个夏天，音乐巴士驶向各个园区，在夏日制造快乐。响起的是音乐，也是阿里人的青春和热爱。",
          desc_en:
            "In July, a Happy Hour Tent was put up on Xixi Campus, equipped with Karaoke machines. It soon became an attraction for colleagues who wanted to have a good time.",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01WIL4Ug1MMP8uaWbus_!!6000000001420-2-tps-3110-1634.png",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E3%80%8AAha%E4%B8%80%E8%B5%B7%E5%94%B1%E6%AD%8C%E5%90%A7%E3%80%8B.mp4",
        },
        {
          title: "活色生香",
          title_en: "Events",
          desc: "快乐和激情是阿里人不变的颜色，我们各不相同，却有着共同的底色，温暖而明亮。这一年，我们启程去到了更多新园区，站上年会舞台闪闪发光，围坐在圆桌会上畅所欲言，一起在球场上挥洒汗水……业务线丰富多彩的文化活动，与你分享。",
          desc_en:
            "Fun is at the core of Alibaba’s culture as we firmly believe in creating an enjoyable workplace. A series of events took place on Alibaba campuses in 2021.",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01djbnZ01MqdHgyUpkw_!!6000000001486-2-tps-3236-1676.png",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E9%98%BF%E9%87%8C%E5%8D%81%E6%B4%BE1min-1.mp4",
        },
      ],
      image_lists: [require("../assets/img/image_lists_img.png")],
      active_tab: "阿里日", //选中——文化活动tab栏
      isShowClassContent: true, // 文化活动TAB对应模块是否显示
      videoLists_pc: [],
      videoLists: [
        {
          title: "《This is Alibaba》",
          title_en: "《This is Alibaba》",
          desc: "阿里巴巴是什么？一个商标？一家公司？一个生态？我们尝试着在5分钟里，向你展示一个充满活力和无限可能的阿里。影片通过阿里同学的眼睛，带领镜头穿梭园区，寻访达人，体验文化⋯⋯以最真实的视角穿梭于阿里园区和阿里人之中。",
          desc_en:
            "This 5-minute-long video gives you a glimpse of the company's offices, employees and culture through a series of interviews. Click and feel the positive vibes!",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN0115fqk827v119qYxDe_!!6000000007858-0-tps-4086-2160.jpg",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/2021%E9%98%BF%E9%87%8C%E6%96%87%E5%8C%96%E5%AE%A3%E4%BC%A0%E7%89%87%E3%80%901080P%E3%80%91%E6%9C%80%E7%BB%88%E7%89%88.mp4",
        },
        {
          title: "《阿里巴巴暖心计划》",
          title_en: "《WeCare Plan》",
          desc: "一家企业，一个组织，最好的作品是身在其中的每个人。阿里巴巴暖心计划在今年发布，请带上这份心意和对未来的承诺，我们一起出发。",
          desc_en:
            "This short segment showcases the newest family-friendly benefits and perks that Aliren can enjoy! Click to see the latest changes!",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN0182ZpUF1qrUUzcwYNO_!!6000000005549-2-tps-3210-1654.png",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E9%98%BF%E9%87%8C%E5%B7%B4%E5%B7%B4%E6%9A%96%E5%BF%83%E8%AE%A1%E5%88%92.mp4",
        },
        {
          title: "《和逍遥子一起喝果汁，在聊点啥》",
          title_en: "《Juice with Daniel》",
          desc: "截至2021年11月，「老逍果汁会」已举办15期，同学们畅所欲言、真诚相见，为更好的阿里巴巴发声。内网有近140万人次围观，累计收到3225份报名，157位同学受邀来到现场，和CEO面对面讨论“员工福利”、“客户价值”、“新财年改革方向”、“优秀管理者的要与不要”等热议话题。许多美好的改变也由此发生，如取消周报、转岗优化、调薪倾斜、餐补和差旅提升、iHome调整、商保升级等敏捷组织改革。",
          desc_en:
            "As of November 2021, a total of 157 colleagues were invited to discuss hot topics with Daniel in the communication event dubbed “Juice with Daniel,” which drew nearly 1.4 million views on the intranet. The discussions have resulted in a slew of reforms such as easing restrictions on internal transfers and optimizing promotion mechanisms.",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01sDdCws1lJX3SyFKQ7_!!6000000004798-0-tps-1920-1080.jpg",
          video:
            " https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E3%80%8A%E8%80%81%E9%80%8D%E6%9E%9C%E6%B1%81%E4%BC%9A%E5%B1%95%E7%A4%BA%E7%89%87%E3%80%8B0107-2%E9%98%BF%E9%87%8C%E5%AE%B6%E4%B9%A6%E6%92%AD%E5%87%BA%E7%89%88.mp4",
        },
        {
          title: "《1.5℃》",
          title_en: "《1.5℃》",
          desc: "阿里巴巴正式发布《阿里巴巴碳中和行动报告》。面向未来，我们提出三大目标：不晚于2030年实现自身运营碳中和；不晚于2030年实现上下游价值链碳排放强度减半，率先实现阿里云的价值链碳中和，成为绿色云；用15年时间，以平台之力带动生态减碳15亿吨。",
          desc_en:
            "There is no Planet B! Facing the future, Alibaba has released their carbon neutralization action report. Click to see what actions Alibaba is taking for a more green and sustainable future!",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01naNFNy1QxHV9Dmiuy_!!6000000002042-0-tps-1920-1080.jpg",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E3%80%8A1.5%E2%84%83%E3%80%8B%E6%9C%80%E7%BB%88%E9%AB%98%E6%B8%85%E7%89%88.mp4",
        },
        {
          title: "《让精彩每天出彩》",
          title_en: "《Digitalizing Olympics》",
          desc: "数字化奥运的进程中，阿里巴巴集团是参与者，创造者，也是推动者，为百年奥运架起一座更快捷、更畅通、更便利的数字之桥。我们关注的冬奥精彩，不只在赛场上，更在我们每个人身边，越来越多人透过云计算和电商服务参与冰雪运动，不仅能看到一场精彩绝伦的北京冬奥会，更在日常生活中亲身体验冰雪运动的乐趣，让冬奥精彩在生活中每天出彩！",
          desc_en:
            "Alibaba Group has been pressing ahead with the digitalization of the Olympic Games. Our focus is not just on the Winter Olympic competitions, but also on how each and every one of us engages with it. More and more people are participating in Olympic events through cloud computing and e-commerce services.",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN016d9hZx25DqSJNrZvj_!!6000000007493-2-tps-1896-703.png",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E5%8F%8C%E5%BE%AE%E5%A4%A7%E6%A1%A3-%E4%B8%BB%E5%BC%A0%E7%AF%87.mp4",
        },
        {
          title: "《唐小菊穿越记》",
          title_en: "《Tang Xiaoju’s Journey》",
          desc: "阿里巴巴年会上，有人许了个愿：要是能回到20年前，在阿里巴巴刚刚创立的时候，那么自己一定能够升职加薪走上人生巅峰，于是，奇迹出现了……10分钟短片讲述阿里巴巴创业历程：致敬每一个看似微渺的小梦想，致敬每一位正在路上的追梦人。",
          desc_en:
            "If you could go back when Alibaba was founded, would you still join? This ten-minute short film tells the tale of Alibaba’s entrepreneurial process: paying tribute to every seemingly insignificant dream and every dream chaser. Click to witness the journey! ",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01UUpOnf22KlanFFeQW_!!6000000007102-0-tps-1920-1080.jpg",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/0912%E5%94%90%E5%B0%8F%E8%8F%8A%E7%A9%BF%E8%B6%8A%E8%AE%B0%EF%BC%88%E5%AE%8C%E6%95%B4%E7%89%88%EF%BC%89.mp4",
        },
        {
          title: "《11个生活的向往》",
          title_en: "《11 Yearnings of Life》",
          desc: "从淘宝村播主播、山区的手艺人、品牌出海的商家、塑料制成衣服的新潮人，到包罗万象的消费者，不断创造的阿里小二⋯⋯双11纽带上11个对于美好生活有着热切期盼的人，这些愿望各不相同却串联起一个的共同向往。让科技更普惠，让消费更绿色，让交易有温度，让乡村变热土，一起关注每份向上的力量。",
          desc_en:
            "11 people walk different paths and face different hardships, however, they all share a common yearning: to have a better life. Click to see how Alibaba helps them in the pursuit of this dream.",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01gjZa3Z1E5RYWxdfEB_!!6000000000300-0-tps-1920-1080.jpg",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E3%80%8A11%E4%B8%AA%E7%94%9F%E6%B4%BB%E7%9A%84%E5%90%91%E5%BE%80%E3%80%8B%E5%AF%B9%E5%A4%96%E7%89%881102%281%29.mp4",
        },
        {
          title: "《阿里巴巴的减碳行动》",
          title_en: "《Alibaba’s Carbon Reduction Action》",
          desc: "“碳中和”是名词也是动词，每个人参与其中，就能让生活更“绿”一点。从盒马减塑行动、张北数据中心、菜鸟绿色包裹/回收、到高德绿色出行碳普惠程序⋯⋯阿里巴巴正在搭建绿色生态，进行减碳行动。",
          desc_en:
            "From Freshippo’s plastic reduction actions to Cainiao’s green packaging/recycling to Amap’s green travel carbon reduction program, Alibaba is working towards carbon neutralization. Click to see how Alibaba is building a greener future!",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01b8TDy31KZ6uCOrFq8_!!6000000001177-0-tps-1920-1080.jpg",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E3%80%8A%E9%98%BF%E9%87%8C%E5%B7%B4%E5%B7%B4%E7%9A%84%E5%87%8F%E7%A2%B3%E8%A1%8C%E5%8A%A8%E3%80%8B.mp4",
        },
        {
          title: "《今年双11为什么要更绿色？》",
          title_en: "《How Double 11 Went Green》",
          desc: "我们为何需要绿色的双11？影片通过通俗易懂的动画和语言，从我国的“国家战略”和阿里的“客户第一”两个维度，分别阐述了双11追求更低碳更绿色更环保的思考和行动。",
          desc_en:
            "This 5-minute short video displays the actions of this year’s Double 11 shopping festival in pursuit of a lower carbon and greener future that also benefits customers. Click to see the incredible initiatives!",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01mRI6HP1xd1IM0wz9F_!!6000000006465-2-tps-3216-1660.png",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E3%80%8A%E4%BB%8A%E5%B9%B4%E5%8F%8C11%E4%B8%BA%E4%BB%80%E4%B9%88%E8%A6%81%E6%9B%B4%E7%BB%BF%E8%89%B2%EF%BC%9F%E3%80%8B%E2%80%94%E2%80%9411.01.mp4",
        },
        {
          title: "《阿里西溪风景观》",
          title_en: "《Xixi Campus Tour》",
          desc: "什么是阿里园区最美的风景？是自然湿地？现代建筑？多元社区？还是同学校园？都是，都不只是。园区最美的不只是风景，人是园区最美的风景。每一个认真生活、快乐工作的同学，都是这个园区里生生不息、闪闪发光的一道风景。在这里，在一起，园区因你而美好，时光因你而生辉。",
          desc_en:
            "This video takes you on a virtual tour on Alibaba's Xixi campus. Enjoy the scenery and meet the lovely people!",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN011YJUzY1cyRtsQUAZe_!!6000000003669-0-tps-1920-1080.jpg",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E3%80%8A%E9%98%BF%E9%87%8C%E8%A5%BF%E6%BA%AA%E9%A3%8E%E6%99%AF%E8%A7%82%E3%80%8B%E6%88%90%E7%89%87%EF%BC%88%E5%B8%B8%E8%A7%84%E5%B0%BA%E5%AF%B81920_1080%EF%BC%89%E7%A0%81%E7%8E%8725M.mp4",
        },
        {
          title: "《唐小菊跑双11之今年买点啥》",
          title_en: "《Double 11 Kaleidoscope: Shopping List》",
          desc: "又到双11，好东西真是太多了，到底应该买点啥？唐小菊和双11的PD们对对碰，共话双11的各种创新小工具。",
          desc_en:
            "Tang Xiaoju interviews colleagues who are working on the frontlines of Double 11 to discover this year's innovations.",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01sXK2IH1UZFArDLIoz_!!6000000002531-0-tps-1920-1080.jpg",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E3%80%8A%E5%94%90%E5%B0%8F%E8%8F%8A%E8%B7%91%E5%8F%8C11%E4%B9%8B%E4%BB%8A%E5%B9%B4%E4%B9%B0%E7%82%B9%E5%95%A5%E3%80%8B%E6%97%A0%E8%BE%B9%E6%A1%86%E7%89%88_1129.mp4",
        },
        {
          title: "《唐小菊跑双11之奋进的菜鸟》",
          title_en: "《Double 11 Kaleidoscope: Cainiao》",
          desc: "购物体验好，还靠物流兜底。今年双11，菜鸟准备得怎么样？走进菜鸟的一线仓库、驿站，与负责同学一起聊聊，今年双11菜鸟都做了哪些准备。",
          desc_en:
            "Tang Xiaoju interviews Cainiao colleagues during the Double 11 Global Shopping Festival.",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01Yhx4rM1Omd3fNbbpX_!!6000000001748-0-tps-1920-1080.jpg",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E3%80%8A%E5%94%90%E5%B0%8F%E8%8F%8A%E8%B7%91%E5%8F%8C11%E4%B9%8B%E5%A5%8B%E8%BF%9B%E7%9A%84%E8%8F%9C%E9%B8%9F%E3%80%8B%E6%97%A0%E8%BE%B9%E6%A1%86%E7%89%88_1129.mp4",
        },
        {
          title: "《唐小菊跑双11之绿色总动员》",
          title_en: "《Double 11 Kaleidoscope: Go Green》",
          desc: "今年双11，行政同学依然是大家可靠的后勤大管家。唐小菊和行政同学一起详解双11的后勤保障，也希望大家做好身边的小环保，打造绿色低碳的双11。",
          desc_en:
            "Tang Xiaoju interviews admin colleagues to discover what they have done to make the shopping festival eco-friendly.",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01TgGapG1bOtXWFGYmb_!!6000000003456-0-tps-1920-1080.jpg",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E3%80%8A%E5%94%90%E5%B0%8F%E8%8F%8A%E8%B7%91%E5%8F%8C11%E4%B9%8B%E7%BB%BF%E8%89%B2%E6%80%BB%E5%8A%A8%E5%91%98%E3%80%8B%E6%97%A0%E8%BE%B9%E6%A1%86%E7%89%88_1129.mp4",
        },
        {
          title: "《唐小菊跑双11之打卡最美园区》",
          title_en: "《Double 11 Kaleidoscope: Campuses》",
          desc: "今年双11，一起把温暖和感恩延续。唐小菊带着大家一起走进双11园区的美丽光影，领略一个温暖、欢乐、有意义的双11。",
          desc_en:
            "Tang Xiaoju shows us around Alibaba campuses, which are decorated with special banners and lights during Double 11.",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01GhRg4Q1b14wYskalr_!!6000000003404-0-tps-1920-1080.jpg",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E3%80%8A%E5%94%90%E5%B0%8F%E8%8F%8A%E8%B7%91%E5%8F%8C11%E4%B9%8B%E6%89%93%E5%8D%A1%E6%9C%80%E7%BE%8E%E5%9B%AD%E5%8C%BA%E3%80%8B%E6%97%A0%E8%BE%B9%E6%A1%86%E7%89%88_1129.mp4",
        },
        {
          title: "《什么是阿里日》",
          title_en: "《All About Ali Day》",
          desc: "为了纪念2003年5月的“非典”时期阿里人的激情和信念，阿里巴巴将之后每年的5月10日定为“阿里日”。让阿里亲友走进阿里园区，感受阿里文化。非典给我们带来的，不仅仅是一次SOHO的经历，更在于我们共同找到阿里精神的真正内涵所在。",
          desc_en:
            "Ali Day commemorates the Alibaba ethos that helped colleagues weather the SARS crisis in 2003. Watch the video to discover what Ali Day is all about.",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01esIwzM1b5eu5oB91i_!!6000000003414-0-tps-1920-1080.jpg",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E4%BB%80%E4%B9%88%E6%98%AF%E9%98%BF%E9%87%8C%E6%97%A5%EF%BC%88MG%E5%8A%A8%E7%94%BB%EF%BC%89.mov",
        },
        {
          title: "《20年时代》",
          title_en: "《20 Years》",
          desc: "从第一次输入字符的激动，到万物互联的数字时代。20年互联网时代回顾，致敬共同参与20年互联网时代的你我，找寻你的互联网回忆。这同时也是2019年阿里巴巴20周年年会的开场主题片。",
          desc_en:
            "Played at Alibaba's 20th anniversary gala, this video was like a time machine that took the audiences back in time to the day when Alibaba was launched.",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01eLIQ2i1PCHEjkeWwo_!!6000000001804-0-tps-1920-1080.jpg",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/0909-20%E5%B9%B4%E6%97%B6%E4%BB%A3-%E7%9B%B4%E6%92%AD-30p.mp4",
        },
        {
          title: "《阿里巴巴20周年年会Opening：城市乐章》",
          title_en: "《A Symphony of the Cities》",
          desc: "阿里巴巴的各个园区建筑化身为乐器，共同演绎出一曲时代的交响。",
          desc_en:
            "This video was played at the opening of the Alibaba 20th Anniversary Gala, which gave audiences a glimpse of the company's campuses and office buildings.",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01KLywJA1bvPetTs1ct_!!6000000003527-0-tps-1920-1080.jpg",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E3%80%8A%E9%98%BF%E9%87%8C%E5%B7%B4%E5%B7%B420%E5%91%A8%E5%B9%B4%E5%B9%B4%E4%BC%9AOpening%EF%BC%9A%E5%9F%8E%E5%B8%82%E4%B9%90%E7%AB%A0%E3%80%8B.mp4",
        },
        {
          title: "《春日运动季：动起来》",
          title_en: "《Spring Sports: Move》",
          desc: "春天的意义，是新生活的开始，也是开启新一年“健康管理”的好时机。今年春天，我们发起了健康运动季，号召全体阿里人预备备，在春天里动起来！",
          desc_en:
            "This Spring a health campaign was introduced, calling on all Aliren to get out there and “move”. Are you ready? Click to see what active actions Aliren are taking!",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01ptfLZP27roF3Lwhh5_!!6000000007851-2-tps-3046-1642.png",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/%E6%98%A5%E6%97%A5%E8%BF%90%E5%8A%A8%E5%AD%A3%E3%80%8A%E5%8A%A8%E8%B5%B7%E6%9D%A5%E3%80%8B.mp4",
        },
        {
          title: "《欢迎来到阿里的世界》",
          title_en: "《Welcome to Alibaba》",
          desc: "阿里巴巴集团招聘主题片。想体验在阿里的一天吗？新同学，请跟我来~",
          desc_en: "Interested in joining Alibaba? Follow us here!",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01jxGuLl1wPuLICPsgu_!!6000000006301-2-tps-1629-806.png",
          video:
            "https://homebook-family.oss-cn-shanghai.aliyuncs.com/2021/video/b%E7%AB%99%E6%8A%95%E6%94%BE%E7%89%88%E6%9C%AC500%E5%86%85.mp4",
        },
      ], // 精彩视频数据列表
      messagelistarr: [], // 留言列表
      leftMessagelistarr: [], // 瀑布流-留言列表-左
      centerMessagelistarr: [], // 瀑布流-留言列表-中间
      rightMessagelistarr: [], // 瀑布流-留言列表-右
      page: 1, // 留言列表-页码
      pageSize: 10, // 留言列表-条数
      isHasMore: true, // 留言列表是否还有更多
      isShowdetailsModel: false, // 是否显示详情蒙层
      messageInfoDetails: null, // 留言详情数据
      classImgarr: null, // 分类照片数组
      classImgarrIndex: 0, // 分类照片数组-下标
      isShowLookImgModel: false, // 是否显示查看图片蒙层
      isShowLookImgPcModel: false, // 是否显示查看图片蒙层
      isShowClassImgModel: false, // 是否显示分类查看查看图片蒙层-mo
      isShowPcClassImgModel: false, // 是否显示分类查看查看图片蒙层-pc
      currentClient: null,
      bucket: "homebook-family",
      region: "oss-cn-shanghai",
      credentials: {
        access_key_id: null,
        access_key_secret: null,
        expiration: null,
        security_token: null,
      }, // STS凭证
      linkurlArr: [], // 已上传资源链接
      linkurlArrnum: 0, // 已上传资源链接
      isShowUploadLoading: false, // 是否显示上传loading
      userInfoId: null, // 当前那用户id
      isShowPcShare: false, //pc《分享给家人》提示扫码移动端
      isShowPcSubmit: false, //pc《我要发布》提示扫码移动端

      // 文化活动图片-阿里日
      alidayImgarr: [
        {
          name: "4",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01fjqgA01PFwOr6dDUN_!!6000000001812-0-tps-4032-3024.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01NDaumj1d6Ed7EhDLV_!!6000000003686-0-tps-4032-3024.jpg",
        },
        {
          name: "58D3E02E-60C7-4862-BCEF-D41A968CB0CF-6946-000002C536B198D7",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01NDXcfT1iU7MXKZUrb_!!6000000004415-0-tps-3190-2127.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01aoqMMn1qw4Sgtvr0Y_!!6000000005559-0-tps-3190-2127.jpg",
        },
        {
          name: "74B1AEAA-0A04-4765-BB9A-C0BE4C2C21E0-6946-000002C2522FA276",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN017LHlxE1VwQqBjqPrn_!!6000000002717-0-tps-2436-1624.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01HT7l3F1Rm7SZo9F5w_!!6000000002153-0-tps-2436-1624.jpg",
        },
        {
          name: "78B929E4-60EF-4C73-BB55-D6D66A914F4C-10241-000003EB13E7163A",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01sqp4vJ1OHwW56EbDr_!!6000000001681-0-tps-3075-2050.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01VNYj491Id78GQ9hzU_!!6000000000915-0-tps-3075-2050.jpg",
        },
        {
          name: "510滨江园区3",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN016xNeFh1iBL7jmSvwC_!!6000000004374-0-tps-1200-675.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01M24pfZ1Wut7QqRIpo_!!6000000002849-0-tps-1200-675.jpg",
        },
        {
          name: "510滨江园区7",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN014fJDk31hZme3MqNDl_!!6000000004292-0-tps-1079-607.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01B1KQB91VzBDXbwn6c_!!6000000002723-0-tps-1079-607.jpg",
        },
        {
          name: "29886275_20210510122620_217479_212903329_YGZ05649",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01p4JqCf1sgcKQ96yBD_!!6000000005796-0-tps-3000-2001.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01I43yZ21rBBX2dbI4l_!!6000000005592-0-tps-3000-2001.jpg",
        },
        {
          name: "134341066",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01gprNpr27XCQBc0JMc_!!6000000007806-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01HL3hDt1NJ1q7dPaJN_!!6000000001548-0-tps-3000-2000.jpg",
        },
        {
          name: "134814798",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01h9TYdh1LX6mlwDRrG_!!6000000001308-0-tps-1991-1327.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN017fKmf824P0UKskqlO_!!6000000007382-0-tps-1991-1327.jpg",
        },
        {
          name: "134919946",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01P62sbh1I9LO23dcRS_!!6000000000850-0-tps-2703-1831.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01hxRxCu1JzqP00TMQv_!!6000000001100-0-tps-2703-1831.jpg",
        },
        {
          name: "134992838",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01yChXwP23IJ4tf9Npl_!!6000000007232-0-tps-2949-1966.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01Sf9toD1sc2MrQoBmf_!!6000000005786-0-tps-2949-1966.jpg",
        },
        {
          name: "135046886",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN015ow4E41oMgd2zqf0F_!!6000000005211-0-tps-2037-1358.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01JlKVla1RKBIYg5ElB_!!6000000002092-0-tps-2037-1358.jpg",
        },
        {
          name: "135239818",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01jKwXID1VGknVG9Lr2_!!6000000002626-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01RM0L7R1djAIDIzTO0_!!6000000003771-0-tps-3000-2000.jpg",
        },
        {
          name: "135255196",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN017fppIb1Ug775ZX4Da_!!6000000002546-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01gVukgu1hfHOdiDqDw_!!6000000004304-0-tps-2048-1365.jpg",
        },
        {
          name: "135295646",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01FYMZ7G1hX2GiVSQNr_!!6000000004286-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01CgS0CQ1vZEnB8lAn2_!!6000000006186-0-tps-3000-2000.jpg",
        },
        {
          name: "135296792",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01ok51Zk1pXVbUBDuch_!!6000000005370-0-tps-2048-1362.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01GlRMSq1n7Hh8enVWo_!!6000000005042-0-tps-2048-1362.jpg",
        },
        {
          name: "135316299",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01DAUD3t1eUnUBUSqLC_!!6000000003875-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01xbHZdS1yzIAdFOKIC_!!6000000006649-0-tps-2048-1365.jpg",
        },
        {
          name: "135318305",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01ZO4xGv1LcbXKrAe77_!!6000000001320-0-tps-4034-2269.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01Y92BIS1mH4XnIlQCP_!!6000000004928-0-tps-4034-2269.jpg",
        },
        {
          name: "135319491",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01VmW1ep1VeZOYhfNiD_!!6000000002678-0-tps-4019-2674.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01se5SO61RGW823bF4j_!!6000000002084-0-tps-4019-2674.jpg",
        },
        {
          name: "135333043",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01jayWyk1z4KWnDvpqX_!!6000000006660-0-tps-4003-3002.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01pRqaRX1zlq9aSzmuB_!!6000000006755-0-tps-4003-3002.jpg",
        },
        {
          name: "135333717",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN0125Nq7i1K3xy2KfftG_!!6000000001109-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01UCTDAK1DesZq45T5L_!!6000000000242-0-tps-2048-1365.jpg",
        },
        {
          name: "135365236",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01Qi5Xsa1TamtU2QDJf_!!6000000002399-0-tps-4045-2696.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01I84P1f1i6IlalQewH_!!6000000004363-0-tps-4045-2696.jpg",
        },
        {
          name: "合影",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01ZygDAI1q7gtS54JS2_!!6000000005449-0-tps-5568-3712.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01PkxRe31y2D4tjeH9w_!!6000000006520-0-tps-5568-3712.jpg",
        },
        {
          name: "数农6",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01exWE4l1gwOaFMGqbD_!!6000000004206-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01KJSxj426VXMqNl6jz_!!6000000007667-0-tps-1200-800.jpg",
        },
        {
          name: "西溪园区合伙人",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01QLaB2U29DcitlQLAc_!!6000000008034-2-tps-1200-803.png",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01UUTjaf1yuFoc6k2Dd_!!6000000006638-2-tps-1200-803.png",
        },
        {
          name: "DAMI3218",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN013cGmou1DFgnJLUnNi_!!6000000000187-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01fGJLzX1xWbkQS0qEN_!!6000000006451-0-tps-3000-2000.jpg",
        },
        {
          name: "IMG_2000",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01GF53FP1Ld3vxeUzsq_!!6000000001321-0-tps-2024-1349.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN017tlGxX1sqEe5aLIkQ_!!6000000005817-0-tps-2024-1349.jpg",
        },
        {
          name: "img_8802.jpg-135289414",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01Nkvkm61XJcVdpPeKV_!!6000000002903-0-tps-1940-1293.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01xF71h81DG9BxDqhHZ_!!6000000000188-0-tps-1940-1293.jpg",
        },
        {
          name: "kel_4563",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN014LSHMJ1RxZMY7niW2_!!6000000002178-0-tps-3238-2162.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01IvIcKk1noKvGCba76_!!6000000005136-0-tps-3238-2162.jpg",
        },
        {
          name: "KEL_4913",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01E9QVDE1VWKGR4xo6y_!!6000000002660-0-tps-3238-2162.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01c7DBek1xGZt2ZGwHS_!!6000000006416-0-tps-3238-2162.jpg",
        },
        {
          name: "LEE8831",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01qnP0jH23lcQJMbNB5_!!6000000007296-0-tps-2904-1937.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01mSjUKX1l1fbeg75PX_!!6000000004759-0-tps-2904-1937.jpg",
        },
        {
          name: "PXTL7687",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01DetbuW1eSVVKx2PEY_!!6000000003870-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01as1WXY1aQRFqRAi97_!!6000000003324-0-tps-2048-1365.jpg",
        },
        // {
        //   name: "ROC_1949",
        //   cover:
        //     "https://img.alicdn.com/imgextra/i1/O1CN01cVbx251zBCTAUu1nm_!!6000000006675-0-tps-3000-2000.jpg",
        //   image:
        //     "https://img.alicdn.com/imgextra/i2/O1CN01XdJY141kFa1RM4PwB_!!6000000004654-0-tps-3000-2000.jpg",
        // },
      ],
      // 文化活动图片-年陈
      Aliversary: [
        {
          name: "1T6A7699",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01P7SNVH1itJ949HTd6_!!6000000004470-0-tps-3500-2333.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01ALOoaC23Mt2Ox6QhQ_!!6000000007242-0-tps-3500-2333.jpg",
        },
        {
          name: "2C5A2663",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01fYsSJH23trYXfpX90_!!6000000007314-0-tps-2880-1920.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01PvMjFk1QO105f31lT_!!6000000001965-0-tps-2880-1920.jpg",
        },
        {
          name: "4png",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01NsHBHW1tHG0gVewLx_!!6000000005876-2-tps-866-577.png",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01CYLHIh22setgoLr96_!!6000000007176-2-tps-866-577.png",
        },
        {
          name: "8C7A2207",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01aPepej1atICquElI8_!!6000000003387-0-tps-3200-2134.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01IWqmFj1u13cT3hNgN_!!6000000005976-0-tps-3200-2134.jpg",
        },
        {
          name: "8C7A2379",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01iIVpr41G8JGHfOXsv_!!6000000000577-0-tps-3200-2134.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01iElhqC1cc0UQjLmZY_!!6000000003620-0-tps-3200-2134.jpg",
        },
        {
          name: "8C7A3058",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01kG6hOc1vUCR6yyVYJ_!!6000000006175-0-tps-3200-2134.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01D0ox6826FVVGFmlIr_!!6000000007632-0-tps-3200-2134.jpg",
        },
        {
          name: "8C7A3216",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01uAKy2B1raNJSMgcJU_!!6000000005647-0-tps-3200-2134.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01V6kEiI1dv4ahXgGGc_!!6000000003797-0-tps-3200-2134.jpg",
        },
        {
          name: "121761902",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01uvsPkP1fBOJrX7qmp_!!6000000003968-0-tps-1938-1292.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN019iDDr81WW9jcobHgQ_!!6000000002795-0-tps-1938-1292.jpg",
        },
        {
          name: "122212984",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01rgZjmf1TFifixV7dL_!!6000000002353-0-tps-1953-1302.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01kMrEci1I0drNDBnIt_!!6000000000831-0-tps-1953-1302.jpg",
        },
        {
          name: "122267916",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01f2IdSO1tFQQdre9nX_!!6000000005872-0-tps-1963-1308.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01ZPH5BR1WGaGmEcULd_!!6000000002761-0-tps-1963-1308.jpg",
        },
        {
          name: "122272147",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01oj7qQG1t3W89TC98T_!!6000000005846-0-tps-2043-1361.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01PTmQFU1s1r4oJzipl_!!6000000005707-0-tps-2043-1361.jpg",
        },
        {
          name: "122509470",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01jZBytn1nCmRlnrsVw_!!6000000005054-0-tps-1907-1267.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01qgF2Zn256W7C2xu6l_!!6000000007477-0-tps-1907-1267.jpg",
        },
        {
          name: "135356184",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01L2Qted225eWHsJjAP_!!6000000007069-0-tps-3971-2642.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01UIMVdG22aL3UcW23S_!!6000000007136-0-tps-3971-2642.jpg",
        },
        {
          name: "153369682",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01zubEux1qdkcHKPGML_!!6000000005519-0-tps-2853-1902.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01lufsCh24wROjT9t6Y_!!6000000007455-0-tps-2853-1902.jpg",
        },
        {
          name: "153456941",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01Yavnga27gojq6rUZr_!!6000000007827-0-tps-2021-1347.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01c9whNF225C7l13j0H_!!6000000007068-0-tps-2021-1347.jpg",
        },
        {
          name: "153459531",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01aXzFbU1VWmfBF9r5X_!!6000000002661-0-tps-1970-1313.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01HqJ7En2A2SghaEnrD_!!6000000008145-0-tps-1970-1313.jpg",
        },
        {
          name: "153465039",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01atZ3lf1u1W17lK28W_!!6000000005977-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01G1JjQN1QUswHX864O_!!6000000001980-0-tps-3000-2000.jpg",
        },
        {
          name: "190752826",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01amU1eE1DEJbfYSMuP_!!6000000000184-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN016rcTzS1jrlQT6pD1j_!!6000000004602-0-tps-2048-1365.jpg",
        },
        {
          name: "196332892",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN011bep1n228Ota94gZs_!!6000000007075-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01fOWLcr1lUWYpCHBWB_!!6000000004822-0-tps-1200-800.jpg",
        },
        {
          name: "211811513",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01tEv6Dz1Y22vP6144i_!!6000000003000-0-tps-1200-799.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01bjgxTb1xB58T9HSV0_!!6000000006404-0-tps-1200-799.jpg",
        },
        {
          name: "214017806",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01k08SYO1S0m8CSsmAY_!!6000000002185-0-tps-2048-1362.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01hGDlLA23dNINs9lOq_!!6000000007278-0-tps-2048-1362.jpg",
        },
        {
          name: "214019223",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01V3vvu51rS8BPXFXQI_!!6000000005629-0-tps-2048-1362.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01YkNXjM1okVDvGrSKk_!!6000000005263-0-tps-2048-1362.jpg",
        },
        {
          name: "三年醇2",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01abtxDh1py4Zi3LsHp_!!6000000005428-0-tps-2910-1940.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN015Ot4DR1QaNh7ibRBP_!!6000000001992-0-tps-2910-1940.jpg",
        },
        {
          name: "三年醇3",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01wJrVTB1LeR7PtqBYS_!!6000000001324-0-tps-3199-2133.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01X3yOO71RfFWAS4HUQ_!!6000000002138-0-tps-3199-2133.jpg",
        },
        {
          name: "DSC-1092",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01EKC0SB1cGTsHbGTVy_!!6000000003573-0-tps-6004-4003.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN019uhvpo1nkfkxIhceZ_!!6000000005128-0-tps-6004-4003.jpg",
        },
        {
          name: "DSC-1420",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01ri6Ckp1MfBNmUNV4N_!!6000000001461-0-tps-5521-3681.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01KFW7FW1bzXDfBi98h_!!6000000003536-0-tps-5521-3681.jpg",
        },
        {
          name: "lADPDf0izwyld3TNCdfND38_3967_2519",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01kfwwWe1Ybloym6O8A_!!6000000003078-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN014YoKTi1XDCxvS3I2S_!!6000000002889-0-tps-3967-2519.jpg",
        },
        // {
        //   name: "mmexport4e4c974ba817e38965d556cbad5a1249_1636517712052",
        //   cover:
        //     "https://img.alicdn.com/imgextra/i1/O1CN01kfwwWe1Ybloym6O8A_!!6000000003078-0-tps-2048-1365.jpg",
        //   image:
        //     "https://img.alicdn.com/imgextra/i3/O1CN01yiiI6j1v5vRld393f_!!6000000006122-0-tps-2048-1365.jpg",
        // },
        {
          name: "single",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01btizzi1wO4kzkR0sZ_!!6000000006297-0-tps-4096-2541.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN016TMPkj1JeJmrxFXgJ_!!6000000001053-0-tps-4096-2541.jpg",
        },
      ],
      // -集体婚礼
      GroupWedding: [
        {
          name: "510滨江园区集体婚礼回娘家",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN016mP0sT1jSZdvnFpRC_!!6000000004547-0-tps-1200-675.jpg",
          image:
            "https://gw.alicdn.com/imgextra/i4/O1CN01vTOf4d1HaXHijtd8Q_!!6000000000774-0-tps-1200-675.jpg",
        },
        {
          name: "135296481",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01PaBSLU1lwv7ASK9kg_!!6000000004884-0-tps-2893-1929.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN010KMF8Z1G61HP8uHWX_!!6000000000572-0-tps-2893-1929.jpg",
        },
        {
          name: "135298009",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01hkNTs81OH1j4NKa3M_!!6000000001679-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN011ijFvP1gRFeMr7rZS_!!6000000004138-0-tps-2048-1365.jpg",
        },
        {
          name: "135312680",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01JmvkI71W0YPGS6cab_!!6000000002726-0-tps-2819-1879.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01WX4XE01GbAD3fjcbO_!!6000000000640-0-tps-2819-1879.jpg",
        },
        {
          name: "135315690",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01zIQjIA255bJxy8LWG_!!6000000007475-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01ZGdjrB1wkWACSjbSG_!!6000000006346-0-tps-2048-1365.jpg",
        },
        {
          name: "135316288",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN0186IWKL210KIcJ7AI1_!!6000000006922-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01EdRbz71zJRbH7cHS3_!!6000000006693-0-tps-2048-1365.jpg",
        },
        {
          name: "135318367",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN014ZByin1py4Zi4i8LE_!!6000000005428-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01o0dDPl1iCFunGP2hS_!!6000000004376-0-tps-3000-2000.jpg",
        },
        {
          name: "135321525",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01exiQNB1tqWVeJzBH5_!!6000000005953-0-tps-2970-1980.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN0143YQF91QMBPnU9HiV_!!6000000001961-0-tps-2970-1980.jpg",
        },
        {
          name: "135331027",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN010S0JEp28s66kJxriW_!!6000000007987-0-tps-2853-1902.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01nt9cEa1gezX7Rqq2z_!!6000000004168-0-tps-2853-1902.jpg",
        },
        {
          name: "135341809",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01hYYq9D1Z8HwPy2u0s_!!6000000003149-0-tps-2805-1870.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01azRu1u1WdwT8TYBzu_!!6000000002812-0-tps-2805-1870.jpg",
        },
        {
          name: "135351920",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01mE9lP81u1yPZv8KQZ_!!6000000005978-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01gLFyCJ1rvRX7fUrqC_!!6000000005693-0-tps-2048-1365.jpg",
        },
        {
          name: "135392841",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01sqfWZt1SeACDsoIbo_!!6000000002271-0-tps-2048-1362.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01CINgXi1NGHSmxUepi_!!6000000001542-0-tps-2048-1362.jpg",
        },
        {
          name: "1036949320210510160930203770212988491ANT3621",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01omSxFK1ZL721uJli7_!!6000000003177-0-tps-3054-2037.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01f5HzN71xvL8bYQd1V_!!6000000006505-0-tps-3054-2037.jpg",
        },
        {
          name: "1036949320210510161408214901212982492J59A9875",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN014CeaLn1XNk4ZRUvIE_!!6000000002912-0-tps-3200-2133.jpg",
          image: "",
        },
        {
          name: "阿里日7",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01uKsRRK1kqDhvfr1bp_!!6000000004734-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01TrdXqx21ymZwevG0g_!!6000000007054-0-tps-3200-2133.jpg",
        },
        {
          name: "DAMI3329",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01gsXqoO1aeB8VnVTlX_!!6000000003354-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01QTcIiV1vOhgRCMY6g_!!6000000006163-0-tps-1200-800.jpg",
        },
        {
          name: "DAMI3854",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01EASRFo1zwNGPimdXD_!!6000000006778-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01Sodg6h1sSsRk5A1pz_!!6000000005766-0-tps-3000-2000.jpg",
        },
        {
          name: "HUI_9552",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01tLZ0YX1pqHqJBWANj_!!6000000005411-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01UKz0RO1aiIhG81DjI_!!6000000003363-0-tps-3000-2000.jpg",
        },
        {
          name: "img_9164.jpg-135370740",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01ASmdK21HJ8EiWjL4N_!!6000000000736-0-tps-1899-1266.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01creASK1zjYAnazOrO_!!6000000006750-0-tps-1899-1266.jpg",
        },
        {
          name: "img_9245.jpg-135374747",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN018ao2Tr1Juo2q9IpdR_!!6000000001089-0-tps-1965-1310.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01ckReUJ1RhztgcRObr_!!6000000002144-0-tps-1965-1310.jpg",
        },
        {
          name: "ROC_2727",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01SmiS231RXSmgg52PO_!!6000000002121-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01a1AvBp1ql4x8eGrNU_!!6000000005535-0-tps-3000-2000.jpg",
        },
        {
          name: "ROC_2753",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01Vnonm01mo33dWTH4U_!!6000000005000-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN017PcMhC1mtXoRuJWVf_!!6000000005012-0-tps-3000-2000.jpg",
        },
        {
          name: "TONY0743",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01BJMIXG26RPnnRCQcC_!!6000000007658-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01HeYA6c1J9dFg7OJAa_!!6000000000986-0-tps-2048-1365.jpg",
        },
        {
          name: "TONY1099",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01eyky7b1ycqlOj6gi5_!!6000000006600-0-tps-2030-1353.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01JN06OQ1ZcW5DWp6kG_!!6000000003215-0-tps-2030-1353.jpg",
        },
        {
          name: "XMH9768",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01LSm2uT1aw2a1EE2W5_!!6000000003393-0-tps-2994-1996.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01dWjD4w1CnkdKxKNsf_!!6000000000126-0-tps-2994-1996.jpg",
        },
        {
          name: "XY008353",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01giA5AM1hNsLTW3Km9_!!6000000004266-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN011tQvPP1FHBJlaQS6U_!!6000000000461-0-tps-3000-2000.jpg",
        },
      ],
      // -活色生香
      Events: [
        {
          name: "0Q8A4700",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01cnh6Hw1m12gCttRiO_!!6000000004893-0-tps-2918-1945.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01v7YEl01QKoE2vdqnd_!!6000000001958-0-tps-2918-1945.jpg",
        },
        {
          name: "0S4A8923",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01KtO4Yn25VFVHLLFKQ_!!6000000007531-0-tps-4464-2976.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01HNkpOj2054n4rOAqE_!!6000000006797-0-tps-4464-2976.jpg",
        },
        {
          name: "0V7A5892",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01GbWUj921VvcuXf83d_!!6000000006991-0-tps-3111-2074.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01UNLiP31k4aVvJVBMX_!!6000000004630-0-tps-3111-2074.jpg",
        },
        {
          name: "1Y3A9170",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01SmoILs1JfgyULgciK_!!6000000001056-0-tps-3008-2006.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01c8ECQk1HFvScsoeI5_!!6000000000729-0-tps-3008-2006.jpg",
        },
        {
          name: "1Y3A9420",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01WSbgZH1QsF8lNTWLR_!!6000000002031-0-tps-3097-2065.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01b6H4CJ1RgchvrvuET_!!6000000002141-0-tps-3097-2065.jpg",
        },
        {
          name: "2Q9A6393",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01oLNwub1aoiFC01exd_!!6000000003377-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01oBFruB1hmbjZbP1cI_!!6000000004320-0-tps-2048-1365.jpg",
        },
        {
          name: "5b41e7ec3682a756751129ac415e8121",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01sI0Usi1O4CdVfEzoO_!!6000000001651-0-tps-3840-2560.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01y5g54x1O2pRl98jNN_!!6000000001648-0-tps-3840-2560.jpg",
        },
        {
          name: "5F0A4332",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01VtF6fL25ONYsUS1nQ_!!6000000007516-0-tps-3199-2133.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01BXGIuy1FAJNSvZ0Fm_!!6000000000446-0-tps-3199-2133.jpg",
        },
        {
          name: "9U9A9595",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01PFYrHp1JlBjEwvOIa_!!6000000001068-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01ovYs0W1cadIn0lswh_!!6000000003617-0-tps-3000-2000.jpg",
        },
        {
          name: "012A6856_w4442",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN014HL9oR1NaQtMfXYDH_!!6000000001586-0-tps-2793-1862.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01PJ02X41Dt4r8Wlevp_!!6000000000273-0-tps-2793-1862.jpg",
        },
        {
          name: "26",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN015yUkqC1V4O6gpfsqA_!!6000000002599-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN014T8FZZ2129snBNA3G_!!6000000006926-0-tps-3000-2000.jpg",
        },
        {
          name: "046A0A66-EFF5-45B2-8F7A-DB07385CF01F-29671-00000B3FAE4DDDD7",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01RrkvtD1MjlLPpcEpP_!!6000000001471-0-tps-3199-2133.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01BOqj4d1yFUZ08BqcI_!!6000000006549-0-tps-3199-2133.jpg",
        },
        {
          name: "64B0E9D9-0FC8-405A-AE42-141DA31BDE9B-29671-00000B323BA86F8F",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01EY4pnf1nHMPGE6idY_!!6000000005064-0-tps-3046-2031.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01IZtl5o1mS437ljFek_!!6000000004952-0-tps-3046-2031.jpg",
        },
        {
          name: "510西溪园区AE会场1",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01Ixx3fj1JuLeQUi81l_!!6000000001088-0-tps-1599-899.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01uZ8HTw1vPA57XDjeE_!!6000000006164-0-tps-1599-899.jpg",
        },
        {
          name: "1111",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01jXLOL41lYBj7yNdOn_!!6000000004830-0-tps-3360-1890.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01ls6Tvv1tiHNavXe0o_!!6000000005935-0-tps-3360-1890.jpg",
        },
        {
          name: "1608b704f277e6b2be1812f0ba3ee3ed",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01UX1zGQ1ji96opOQSQ_!!6000000004581-0-tps-5472-3078.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01hFfMyz1Iz68s8m3NJ_!!6000000000963-0-tps-5472-3078.jpg",
        },
        {
          name: "8044E25B-BF06-4F67-A0E6-64D149F854E8-29118-00000B0BCBF522B9",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01XYM28T1OKgtW7tYuw_!!6000000001687-0-tps-3200-2133.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01PTmxyK1OIrJKmUFG1_!!6000000001683-0-tps-3200-2133.jpg",
        },
        {
          name: "125332040",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN014tbVcc1pepwJ6GPN1_!!6000000005386-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN012wc3tV1SJ5yjENXZD_!!6000000002225-0-tps-1200-800.jpg",
        },
        {
          name: "125366420",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN014CHBbZ1sXunrFhJ0J_!!6000000005777-0-tps-2004-1336.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01JiHEqZ1HuEJU2CROn_!!6000000000817-0-tps-2004-1336.jpg",
        },
        {
          name: "141995881",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01ueECY61l7AMMj1FUk_!!6000000004771-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01LIwukQ1j6ad73O8qt_!!6000000004499-0-tps-2048-1365.jpg",
        },
        {
          name: "144041971副本",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01m5b4bo1M1KvQ5ZjwN_!!6000000001374-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01QZGISo1fUcx2boukh_!!6000000004010-0-tps-2048-1365.jpg",
        },
        {
          name: "147319261",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01k7SaBF1P9Wr6qEMbC_!!6000000001798-0-tps-1757-1171.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01m316mh21qXRzWbBnr_!!6000000007036-0-tps-1757-1171.jpg",
        },
        {
          name: "151623277",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN018NebLu1VyGQPoTq8K_!!6000000002721-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01466NMd1aFRkQXIbc6_!!6000000003300-0-tps-3000-2000.jpg",
        },
        {
          name: "171789578",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01sqZr7U1adGLMtbdJY_!!6000000003352-0-tps-2048-1361.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01FQl8UX1ZuNWnNoxS3_!!6000000003254-0-tps-2048-1361.jpg",
        },
        {
          name: "186296554",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01jProeX1vZhBrwPWrT_!!6000000006187-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01pzjdm51zza2J25gHZ_!!6000000006785-0-tps-2048-1365.jpg",
        },
        {
          name: "186333360",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01iZmBxp1jRCSETrjqd_!!6000000004544-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01kCh4wy29iJGCfK0oQ_!!6000000008101-0-tps-2048-1365.jpg",
        },
        {
          name: "代码大赛过程中-广州",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01Rd7App1efmzSobZ2K_!!6000000003899-0-tps-1992-1328.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01UYDmW31DsA47e9ugc_!!6000000000271-0-tps-1992-1328.jpg",
        },
        {
          name: "加速器团建",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01gDp8mM1wcH2JhwNEf_!!6000000006328-0-tps-2040-1359.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01hJLU5t1FI66v1uXZW_!!6000000000463-0-tps-2040-1359.jpg",
        },
        {
          name: "篮球队",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01XrlIPq1ZhYRKhNSfy_!!6000000003226-0-tps-4032-2726.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01SusYdo1eZNRboiE8v_!!6000000003885-0-tps-4032-2726.jpg",
        },
        {
          name: "奇点杯创新大赛2",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01r3k33r1vm3sr0ER8e_!!6000000006214-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01EMXauP1JKcl42YCV2_!!6000000001010-0-tps-2048-1365.jpg",
        },
        {
          name: "奇点杯创新大赛3",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01hYULGi25JnbJ6XXH5_!!6000000007506-0-tps-2048-1361.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01PWGXcj1Zn3BwyD09Y_!!6000000003238-0-tps-2048-1361.jpg",
        },
        {
          name: "双11KO3",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01u5l6Ak1iCiJGlLRa4_!!6000000004377-0-tps-2995-1997.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01qsyE7q1jAFndh2WpE_!!6000000004507-0-tps-2995-1997.jpg",
        },
        {
          name: "双11KO7",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01SFivhY1RHtJwse7QQ_!!6000000002087-0-tps-2836-1891.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01BFiVnj26Cl84ADXsd_!!6000000007626-0-tps-2836-1891.jpg",
        },
        {
          name: "天猫国际全员大会3",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01KcKHBF1qTDVeH6wxc_!!6000000005496-0-tps-1989-1326.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01ZuTJ101QGEGU9kmj1_!!6000000001948-0-tps-1989-1326.jpg",
        },
        {
          name: "AF6R6788",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01sd1PQZ1T4jAUSn7Pq_!!6000000002329-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN013UpKFp1mQgrUAjYtP_!!6000000004949-0-tps-3000-2000.jpg",
        },
        {
          name: "ANT_7092",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01mwxYRi1FnF2WRSV2n_!!6000000000531-0-tps-5398-3288.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01wauqTW1EvCJ6ToJcs_!!6000000000413-0-tps-5398-3288.jpg",
        },
        {
          name: "ANT1278",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01XfY6IH1SVSfk5gXjh_!!6000000002252-0-tps-3054-1718.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01OFrEmq1fdKTlZGsYm_!!6000000004029-0-tps-3054-1718.jpg",
        },
        {
          name: "ANT2357",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN013X7gRW1EgXdCxNNuM_!!6000000000381-0-tps-2793-1862.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01dQF0rs1FocELAvwbs_!!6000000000534-0-tps-2793-1862.jpg",
        },
        {
          name: "ANT3244_w0369",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01Ljs3691qAtfPuVF49_!!6000000005456-0-tps-2499-1667.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01kvnytY29wxvx8esRY_!!6000000008133-0-tps-2499-1667.jpg",
        },
        {
          name: "ANT7617",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01bCMEeE24UxdMiYpHa_!!6000000007395-0-tps-2798-1865.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01eJCVTV1omKoBHG5ZS_!!6000000005267-0-tps-2798-1865.jpg",
        },
        {
          name: "B4330F20-D7DA-4CD3-AB1C-2DD3B6EB0AA2-29671-00000B41B248E2AD",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN016XZsRy1qOdY1s9Nxt_!!6000000005486-0-tps-3063-2042.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01gWaSzW1nct1SsRFCp_!!6000000005111-0-tps-3063-2042.jpg",
        },
        {
          name: "B区乔迁仪式",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01qbx77B1gTXdFHPvAX_!!6000000004143-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01xuarbL1H0oOME6fj6_!!6000000000696-0-tps-1200-800.jpg",
        },
        {
          name: "DSC05521",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01YjKTxt21zEyTRF3uZ_!!6000000007055-0-tps-5879-3308.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01r2DXTy1l5nAhESpiH_!!6000000004768-0-tps-5879-3308.jpg",
        },
        {
          name: "DWWW5216",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01j54wSW1NN9P3giIKD_!!6000000001557-0-tps-2787-1858.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01h2H47Z223MXPxe8Nv_!!6000000007064-0-tps-2787-1858.jpg",
        },
        {
          name: "E8E95DF2-37B9-472E-84F7-C88F615E0909-29913-00000B53D2C81C29",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01uXMBgM1mPm4CRuuIX_!!6000000004947-2-tps-1600-1067.png",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01yoLZXk1TSXlLivt6h_!!6000000002381-2-tps-1600-1067.png",
        },
        {
          name: "F8E2E0E6-0D7D-400A-A6AB-332CBFAFF5FC-29671-00000B3010D00007",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01tFQD1L1TybUOH0Odx_!!6000000002451-0-tps-1600-1067.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN019Q1v7O1L3nRAumspi_!!6000000001244-0-tps-1600-1067.jpg",
        },
        {
          name: "IMG_1045",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN014tWO181EATu6jFiCY_!!6000000000311-0-tps-3840-2561.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN013BMB4w1yssd0JCZ4w_!!6000000006635-0-tps-3840-2561.jpg",
        },
        {
          name: "IMG_1052",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01POHwg825G8QogNwuY_!!6000000007498-0-tps-3840-2560.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01tfiTrb24GIxgXFKQT_!!6000000007363-0-tps-3840-2560.jpg",
        },
        {
          name: "IMG_4768",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01yJ4jfM1dETkzj8FQS_!!6000000003704-0-tps-5472-3648.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01ewbVrG1HqZ9BUcIzG_!!6000000000809-0-tps-5472-3648.jpg",
        },
        {
          name: "IMG_4830",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01USHByw1KtiilwbrKJ_!!6000000001222-0-tps-2400-1600.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01zYpvbM20PEDWeJQoC_!!6000000006841-0-tps-2400-1600.jpg",
        },
        {
          name: "IMG_4833",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01jpNmlg1EL10yVFMkY_!!6000000000334-0-tps-3200-2133.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01IlNTqE1y3aGkj5STo_!!6000000006523-0-tps-3200-2133.jpg",
        },
        {
          name: "lADPDgtYylwkmJ7NAyDNBLA_1200_800",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01BqFX2M1le8sV4aDOW_!!6000000004843-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01yFqe881x8n9VnG9Ac_!!6000000006399-0-tps-1200-800.jpg",
        },
        {
          name: "MDL0374",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01FiXdIV1fhS2lptXcj_!!6000000004038-0-tps-3185-2124.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01bIPyas1ehABENvoRo_!!6000000003902-0-tps-3185-2124.jpg",
        },
        {
          name: "WQN04559",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01ItpXam1JYMdZ9rhTZ_!!6000000001040-0-tps-2055-1370.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01Ews7YD1IVmn58XTwz_!!6000000000899-0-tps-2055-1370.jpg",
        },
        {
          name: "WUXI3384",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01R3sXAF260qpZve4Wv_!!6000000007600-0-tps-1831-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01mkhaul1gVNDIjyBo4_!!6000000004147-0-tps-1831-1365.jpg",
        },
        {
          name: "WUXI4625",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01ijowZf1ggMimolDHa_!!6000000004171-0-tps-5184-3456.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01X7ZHTi1NWJKIvYzSY_!!6000000001577-0-tps-5184-3456.jpg",
        },
        {
          name: "WUXI7031",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01DrVLZQ1LPK3L88zFP_!!6000000001291-0-tps-3200-2133.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01CiooDA1R54ED5P9Eg_!!6000000002059-0-tps-3200-2133.jpg",
        },
        {
          name: "YES WE CAN篮球赛4",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01X7DkzX2ADuaP598q3_!!6000000008170-0-tps-6628-4421.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN013t2rlX1hIpzUto0bi_!!6000000004255-0-tps-6628-4421.jpg",
        },
        {
          name: "YJ3_6490",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01xAZzVo1ESnkZtG82B_!!6000000000351-0-tps-6144-4080.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01PumlCh1ZM1pARSeYV_!!6000000003179-0-tps-6144-4080.jpg",
        },
        {
          name: "YYDS8946_w6085",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01qPntLl1XMpHVx4fum_!!6000000002910-0-tps-2628-1752.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01ig2Lc91vsvpBDn7sD_!!6000000006229-0-tps-2628-1752.jpg",
        },
      ],
      // -拔河
      TugofWar: [
        {
          name: "7F3A7902",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01qzl78i1x7PxzVqzWH_!!6000000006396-0-tps-6720-4480.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01WduQNh29a48C0HWUr_!!6000000008083-0-tps-6720-4480.jpg",
        },
        {
          name: "12172951",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01p0TZpA1oqulsRzELz_!!6000000005277-0-tps-2560-1706.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN019KN6jA29TCBp2LUnQ_!!6000000008068-0-tps-2560-1706.jpg",
        },
        {
          name: "177931684",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01FdeVu21qGqoVTU3vX_!!6000000005469-0-tps-1962-1306.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01t7hHFl1o81xGocZu2_!!6000000005179-0-tps-1962-1306.jpg",
        },
        {
          name: "178104036",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01iQET421f9YjbidcxZ_!!6000000003964-0-tps-2048-1363.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01K25MKQ23khdNlEi9o_!!6000000007294-0-tps-2048-1363.jpg",
        },
        {
          name: "179469819",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01clPfZ21rPqCfAm4Wq_!!6000000005624-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN012mFFQw1DEJbjNmd2Q_!!6000000000184-0-tps-3000-2000.jpg",
        },
        {
          name: "179473810",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01TlKJMK1nqcu8eKFKC_!!6000000005141-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01aLOcgI1DxCQGe2pD1_!!6000000000282-0-tps-3000-2000.jpg",
        },
        {
          name: "179479893",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01ZYL2Gg29pdb6sDcPZ_!!6000000008117-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN014uAvjY1H36NGRSRPO_!!6000000000701-0-tps-2048-1365.jpg",
        },
        {
          name: "179480108",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01yBj5BL27ZUOwFyX3f_!!6000000007811-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01Y46szQ1aQteKNKwmK_!!6000000003325-0-tps-1200-800.jpg",
        },
        {
          name: "179491601",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN010stet71PcNoJXjCC6_!!6000000001861-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01FbunNm1FaPxA1mkWp_!!6000000000503-0-tps-3000-2000.jpg",
        },
        {
          name: "179510127",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01Qpyg6m1VtgSh489I8_!!6000000002711-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01SN4gf91HTfLNZHlNv_!!6000000000759-0-tps-1200-800.jpg",
        },
        {
          name: "179527516",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01VwO09f1JNpWoL4qdm_!!6000000001017-0-tps-2039-1359.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01iide4E1m3n3aw69ra_!!6000000004899-0-tps-2039-1359.jpg",
        },

        {
          name: "179608086",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01qKBu6h1eUnU8xpdqu_!!6000000003875-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01FLGYpf1ijgpGFeGCz_!!6000000004449-0-tps-2048-1365.jpg",
        },
        {
          name: "843750569951207",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01uoP0Hk26BqKoCQ3dt_!!6000000007624-0-tps-2413-1608.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01G0yUbN1dRIqZ1YnPt_!!6000000003732-0-tps-2413-1608.jpg",
        },
        {
          name: "78913734202109101301283170942555841150014287",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01JNgbrY1ByufjZIRJL_!!6000000000015-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01UJXd0i1GepNmSgDOn_!!6000000000648-0-tps-3000-2000.jpg",
        },
        {
          name: "拔河-cpo1",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01KfSS3r1FIYVRYhIa6_!!6000000000464-0-tps-3700-2467.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01N5gaeL29djIXotLKv_!!6000000008091-0-tps-3700-2467.jpg",
        },
        {
          name: "拔河",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01tqc3tM1E7jWwcKasr_!!6000000000305-0-tps-1620-1080.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01LeY6Jt28mbLzcBsMT_!!6000000007975-0-tps-1620-1080.jpg",
        },
        {
          name: "拔河1",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN013JQnmM1dfxWV9lD6f_!!6000000003764-2-tps-3000-2000.png",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01buCUQG1RMvg0QWsQS_!!6000000002098-2-tps-3000-2000.png",
        },
        {
          name: "最全合影",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01bK2QRM1mQESp2046e_!!6000000004948-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01aMbEO11KmqmSz71Zp_!!6000000001207-0-tps-2048-1365.jpg",
        },
        {
          name: "ANT8827_w8576",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN017kn5g71lCCiaaR1jS_!!6000000004782-0-tps-3200-2134.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01i3fez21ntpfyF24Ld_!!6000000005148-0-tps-3200-2134.jpg",
        },

        {
          name: "DWWW0252",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01tQqOfE1pByzN4pZLO_!!6000000005323-0-tps-2515-1677.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN011lr3GB1YVMH8ba1t0_!!6000000003064-0-tps-2515-1677.jpg",
        },
        {
          name: "DWWW0752 (1)",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01ow3BND1DtzeGJPvAA_!!6000000000275-0-tps-2901-1934.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01vU7lmC27lr5rng8pf_!!6000000007838-0-tps-2901-1934.jpg",
        },
        {
          name: "DWWW1123",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01q2nZDk1m652QlKhSG_!!6000000004904-0-tps-3126-1589.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01sZJcH11RHtJmMTi8i_!!6000000002087-0-tps-3126-1589.jpg",
        },
        {
          name: "DWWW1162",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01qgTtID1VZX2RXUvYf_!!6000000002667-0-tps-3148-2099.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01P7Z8D51XdJXYQZVZN_!!6000000002946-0-tps-3148-2099.jpg",
        },
        {
          name: "DWWW9146",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01IYXOcS1hKfZVE4QPW_!!6000000004259-0-tps-3002-2002.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01gp7b4z21QtGnG33in_!!6000000006980-0-tps-3002-2002.jpg",
        },
        {
          name: "DWWW9917",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01VFZmUI1fKYEo01nuU_!!6000000003988-0-tps-2435-1624.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01eoRlJK1hm9L8yAvS4_!!6000000004319-0-tps-2435-1624.jpg",
        },
        {
          name: "https___voss.vphotos.cn_m_aa0697_2diz7vg_2ea4_4iqmNOw8_vbox7144_IVTZ3472_193642_small.JPG_vbox7144_IVTZ3472_193642 (1)",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01XShDba20fiTSmRv7S_!!6000000006877-0-tps-3796-2531.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01LpY0RT1plhsn5yxvL_!!6000000005401-0-tps-3796-2531.jpg",
        },
        {
          name: "https___voss.vphotos.cn_m_de67b0_2dixvol_2kq2_4iqmLYmB_vbox8146_012A6096_094103_small.JPG_vbox8146_012A6096_094103",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN0119u1P01vlbU4zCrkZ_!!6000000006213-0-tps-1080-720.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01HWvoS71EATuJ9iCjC_!!6000000000311-0-tps-1080-720.jpg",
        },
        {
          name: "vbox7144_IVTZ1169_104044",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01vau98f1bz4p7RVg4m_!!6000000003535-0-tps-1920-1280.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01R3JXX01eowuXWJZWR_!!6000000003919-0-tps-1920-1280.jpg",
        },
        {
          name: "vbox7144_IVTZ2234_125629",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN012659DD21sN2AnHbWc_!!6000000007040-0-tps-1920-1280.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01R9Uhua1TJNq9128Kp_!!6000000002361-0-tps-1920-1280.jpg",
        },
        {
          name: "vbox7144_IVTZ2666_155731",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01c3hGnv259GUWfSULe_!!6000000007483-0-tps-1920-1280.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01BcZPvI1I1YeSOORrm_!!6000000000833-0-tps-1920-1280.jpg",
        },
        {
          name: "vbox7144_IVTZ3562_194318",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01GrGfIU1NsIKwTcsK6_!!6000000001625-0-tps-1920-1280.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01yxlSa01Y5FhTVtl6W_!!6000000003007-0-tps-1920-1280.jpg",
        },
        {
          name: "vbox8146_012A6705_102310",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01T70mgi1zv04krp4PZ_!!6000000006775-0-tps-1920-1280.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01vIIosG1adijz2fB7M_!!6000000003353-0-tps-1920-1280.jpg",
        },
        {
          name: "vbox8146_012A8351_122413",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01LaNK0i1q0MYc8MNM7_!!6000000005433-0-tps-1920-1280.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01KXWiXM1kHs02O9PMR_!!6000000004659-0-tps-1920-1280.jpg",
        },
        {
          name: "vbox8146_012A9492_160219",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01pqYsDL1okxcYwTaiU_!!6000000005264-0-tps-1920-1280.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01WTMr831cK92eoQUHE_!!6000000003581-0-tps-1920-1280.jpg",
        },
        // {
        //   name: "vbox8146_012A9958_185321 (1)",
        //   cover:
        //     "https://img.alicdn.com/imgextra/i4/O1CN01h5D7XT1GrC4iglOw0_!!6000000000675-0-tps-1920-1280.jpg",
        //   image:
        //     "https://img.alicdn.com/imgextra/i2/O1CN01jb6Brq1LzxjpvgFSx_!!6000000001371-0-tps-1920-1280.jpg",
        // },
      ],
      // -音乐季
      MusicSeasonarr: [
        {
          name: "001_1038",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01M53HX01FcFXCocrBC_!!6000000000507-0-tps-4000-2661.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01VltfTU1XDfMSrGMXF_!!6000000002890-0-tps-4000-2661.jpg",
        },
        {
          name: "1R1A0041",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01g82Xbt1koqW51Js6J_!!6000000004731-0-tps-4000-2666.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01xzHIMa1t6GVWSBDQy_!!6000000005852-0-tps-4000-2666.jpg",
        },
        {
          name: "637EF06B-20E3-4DBD-A1CA-60E8DB28AC7D-29671-00000B37EC11A6C9",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01URVIS91O4CdX2Ee4m_!!6000000001651-0-tps-3199-2133.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01PKB6hP1x4fac7inGv_!!6000000006390-0-tps-3199-2133.jpg",
        },
        {
          name: "161069813",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN015G4F8V1d79QCk46kM_!!6000000003688-0-tps-3008-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01nAZE3O1D8MSMbmEqC_!!6000000000171-0-tps-3008-2000.jpg",
        },
        {
          name: "161070029",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01x7UM1p1q36vsjBEKT_!!6000000005439-0-tps-3008-1889.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01hrIBUG1cnummR7XHa_!!6000000003646-0-tps-3008-1889.jpg",
        },
        {
          name: "161071078",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN0149OEJV1wGI1URN9Z3_!!6000000006280-0-tps-3008-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01XIhZKR1xqImbIisjz_!!6000000006494-0-tps-3008-2000.jpg",
        },
        {
          name: "161136188",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01oiGv5n1YkTLVMPDvv_!!6000000003097-0-tps-3008-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01RRlSFq27nEHa86G2i_!!6000000007841-0-tps-3008-2000.jpg",
        },
        {
          name: "172644470",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01GOfLU41MxxcerdYxG_!!6000000001502-0-tps-2022-1348.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01mmYBnq1F4ocj5nL7d_!!6000000000434-0-tps-2022-1348.jpg",
        },
        {
          name: "172649166",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01JebvoN1xRZOUPqBPc_!!6000000006440-0-tps-2950-1967.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01Dpd8MN1ezU1JDhY3W_!!6000000003942-0-tps-2950-1967.jpg",
        },
        {
          name: "172662900",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN014yaMnc1cx4hvdjV85_!!6000000003666-0-tps-2958-1972.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN011LR2oI1YeyasF5L6U_!!6000000003085-0-tps-2958-1972.jpg",
        },
        {
          name: "183410518",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01Xq3DXr1f1Jbd9pT7I_!!6000000003946-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01JEG1BJ1vAxnpH7qL3_!!6000000006133-0-tps-1200-800.jpg",
        },
        {
          name: "183411513",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01oDFhnX1DzUOzbvTn3_!!6000000000287-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01pThz2e1CucZpt38E4_!!6000000000141-0-tps-1200-800.jpg",
        },
        {
          name: "183411560",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01yk74701tUztTJhsSU_!!6000000005906-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01vN4LGF1wvVfg09x1c_!!6000000006370-0-tps-2048-1365.jpg",
        },
        {
          name: "111102164296529",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01jLQjfs1vW21LxTOvR_!!6000000006179-0-tps-3976-2651.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01hr5y1y1EJdpLZJXzN_!!6000000000331-0-tps-3976-2651.jpg",
        },
        {
          name: "945464790907563",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01yP4QnI1ZDKIdTiYOw_!!6000000003160-0-tps-5692-3856.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01ec5c8x1O2pRguceRW_!!6000000001648-0-tps-5692-3856.jpg",
        },
        {
          name: "不插电音乐会4",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01r5oEiU1Q8RX6jHOyl_!!6000000001931-0-tps-6720-4480.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01J0FHbT28h6bNiQ5y4_!!6000000007963-0-tps-6720-4480.jpg",
        },
        {
          name: "调交响音乐会3",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01yjmQNO1VJVAzBuJEi_!!6000000002632-0-tps-4171-2622.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01l1S0EB1EP8a3PMY7e_!!6000000000343-0-tps-4171-2622.jpg",
        },
        {
          name: "调音乐会2",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01J1Z8Pf1WrDx4CM9lG_!!6000000002841-0-tps-4500-2784.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01Ya3gOf1aYDzD5ZO7v_!!6000000003341-0-tps-4500-2784.jpg",
        },
        {
          name: "无价之滨假装在outing",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01g7irjr1tYf3vnXopZ_!!6000000005914-0-tps-1200-675.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01JSh45F1d9ROs47X9O_!!6000000003693-0-tps-1200-675.jpg",
        },
        {
          name: "无价之滨夏日歌会滨江1",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN017Ouf4e1vV7EAXffVx_!!6000000006177-0-tps-1200-675.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01PWpIYM1wYbrjWiamY_!!6000000006320-0-tps-1200-675.jpg",
        },
        {
          name: "五周年4",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN017y95Ng1OdvWpchDlz_!!6000000001729-0-tps-1200-797.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01VLQreS2AKmWowpsrV_!!6000000008185-0-tps-1200-797.jpg",
        },
        // {
        //   name: "五周年6",
        //   cover:
        //     "https://img.alicdn.com/imgextra/i1/O1CN01zLn50R1CGJiv20sdl_!!6000000000053-0-tps-1200-800.jpg",
        //   image:
        //     "https://img.alicdn.com/imgextra/i3/O1CN01hKi2z71syTm93f9Nb_!!6000000005835-0-tps-1200-800.jpg",
        // },
        {
          name: "B806BB18-C17F-47E4-A317-2CD6734A2D09-29913-00000B53DC1D364A",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01Ck0rAu1xzShgwUxz1_!!6000000006514-2-tps-1600-1067.png",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01TMwkkH1tl1l6ivhAa_!!6000000005941-2-tps-1600-1067.png",
        },
        {
          name: "https___voss.vphotos.cn_m_270a15_2bmd1d5_2ea4_4innrkPe_vbox9124_531A8714_194518_small.JPG_vbox9124_531A8714_194518",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN010ZMWpu28OKMhLZcdG_!!6000000007922-0-tps-1080-720.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01pERBFU1PuFFtXXWDB_!!6000000001900-0-tps-1080-720.jpg",
        },
        {
          name: "https___voss.vphotos.cn_m_f7ae51_2bmfjdk_2ea4_4innF1v2_vbox9124_531A8487_190909_small.JPG_vbox9124_531A8487_190909",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01JztWVK1esc58WCpkD_!!6000000003927-0-tps-3840-2560.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01CwHesk1pSTFLNPTVI_!!6000000005359-0-tps-3840-2560.jpg",
        },
        {
          name: "IMG_1472",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01ElVNG51ZBUiNgfp82_!!6000000003156-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01T43kF01R9BnLHtlNd_!!6000000002068-0-tps-3000-2000.jpg",
        },
        {
          name: "SUN07516",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01j8rNfE1hfjnFOQiov_!!6000000004305-0-tps-5632-3755.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN017zg14C1TTux0eeNBc_!!6000000002384-0-tps-5632-3755.jpg",
        },
      ],
      // -双11
      Double11arr: [
        {
          name: "1",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01zRTVke1HPXmLLIfxg_!!6000000000750-0-tps-2009-1339.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01grKMKl27Z208qNYtt_!!6000000007810-0-tps-2009-1339.jpg",
        },
        {
          name: "2",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01aSgNc41sInjVkwmKR_!!6000000005744-2-tps-2045-1363.png",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01OxyLEf1YZ1Rao6HxF_!!6000000003072-2-tps-2045-1363.png",
        },
        {
          name: "2BF3AA24-A6C0-46D5-B187-53EF06336F9D-961-000000A2407FCB11",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01chsZpK1iJ7r4O7WH0_!!6000000004391-0-tps-2038-1358.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01Db7pFm1wD5FhCIvKr_!!6000000006273-0-tps-2038-1358.jpg",
        },
        {
          name: "2C08BBB6-F1D8-44C6-9EEF-101A656F17A1-26542-00001AE0EF35ED6E",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01e9ysMa20Q90YtLEyl_!!6000000006843-0-tps-2974-1983.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01eHydyO1Pfaa0DgM41_!!6000000001868-0-tps-2974-1983.jpg",
        },
        {
          name: "3",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01AS8R341HEYH277xFM_!!6000000000726-0-tps-2035-1356.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01Zr3Wav1myaAYLyD95_!!6000000005023-0-tps-2035-1356.jpg",
        },
        {
          name: "9D875664-E88C-42FE-8195-6BD0637911A8-5612-000002CA2AA69356",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01knZBKF1TVI8ogt9Rn_!!6000000002387-0-tps-6000-4000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01opPOVe1FdAKQq2Kqp_!!6000000000509-0-tps-6000-4000.jpg",
        },
        {
          name: "52B48ADA-3914-4351-A7F2-8C9C71C877D6-961-000000A35E2434A4",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01xgQd5M1Kl1CMOHUyU_!!6000000001203-0-tps-1979-1319.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01zGy7VV1RcV8pghWqn_!!6000000002132-0-tps-1979-1319.jpg",
        },
        {
          name: "510西溪园区AE会场3",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01nVu1u81e2OvkqXMrq_!!6000000003813-0-tps-1070-602.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN014umFmn2A1XtOREWYF_!!6000000008143-0-tps-1070-602.jpg",
        },
        {
          name: "750EEBF9-87DB-4215-ABF8-EDBC1231C435-5612-000002CC93C41E04",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01O75yER1JXuF5MqCyH_!!6000000001039-0-tps-6000-4000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01LZ6M201GhZkzmz6sJ_!!6000000000654-0-tps-6000-4000.jpg",
        },
        {
          name: "23929AA5-383F-4560-A02B-16266E3702A3-6246-0000030FF517A1BA",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01FZ9XoZ1zhGC4A4KR1_!!6000000006745-0-tps-6000-4000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01Vpqwmc28MxAxJ0Rot_!!6000000007919-0-tps-6000-4000.jpg",
        },
        {
          name: "20211111双11海外同学001",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN012Kiat721QQsBkAYuO_!!6000000006979-0-tps-4032-2268.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01STDc5h1jaolqjRp0P_!!6000000004565-0-tps-4032-2268.jpg",
        },
        {
          name: "181581428",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01AEgS8P1NJ1qEmnwPJ_!!6000000001548-0-tps-2848-1899.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN017BpM6A1HjhCz53uWz_!!6000000000794-0-tps-2848-1899.jpg",
        },
        {
          name: "193447645",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01RlaxlW1NyhsndcBVw_!!6000000001639-0-tps-4096-2730.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01nylh351W2NzRHqSRe_!!6000000002730-0-tps-4096-2730.jpg",
        },
        {
          name: "198863247",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01vvUzMu1TYxJ7wdbo2_!!6000000002395-0-tps-2869-1721.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01wkULgQ1OM457m5GN2_!!6000000001690-0-tps-2869-1721.jpg",
        },
        {
          name: "198942359",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01m6maz11kSrVHhpBRN_!!6000000004683-0-tps-2866-1719.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN012XZfBL1MIjyLx3HXk_!!6000000001412-0-tps-2866-1719.jpg",
        },
        {
          name: "199124842",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN017QiwJa1svH0D9Ym8z_!!6000000005828-0-tps-3001-1811.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01GmXMFw1RdPw6CsGrJ_!!6000000002134-0-tps-3001-1811.jpg",
        },
        {
          name: "199729715",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01Wgm0281bHZC4z58gv_!!6000000003440-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN014XP6vU1oifdoTL2xT_!!6000000005259-0-tps-1200-800.jpg",
        },
        {
          name: "199747356",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01SsmDE21ovx7xT26K9_!!6000000005288-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01Tj7Cvg1v1LU51uZrk_!!6000000006112-0-tps-1200-800.jpg",
        },
        {
          name: "201365969",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01ciMZu71ebD20MALYa_!!6000000003889-0-tps-2019-1346.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01YdCABh1smZTQeVfrl_!!6000000005809-0-tps-2019-1346.jpg",
        },
        {
          name: "201520035",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01oVVA1S1jt8cBbK52h_!!6000000004605-0-tps-2804-1869.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01xvjrd51oGH5Hfa5Ca_!!6000000005197-0-tps-2804-1869.jpg",
        },
        {
          name: "201829898",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01qWaO29207MliWwrEa_!!6000000006802-0-tps-1100-825.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01pAHSCl1wFNERka1BT_!!6000000006278-0-tps-1100-825.jpg",
        },
        {
          name: "201896450",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01YW9NQt1QPOBdRtSR6_!!6000000001968-0-tps-4015-2676.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01QTsfc4211hUHuEsUi_!!6000000006925-0-tps-4015-2676.jpg",
        },
        {
          name: "201997508",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01nMIt0e1pepwPPgZTC_!!6000000005386-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01XUYS6v27chAh1QjgN_!!6000000007818-0-tps-1200-800.jpg",
        },
        {
          name: "202044348",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01DCrQuW20vkKvrmEQ1_!!6000000006912-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01ZyBWfH1WbC5bU7gsq_!!6000000002806-0-tps-3000-2000.jpg",
        },
        {
          name: "202068546",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN011vS8ZV1QHbSFnw7oK_!!6000000001951-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01HN0Oge22ev16gDTvP_!!6000000007146-0-tps-1200-800.jpg",
        },
        {
          name: "202076545",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01hTuKwD1Qo7Zpu5CHd_!!6000000002022-0-tps-4084-2722.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01kzqMxp1ilyo5nBYXK_!!6000000004454-0-tps-4084-2722.jpg",
        },
        {
          name: "美食节1",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01xtM9c71s6tR0VEYkG_!!6000000005718-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01ybYC9Q1LACyxmhNTL_!!6000000001258-0-tps-1200-800.jpg",
        },
        {
          name: "双11战袍2",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01w1OCr61wZWeqQZ7AL_!!6000000006322-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN014dHA9i1jNzg66Gpe8_!!6000000004537-0-tps-1200-800.jpg",
        },
        {
          name: "双11战袍秀",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01KsF0aF1awUyhX24SJ_!!6000000003394-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01yCGmT71DDrD6kiiRa_!!6000000000183-0-tps-2048-1365.jpg",
        },
        {
          name: "双11KO旱地龙舟赛1",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01k5zLdu1xwiKPkibxq_!!6000000006508-0-tps-1142-642.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01ErWRG11jsDowjwoJn_!!6000000004603-0-tps-1142-642.jpg",
        },
        {
          name: "ANT3676",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01wznWgB1IO03n6NAlM_!!6000000000882-0-tps-3200-2134.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01t6pxzO1wI7bp7wHcJ_!!6000000006284-0-tps-3200-2134.jpg",
        },
        // {
        //   name: "ANT3990",
        //   cover:
        //     "https://img.alicdn.com/imgextra/i3/O1CN01yBlPir1MdLncfBgAY_!!6000000001457-0-tps-3200-2134.jpg",
        //   image:
        //     "https://img.alicdn.com/imgextra/i2/O1CN01W2sSoL26WuYSujo8C_!!6000000007670-0-tps-3200-2134.jpg",
        // },
        {
          name: "ANT7294",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01cv0oYT1gN85ONbGZb_!!6000000004129-0-tps-3020-2014.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN017UpFV21xdTgwn0KiV_!!6000000006466-0-tps-3020-2014.jpg",
        },
        {
          name: "B5A56162-951C-43B1-A88F-B4F5D45943E0-26542-00001A1B1CE98E95.jpg",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01xms2x51WuQj8f41NK_!!6000000002848-0-tps-1170-778.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01a8Eks91Tz3sqigwcH_!!6000000002452-0-tps-1170-778.jpg",
        },
        {
          name: "BA7895F2-47BD-422D-B066-75CD19D60068-5612-000002BBF2508927",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01ukdQFz1UpjQnxIFqi_!!6000000002567-0-tps-6000-4000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01ulIIG61maJAsZsPR2_!!6000000004970-0-tps-6000-4000.jpg",
        },
        {
          name: "D160655C-BF43-4283-A522-963ECD14CEEC-26542-00001AE0BD3CACA3",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01edhiEd1Gh7MUUZorJ_!!6000000000653-0-tps-2916-1944.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01MD9l7D1RNO4cod7Tk_!!6000000002099-0-tps-2916-1944.jpg",
        },
        {
          name: "F6917024-CF2B-4090-B965-7016603075C1-961-000000A33139C0D0",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01j6kVMS1pwhOBKICHN_!!6000000005425-0-tps-2022-1347.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01wOKiqr1hpoVOlkjVk_!!6000000004327-0-tps-2022-1347.jpg",
        },
        {
          name: "SUN06365",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01Nvdsqq1hR57S5kRQe_!!6000000004273-0-tps-2007-1201.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01LdHlc51xbe6p7kZto_!!6000000006462-0-tps-2007-1201.jpg",
        },
        {
          name: "YXH4988",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01srV9OE1TADv6C9nTR_!!6000000002341-0-tps-2886-1924.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01e6QzUN26bwuclpNAX_!!6000000007681-0-tps-2886-1924.jpg",
        },
      ],
      // -社会责任
      SocialResponsibilityarr: [
        {
          name: "6.云南红河-县妇幼保健院医生为村医进行妇幼保健知识培训",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01r3bT0r1Udp8LWkVf2_!!6000000002541-0-tps-8256-5504.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN014fLfZZ1pG6YKR6JR4_!!6000000005332-0-tps-8256-5504.jpg",
        },
        {
          name: "7月，由中国人民银行金融消费权益保护局指导、中国金融教育发展基金会、蚂蚁集团联合发起“星海计划”金融消费者保护行动。一辆印有骗子各种甜蜜话术的防骗西瓜大篷车，出现在了吉",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01J7TyHJ1yrVREJdywM_!!6000000006632-0-tps-3000-2002.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01YKMHR31OTqoXrKU70_!!6000000001707-0-tps-3000-2002.jpg",
        },
        {
          name: "19FE97C5-6169-4B04-92D6-969FC1B40C67-375-00000112B639F218",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01OGmOGH1GGYOK7ORAk_!!6000000000595-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01Dftcfe1SBJFElEW5K_!!6000000002208-0-tps-1200-800.jpg",
        },
        {
          name: "44C38585-56F3-4086-95C5-935D640AFD9B",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01RHogw51DZNpIMFFEQ_!!6000000000230-2-tps-872-606.png",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01YIKS9m1sA6CpcQxq8_!!6000000005725-2-tps-872-606.png",
        },
        {
          name: "84D34DB2-837B-4ca2-B52F-FD39F2AF7D55",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01tHt5py1MYJRPNoTLM_!!6000000001446-2-tps-748-498.png",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01NXe4lS1Nu7vAZ8PbF_!!6000000001629-2-tps-748-498.png",
        },
        {
          name: "0604DC3F-4C4E-404e-AA64-F715D97D1B00",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01lAQnyc21O8tRONo0K_!!6000000006974-2-tps-872-605.png",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01HJHXsz1hFdDXWquyj_!!6000000004248-2-tps-872-605.png",
        },
        {
          name: "134812821",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01NGQngA1CPTe1NSQlA_!!6000000000073-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN019YXpHK22QijtTKIp4_!!6000000007115-0-tps-2048-1365.jpg",
        },
        {
          name: "134849055",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01nkNKft1Lnb2mkOAlw_!!6000000001344-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01LLjgSZ1ITUoYBTyg8_!!6000000000894-0-tps-2048-1365.jpg",
        },
        {
          name: "备选3",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01ftaFQh1NN9P6xIroF_!!6000000001557-0-tps-4032-3024.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01VuVLd21qefPTXkVSS_!!6000000005521-0-tps-4032-3024.jpg",
        },
        {
          name: "橙点公益之星合影",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01wvFpLC20M1RWQSFaC_!!6000000006834-0-tps-2048-1365.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01MejdKm1owruyPAyLl_!!6000000005290-0-tps-2048-1365.jpg",
        },
        {
          name: "橙点十佳项目合影",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01T4B2Sv1Lo3RFDmzrj_!!6000000001345-0-tps-2048-1361.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01eqPlSj1CWnyowmyAX_!!6000000000089-0-tps-2048-1361.jpg",
        },
        {
          name: "第四届委员合影",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN019SBCwz1vb4NYFq3Do_!!6000000006190-0-tps-2000-1333.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01xegVxD1Zy2hNtM6g3_!!6000000003262-0-tps-2000-1333.jpg",
        },
        {
          name: "公益义卖集市",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01qxTNr21Y9pf92oNRR_!!6000000003017-0-tps-8000-5333.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01KYkURX1NHeeb97cbP_!!6000000001545-0-tps-8000-5333.jpg",
        },
        {
          name: "公益议题辩论",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN017BmM0l1Cq2cAENYLN_!!6000000000131-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01qrJLbT1fgXFhekZDu_!!6000000004036-0-tps-3000-2000.jpg",
        },
        {
          name: "江西寻乌-戈新县11",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01lAMI3F1ND4gye0nit_!!6000000001535-0-tps-3648-2736.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01i2cAOA1uVk9hNRYel_!!6000000006043-0-tps-3648-2736.jpg",
        },
        // {
        //   name: "截屏2021-12-13 上午11.49.35",
        //   cover:
        //     "https://img.alicdn.com/imgextra/i1/O1CN01tSC6tk1Z9CjN90KCg_!!6000000003151-2-tps-1980-1350.png",
        //   image:
        //     "https://img.alicdn.com/imgextra/i3/O1CN01n8cooR1qwzFdQ4DQy_!!6000000005561-2-tps-1980-1350.png",
        // },
        {
          name: "截屏2021-12-13 下午1.49.23",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01xORdRF1JKAMSDdl4t_!!6000000001009-2-tps-2032-1266.png",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01PfHeT81NSe9nxZmZH_!!6000000001569-2-tps-2032-1266.png",
        },
        {
          name: "精彩1",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01msMQAi1rJQeyCCoJ8_!!6000000005610-0-tps-4032-3024.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01hHUT6T1J0TKUWGj4s_!!6000000000966-0-tps-4032-3024.jpg",
        },
        {
          name: "刘云飞",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01b76h2x1iVUY9ObpQW_!!6000000004418-0-tps-7952-5304.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN015lfZnS1RMvfztOxfm_!!6000000002098-0-tps-7952-5304.jpg",
        },
        {
          name: "刘云飞假发",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01tSd06H1wRHWpuQbl4_!!6000000006304-0-tps-1200-900.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01t7q8kD1YFmoFM6XfI_!!6000000003030-0-tps-1200-900.jpg",
        },
        {
          name: "培培2",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01ItINSI28Fcq75xaLw_!!6000000007903-0-tps-1276-956.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01zDqHW91jhgi5Nf3NJ_!!6000000004580-0-tps-1276-956.jpg",
        },
        {
          name: "首批乡村振兴技术官终选",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01J5GX2K1azFLwvkFAS_!!6000000003400-0-tps-3600-2400.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01kOMoZy1iCiJLEqnjk_!!6000000004377-0-tps-3600-2400.jpg",
        },
        {
          name: "数农3",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01rzRhG71CmNRnKn0DJ_!!6000000000123-2-tps-640-482.png",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01bCQFmh1UmWeuBsKhk_!!6000000002560-2-tps-640-482.png",
        },
        {
          name: "四川苍溪-孙文",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01livj9b1Os7nytunrg_!!6000000001760-0-tps-4096-3072.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01Gg9bQK1nvfGBigJfi_!!6000000005152-0-tps-4096-3072.jpg",
        },
        {
          name: "童伴之家1",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01psb3mU1yUbdM0nt7F_!!6000000006582-0-tps-4016-2256.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01DGVRPj1XMMsx2FQO0_!!6000000002909-0-tps-4016-2256.jpg",
        },
        {
          name: "现场候选人对关注的公益项目演说",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01BDb2Kp1xEkIqWhVGn_!!6000000006412-0-tps-3000-2000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN01QBod1Y1RwCAk8wTFm_!!6000000002175-0-tps-3000-2000.jpg",
        },
        {
          name: "尹贻盼",
          cover:
            "https://img.alicdn.com/imgextra/i3/O1CN01HuWg0p1wNcMSHoCQ3_!!6000000006296-0-tps-4000-3000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN013B8wwH1FaPwzWa5dy_!!6000000000503-0-tps-4000-3000.jpg",
        },
        {
          name: "C07C3881-BBF4-4e34-8BDD-AC3986AEAB1A",
          cover:
            "https://img.alicdn.com/imgextra/i4/O1CN01vVYpoy25z1FJzYR5s_!!6000000007596-2-tps-1126-696.png",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01P7BAWH23PB1FB3Nmg_!!6000000007247-2-tps-1126-696.png",
        },
        {
          name: "C960A6E3-DC6E-41AF-8E94-52CC06F98B7B-375-000001143E8FC8F0",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01tZD3MX1ccSsqJVJ5y_!!6000000003621-0-tps-1200-800.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01ywRLFg1ToWlxgG7of_!!6000000002429-0-tps-1200-800.jpg",
        },
        {
          name: "IMG_0797",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN017M6Yj91MNmKcPqU7U_!!6000000001423-0-tps-4032-3024.jpg",
          image:
            "https://img.alicdn.com/imgextra/i1/O1CN01YUEXVx1oIZ47Gax3I_!!6000000005202-0-tps-4032-3024.jpg",
        },
        {
          name: "IMG_2314",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01f4tmnx1ldgTslaua2_!!6000000004842-0-tps-4000-3000.jpg",
          image:
            "https://img.alicdn.com/imgextra/i4/O1CN017NdSsA1HOczDgOpHy_!!6000000000748-0-tps-4000-3000.jpg",
        },
        {
          name: "IMG_6553",
          cover:
            "https://img.alicdn.com/imgextra/i1/O1CN01gBYFOn26F36rPJRXk_!!6000000007631-0-tps-5184-3456.jpg",
          image:
            "https://img.alicdn.com/imgextra/i3/O1CN01CWFMKU1ehAB8RjLDr_!!6000000003902-0-tps-5184-3456.jpg",
        },
        {
          name: "lADPDetfU4pHj0_NCNzNC9A_3024_2268",
          cover:
            "https://img.alicdn.com/imgextra/i2/O1CN01oGNaMw1RweZNUSzZr_!!6000000002176-0-tps-3024-2268.jpg",
          image:
            "https://img.alicdn.com/imgextra/i2/O1CN01AUAcRR24kX6R0q8Qc_!!6000000007429-0-tps-3024-2268.jpg",
        },
      ],
    };
  },
  computed: {
    player() {
      return this.$refs.videoPlayer.player;
    },
  },
  methods: {
    // 处理瀑布流左右数据
    updateWaterfall() {
      const leftHeight = this.$refs.left.clientHeight;
      const rightHeight = this.$refs.right.clientHeight;
      let item = this.messagelistarr.shift();
      if (item == null) {
        return;
      }
      // console.log(leftHeight, centerHeight, rightHeight);
      if (this.isMobile) {
        if (leftHeight <= rightHeight) {
          this.leftMessagelistarr.push(item);
        } else {
          this.rightMessagelistarr.push(item);
        }
      } else {
        const centerHeight = this.$refs.center.clientHeight;

        if (leftHeight <= centerHeight && leftHeight <= rightHeight) {
          this.leftMessagelistarr.push(item);
        } else if (centerHeight <= leftHeight && centerHeight <= rightHeight) {
          this.centerMessagelistarr.push(item);
        } else if (rightHeight <= leftHeight && rightHeight <= centerHeight) {
          this.rightMessagelistarr.push(item);
        }
      }
      this.$nextTick(function () {
        this.updateWaterfall();
      });
    },
    // 关闭分享亲友提示
    closeShowShare() {
      this.isShowShare = false;
    },
    // 截取参数
    getlocationString(key, Url) {
      //key为要获取的字段，url为获取字段的链接
      var str = Url;
      str = str.split("?")[1];
      // str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      if (obj[key].indexOf("#") == -1) {
        return obj[key];
      } else {
        return obj[key].split("#")[0];
      }
    },
    // 回到顶部
    returnTop() {
      this.$refs.page.scrollTop = 0;
      document.getElementsByClassName(
        "vue-waterfall-easy-scroll"
      )[0].scrollTop = 0;
    },
    //设置中英文
    setLang() {
      if (this.ali_jiashu_lang == "cn") {
        window.localStorage.setItem("ali_jiashu_lang", "en");
        this.ali_jiashu_lang = "en";
      } else if (this.ali_jiashu_lang == "en") {
        window.localStorage.setItem("ali_jiashu_lang", "cn");
        this.ali_jiashu_lang = "cn";
      }
      this.reload();
    },
    // 判断当前使用端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // 游客注册
    async registerTourist() {
      let res = await registerTourist({});
      if (res.code == 0) {
        console.log("完成亲友注册");
        window.localStorage.setItem("ali_jiashu_UserToken", res.data.token);
        this.$router.replace({
          name: "upload",
        });
      }
    },
    //pc Share
    showPcSahre() {
      // this.$refs.videoPlayer.pause();
      this.player.pause();
      this.isShowPcShare = true;
      // window._czc.push([
      //   "_trackEvent",
      //   "用户",
      //   "点击按钮",
      //   "分享给家人",
      //   "1",
      //   "1",
      // ]);
    },
    showPcSubmit() {
      // this.$refs.videoPlayer.pause();
      this.player.pause();
      this.isShowPcSubmit = true;
      // window._czc.push([
      //   "_trackEvent",
      //   "用户",
      //   "点击按钮",
      //   "分享给家人",
      //   "1",
      //   "1",
      // ]);
    },
    // 显示上传分类
    ShowUpload() {
      return
      // this.$refs.videoPlayer.pause();
      this.player.pause();
      if (this.isMobile) {
        console.log("Mobile，判断是否有缓存或者链接携带参数");
        if (window.localStorage.getItem("ali_jiashu_UserToken")) {
          console.log("有缓存");

          this.$router.replace({
            name: "upload",
          });
        } else {
          console.log("没有缓存，开始判断环境");
          var ua = navigator.userAgent.toLowerCase();
          var isWeixin = ua.indexOf("micromessenger") != -1;
          if (isWeixin) {
            // console.log("微信环境");
            console.log("执行微信授权");
            var url =
              "https://jiashu.alibaba.com/index.php/api/auth/weiXinCallback";
            // window.localStorage.clear(); //清除缓存
            window.location.replace(
              "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx93571cfb54d3fc36&redirect_uri=" +
                encodeURIComponent(url) +
                "?returnUrl=https://jiashu.alibaba.com?completelogin=1&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"
              //?completelogin=1
            );
            return;
          }
          if (ua.match(/AlipayClient/i) == "alipayclient") {
            // console.log("支付宝浏览器");
            console.log("执行支付宝授权");
            window.location.replace(
              "https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021003103635439&scope=auth_user&redirect_uri=https://jiashu.alibaba.com/index.php/api/auth/aliPayCallback?returnUrl=https://jiashu.alibaba.com?completelogin=1"
            );
            return;
          }
          if (dd.env.platform != "notInDingTalk") {
            // console.log("钉钉环境");
            console.log("执行钉钉授权");
            window.location.replace(
              "https://oapi.dingtalk.com/connect/oauth2/sns_authorize?appid=ding6mnsowo8chcdwyzr&response_type=code&scope=snsapi_auth&state=STATE&redirect_uri=https://jiashu.alibaba.com/index.php/api/auth/dingCallback"
            );
            return;
          }
          console.log("不在微信、钉钉、支付宝内；注册亲友身份");
          this.registerTourist();
          return;
        }
        return;
        this.$refs.obtain.style.display = "none";
        this.isShowUpload = true;
      } else {
        console.log("2");
        // this.isShowPcShare = true;
        this.isShowPcSubmit = true;
      }
    },
    // 关闭pc分享给家人提示
    closePcShare() {
      this.isShowPcShare = false;
      this.isShowPcSubmit = false;
    },
    // 关闭上传分类
    closeUpload() {
      this.$refs.obtain.style.display = "block";
      // this.$refs.videoPlayer.pause();
      this.player.pause();
      this.isShowUpload = false;
    },
    // 获取距离顶部高度
    handleScrollx() {
      let that = this;
      if (that.isShowTab == "message") {
        if (that.$refs.page) {
          let scrollTop = that.$refs.page.scrollTop;
          let clientHeight = that.$refs.page.clientHeight;
          let scrollHeight = that.$refs.page.scrollHeight;
          // console.log('1',scrollTop + clientHeight)
          // console.log('2',scrollHeight)
          // console.log('3',scrollTop + clientHeight >= scrollHeight)
          if (scrollTop + clientHeight >= scrollHeight - 10) {
            console.log("滚动到底部了");
            if (!that.isHasMore) {
              that.$toast(2000, "没有更多了");
            } else {
              if (!that.isShowLoading) {
                that.page = that.page + 1;
                that.getList();
              }
            }
            // document.getElementsByClassName(
            //   "vue-waterfall-easy-scroll"
            // )[0].className = "vue-waterfall-easy-scroll lists_scroll";
            // } else {
            //   document.getElementsByClassName(
            //     "vue-waterfall-easy-scroll"
            //   )[0].className = "vue-waterfall-easy-scroll";
          }
        }
      }

      if (that.$refs.obtain) {
        if (that.$refs.obtain.getBoundingClientRect().top < 10) {
          if (that.$refs.videoPlayer) {
            // that.$refs.videoPlayer.pause();
            this.player.pause();
          }
        }
      }
      if (that._isMobile()) {
      } else {
        if (that.$refs.page) {
          let scrollTop = that.$refs.page.scrollTop;
          if (scrollTop > 480) {
            that.$refs.returnTop.style.display = "block";
          } else {
            that.$refs.returnTop.style.display = "none";
          }
        }
      }
    },
    // 获取用户信息
    async getUserInfo() {
      let that = this;
      let res = await getUserInfo({});
      if (res.code == 0) {
        // 更新用户id
        // console.log('更新用户id', res.data.userInfo.id);
        that.userInfoId = res.data.userInfo.id;
        window.localStorage.setItem(
          "ali_jiashu_avatar",
          res.data.userInfo.avatar
        );
        window.localStorage.setItem(
          "ali_jiashu_nickname",
          res.data.userInfo.nickname
        );
      }
    },
    // 获取留言列表
    async getList() {
      let that = this;
      that.isShowLoading = true;
      let res = await getCommentList({
        page: that.page,
        pageSize: that.pageSize,
      });
      if (res.code == 0) {
        that.isShowLoading = false;
        if (res.data.lists.length < 10) {
          that.isHasMore = false;
        }
        if (res.data.lists.length != 0) {
          if (that.page == 1) {
            that.messagelistarr = res.data.lists;
            if (that.firstmessage) {
              that.messagelistarr.unshift(that.firstmessage);
            }
          } else {
            // console.log(that.messagelistarr);
            // console.log(res.data.lists);
            that.messagelistarr = that.messagelistarr.concat(res.data.lists);
            if (that.firstmessage) {
              that.messagelistarr.unshift(that.firstmessage);
            }
          }
          // that.messagelistarr.push(res.data.lists);
          console.log(that.messagelistarr);
          this.updateWaterfall();
        } else {
          // that.$refs.waterfall.waterfallOver();
        }
      } else {
        // that.$refs.waterfall.waterfallOver();
        that.$toast(2000, "error");
      }
    },
    // 获取用户上传的留言
    async getUserComments() {
      let that = this;
      let res = await getUserComments({});
      if (res.code == 0) {
        console.log(res);
        res.data.lists.avatar =
          window.localStorage.getItem("ali_jiashu_avatar");
        res.data.lists.nickname = window.localStorage.getItem(
          "ali_jiashu_nickname"
        );
        that.firstmessage = res.data.lists;
        // console.log(that.firstmessage);
        this.getList();
      } else {
        that.$toast(2000, "error");
      }
    },
    // 切换首页tab
    tabCheck(str) {
      if (str == "message") {
        this.isShowTab = str;
        this.isHasMore = true;
        this.messagelistarr = [];
        this.leftMessagelistarr = [];
        this.centerMessagelistarr = [];
        this.rightMessagelistarr = [];
        this.firstmessage = null;
        this.page = 1;
        this.pageSize = 10;
        this.getList();
      } else if (str == "video") {
        this.isShowTab = str;
      }
    },
    // 切换文化活动子级Tab
    checkClassTabLists(str, index) {
      if (this.ali_jiashu_lang == "en") {
        this.active_tab = str.title_en;
        this.classTabListsDetailsTitle =
          this.classTabListsDetails[index].title_en;
        this.classTabListsDetailsDesc =
          this.classTabListsDetails[index].desc_en;
      } else {
        this.active_tab = str.title;
        this.classTabListsDetailsTitle = this.classTabListsDetails[index].title;
        this.classTabListsDetailsDesc = this.classTabListsDetails[index].desc;
      }
      this.classTabListsDetailsCover = this.classTabListsDetails[index].cover;
      this.classTabListsDetailsVideo = this.classTabListsDetails[index].video;
      this.isShowClassContent = false;
      this.isShowClassContent = true;
      console.log(str);

      this.image_lists = [];
      if (str.title == "阿里日") {
        this.image_lists = this.alidayImgarr;
      }
      if (str.title == "年陈") {
        this.image_lists = this.Aliversary;
      }
      if (str.title == "集体婚礼") {
        this.image_lists = this.GroupWedding;
      }
      if (str.title == "活色生香") {
        this.image_lists = this.Events;
      }
      if (str.title == "拔河大赛") {
        this.image_lists = this.TugofWar;
      }
      if (str.title == "音乐季") {
        this.image_lists = this.MusicSeasonarr;
      }
      if (str.title == "双11") {
        this.image_lists = this.Double11arr;
      }
      if (str.title == "社会责任") {
        this.image_lists = this.SocialResponsibilityarr;
      }
    },
    // 跳转至上传页面
    goToUpload(type) {
      // console.log(this.$router.history.current.name);
      localStorage.setItem("uploadtype", type);
      if (this.$router.history.current.name != "upload") {
        this.$router.replace({
          name: "upload",
        });
      }
    },
    // 瀑布流滚动到底部了
    scrollbottom() {
      console.log("滚动到底部了", this.isHasMore);
      if (!this.isHasMore) {
        // this.$refs.waterfall.waterfallOver();
      } else {
        this.page = this.page + 1;
        this.getList();
      }
    },
    // 显示留言详情蒙层
    changeComponentShow(params) {
      this.isShowdetailsModel = params;
      this.$refs.obtain.style.display = "block";
      // this.$refs.videoPlayer.pause();
      this.player.pause();
    },
    // 查看图片蒙层
    changeLookimgShow(params) {
      this.isShowLookImgModel = params;
      this.$refs.obtain.style.display = "block";
      // this.$refs.videoPlayer.pause();
      this.player.pause();
    },
    // PC查看图片蒙层
    changeLookimgPcShow(params) {
      this.isShowLookImgPcModel = params;
      this.$refs.obtain.style.display = "block";
      // this.$refs.videoPlayer.pause();
      this.player.pause();
    },
    // mo分类查看查看图片
    changeSeeClassimgShow(params) {
      this.isShowClassImgModel = params;
      this.$refs.obtain.style.display = "block";
      // this.$refs.videoPlayer.pause();
      this.player.pause();
    },
    // PC分类查看查看图片
    changeSeeClassimgPcShow(params) {
      this.isShowPcClassImgModel = params;
      this.$refs.obtain.style.display = "block";
      // this.$refs.videoPlayer.pause();
      this.player.pause();
    },
    // 显示详情
    goToDetails(value) {
      console.log("显示详情");
      console.log(value);
      this.$refs.obtain.style.display = "none";
      // this.$refs.videoPlayer.pause();
      this.player.pause();
      this.messageInfoDetails = value;
      this.isShowdetailsModel = true;
      if (value.status == 2) {
        console.log("审核中作品，不注册分享");
      } else {
        console.log("已通过作品,重置分享链接为作品详情");
        this.getJsSDKConfig(value.id);
        this.sharedingding(value.id);
      }
    },
    // 查看图片
    showImageMask(value) {
      console.log("查看图片", value);
      // console.log("查看图片", this.messageInfoDetails);
      this.messageInfoDetails = value;
      this.isShowLookImgModel = true;
      this.$refs.obtain.style.display = "none";
      // this.$refs.videoPlayer.pause();
      this.player.pause();
    },
    // 查看图片-2
    showImageMaskTwo(value, index) {
      console.log(value, index);
      this.classImgarr = value;
      this.classImgarrIndex = index;
      if (this.isMobile) {
        console.log("mo");
        this.isShowClassImgModel = true;
        this.$refs.obtain.style.display = "none";
        // this.$refs.videoPlayer.pause();
        this.player.pause();
      } else {
        console.log("pc");
        this.isShowPcClassImgModel = true;
        this.$refs.obtain.style.display = "none";
        // this.$refs.videoPlayer.pause();
        this.player.pause();
      }
      return;
      this.messageInfoDetails = {
        category: 2,
        linkUrl: [value.cover],
        imgurl: value.image,
        name: value.name,
      };
      console.log("查看大图、下载", this.messageInfoDetails);
    },
    // 文化活动查看视频
    showImageMask_class() {
      // window._czc.push([
      //   "_trackEvent",
      //   "用户",
      //   "点击播放",
      //   "文化活动视频",
      //   "1",
      //   "1",
      // ]);
      this.messageInfoDetails = {
        category: 1,
        linkUrl: [this.classTabListsDetailsVideo],
        cover: this.classTabListsDetailsCover,
      };
      console.log(this.messageInfoDetails);
      if (this.isMobile) {
        // this.$refs.videoPlayer.pause();
        this.player.pause();
        this.$refs.obtain.style.display = "none";
        this.isShowLookImgModel = true;
      } else {
        // this.$refs.videoPlayer.pause();
        this.player.pause();
        this.$refs.obtain.style.display = "none";
        this.isShowLookImgPcModel = true;
      }
    },
    // 精彩视频查看视频
    showImageMask_video(videoUrl, cover, index, videoArr) {
      // window._czc.push([
      //   "_trackEvent",
      //   "用户",
      //   "点击播放",
      //   "精彩视频",
      //   "1",
      //   "1",
      // ]);
      this.messageInfoDetails = {
        category: 1,
        linkUrl: [videoUrl],
        cover: [cover],
        index: index,
        videoArr: videoArr,
        tabType: "video",
      };
      // console.log("精彩视频查看视频s");
      // console.log(this.messageInfoDetails);
      // console.log("精彩视频查看视频e");
      if (this.isMobile) {
        // this.$refs.videoPlayer.pause();
        this.player.pause();
        this.$refs.obtain.style.display = "none";
        this.isShowLookImgModel = true;
      } else {
        // this.$refs.videoPlayer.pause();
        this.player.pause();
        this.$refs.obtain.style.display = "none";
        this.isShowLookImgPcModel = true;
      }
    },
    // 删除留言
    async deleteDetails(value) {
      console.log("删除留言del");
      console.log(value);
      let that = this;
      let res = await deleteComment({
        cid: value.id,
      });
      if (res.code == 0) {
        that.isHasMore = true;
        that.messagelistarr = [];
        that.leftMessagelistarr = [];
        that.centerMessagelistarr = [];
        that.rightMessagelistarr = [];
        that.firstmessage = null;
        that.page = 1;
        that.pageSize = 10;
        that.getList();
        that.$toast(2000, that.ali_jiashu_lang == "cn" ? "已删除" : "success");
      } else {
        that.$toast(2000, "error");
      }
    },
    // input——image——onchange
    addImg(event) {
      let file = event.target.files;
      let len = file.length;
      if (len > 9) {
        this.$toast(
          1000,
          this.ali_jiashu_lang == "cn" ? "最多可上传9张" : "Max length 9"
        );
        return false;
      } else {
        this.isShowUpload = false;
        this.isShowUploadLoading = true;
        this.linkurlArr = [];
        getSTSToken()
          .then((res) => {
            console.log(res);
            this.credentials = res.data.info;
            file.forEach((element, index) => {
              this.uploadfile(element, index, len, 2); // 参数： 文件、下标位置、总长度、类型（1:视频，2:图片）
            });
          })
          .catch((err) => {
            console.warn("获取阿里云STS失败！", err);
          });
      }
    },
    // input——video——onchange
    addVideo(event) {
      this.isShowUpload = false;
      this.isShowUploadLoading = true;
      this.linkurlArr = [];
      getSTSToken()
        .then((res) => {
          console.log(res);
          this.credentials = res.data.info;
          let file = event.target.files;
          this.uploadfile(file[0], 0, 1, 1); // 参数： 文件、下标位置、总长度、类型（1:视频，2:图片）
        })
        .catch((err) => {
          console.warn("获取阿里云STS失败！", err);
        });
    },
    // 上传文件
    async uploadfile(work, index, count, type) {
      let that = this;
      console.log(type == 1 ? "视频" : "图片");
      const extensionName = work.name.substr(work.name.indexOf(".")); // 文件扩展名
      that.ossUploadVideoFile(work, extensionName, index, count, type);
    },
    // 上传
    ossUploadVideoFile(option, extensionName, index, count, type) {
      const that = this;
      const date = formatTime(new Date(), "YMD"); // 当前时间
      const dateTime = formatTime(new Date(), "YMDhms"); // 当前时间
      const randomStr = that.randomString(4); //  4位随机字符串
      const fileName =
        "file/" + date + "/" + dateTime + "_" + randomStr + extensionName; // 文件名字（相对于根目录的路径 + 文件名）
      // 执行上传
      this.commonUpload(option, fileName)
        .then((res) => {
          that.linkurlArr[index] = res.url;
          this.linkurlArrnum += 1;
          if (this.linkurlArrnum == count) {
            localStorage.setItem(
              "uploadingLinkurl",
              JSON.stringify(that.linkurlArr)
            );
            localStorage.setItem("uploadtype", type);
            that.isShowUploadLoading = false;
            that.goToUpload();
            return;
          }
        })
        .catch((err) => {
          console.log(err);
          alert("oss普通上传失败, 请重新上传");
        });
    },
    //创建随机字符串
    randomString(num) {
      const chars = [
        "0",
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "a",
        "b",
        "c",
        "d",
        "e",
        "f",
        "g",
        "h",
        "i",
        "j",
        "k",
        "l",
        "m",
        "n",
        "o",
        "p",
        "q",
        "r",
        "s",
        "t",
        "u",
        "v",
        "w",
        "x",
        "y",
        "z",
      ];
      let res = "";
      for (let i = 0; i < num; i++) {
        var id = Math.ceil(Math.random() * 35);
        res += chars[id];
      }
      return res;
    },
    // 普通上传
    async commonUpload(file, fileName) {
      if (!this.currentClient) {
        await this.createOssClient();
      }
      return new Promise((resolve, reject) => {
        return this.currentClient
          .put(fileName, file)
          .then((result) => {
            resolve(result);
          })
          .catch((err) => {
            reject(err);
          });
      });
    },
    // 获取STS Token
    getCredential() {
      return new Promise((resolve, reject) => {
        getSTSToken()
          .then((res) => {
            this.credentials = res.data.info;
          })
          .catch((err) => {
            console.warn("获取阿里云STS失败！", err);
          });
      });
    },
    // 创建oss客户端对象
    async createOssClient() {
      return new Promise((resolve, reject) => {
        if (!this.credentials.access_key_id) {
          console.log("重新获取");
          this.getCredential()
            .then((res) => {
              this.currentClient = new OSS({
                region: this.region,
                accessKeyId: this.credentials.access_key_id,
                accessKeySecret: this.credentials.access_key_secret,
                stsToken: this.credentials.security_token,
                bucket: this.bucket,
                secure: true,
              });
              resolve(this.currentClient);
            })
            .catch((err) => {
              reject(err);
              console.log("getCredentialErr", err);
            });
        } else {
          console.log("已有不获取");
          this.currentClient = new OSS({
            region: this.region,
            accessKeyId: this.credentials.access_key_id,
            accessKeySecret: this.credentials.access_key_secret,
            stsToken: this.credentials.security_token,
            bucket: this.bucket,
            secure: true,
          });
          resolve(this.currentClient);
        }
      });
    },
    // 获取微信sdk，注册分享
    getJsSDKConfig(id) {
      console.log("注册微信分享");
      let that = this;
      that.$axios
        .post("/api/auth/getWeiXinJSSdk", {
          url: location.href.split("#")[0],
        })
        .then((res) => {
          if (res.status === 200) {
            let JsSDKConfig = res.data;
            window.wx.config({
              beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
              debug: false,
              appId: JsSDKConfig.appId,
              timestamp: JsSDKConfig.timestamp,
              nonceStr: JsSDKConfig.nonceStr,
              signature: JsSDKConfig.signature,
              jsApiList: ["updateAppMessageShareData", "onMenuShareTimeline"],
            });
            window.wx.ready(function () {
              // console.log(window.localStorage.getItem("ali_jiashu_lang"));
              let title_text = "";
              let desc_text = "";
              if (window.localStorage.getItem("ali_jiashu_lang") == "en") {
                title_text = "More content on A Letter Home digital version.";
                desc_text = "Click to view";
              } else {
                title_text = "点击开启《2021阿里家书》";
                desc_text = "这一年的精彩瞬间，想第一时间与你分享";
              }
              // console.log(title_text,desc_text);
              // 转发
              window.wx.updateAppMessageShareData({
                title: title_text, // 分享标题
                desc: desc_text, // 分享描述
                link: "https://jiashu.alibaba.com?messageid=" + id + "#/", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl:
                  "https://img.alicdn.com/imgextra/i4/O1CN01hsTdS629If4vKEXIk_!!6000000008045-0-tps-1200-1200.jpg", // 分享图标
                success: function () {},
              });
              wx.onMenuShareTimeline({
                title: title_text, // 分享时的标题
                link: "https://jiashu.alibaba.com?messageid=" + id + "#/", // 分享时的链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl:
                  "https://img.alicdn.com/imgextra/i4/O1CN01hsTdS629If4vKEXIk_!!6000000008045-0-tps-1200-1200.jpg", // 分享时显示的图标
                //用户确认分享后执行的回调函数
                success: function () {},
                //用户取消分享后执行的回调函数
                cancel: function () {},
              });
            });
          }
        });
    },
    // 钉钉分享
    sharedingding(id) {
      console.log("注册钉钉分享");
      let title_text = "";
      let desc_text = "";
      if (window.localStorage.getItem("ali_jiashu_lang") == "en") {
        title_text = "More content on A Letter Home digital version.";
        desc_text = "Click to view";
      } else {
        title_text = "点击开启《2021阿里家书》";
        desc_text = "这一年的精彩瞬间，想第一时间与你分享";
      }
      // console.log(title_text,desc_text);
      window.dd.ready(function () {
        window.dd.biz.navigation.setRight({
          show: true,
          control: true,
          text: "分享",
          onSuccess: function () {
            window.dd.biz.util.share({
              type: 0, //分享类型，0:全部组件 默认；1:只能分享到钉钉；2:不能分享，只有刷新按钮
              url: "https://jiashu.alibaba.com?messageid=" + id + "#/",
              title: title_text,
              content: desc_text,
              image:
                "https://img.alicdn.com/imgextra/i4/O1CN01hsTdS629If4vKEXIk_!!6000000008045-0-tps-1200-1200.jpg",
              onSuccess: function () {},
              onFail: function () {},
            });
          },
        });
      });
    },
    onPlayerPause($event) {
      this.isPlay = false;
    },
    onPlayerPlay($event) {
      this.isPlay = true;
    },
  },
  mounted() {
    // this.registerTourist()
    window.addEventListener("scroll", this.handleScrollx, true);
    console.log("1");
    this.player.play();
    // document.getElementById("video").play();
    document.addEventListener(
      "WeixinJSBridgeReady",
      function () {
        this.player.play();
        // document.getElementById("video").play();
        console.log("自动播放音频");
      },
      false
    );
    console.log("2");
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScrollx);
  },
  created() {
    if (this._isMobile()) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.ali_jiashu_lang =
      window.localStorage.getItem("ali_jiashu_lang") || "cn";
    if (this.ali_jiashu_lang == "cn") {
      this.active_tab = "阿里日";
    } else {
      this.active_tab = "Ali Day";
    }
    this.checkClassTabLists({ title: "阿里日", title_en: "Ali Day" }, 0);
    this.getUserInfo(); // 获取用户信息

    let classtab = this.$route.params.classTab;
    if (classtab) {
      this.isShowTab = "class";
      this.active_tab = classtab;
      setTimeout(() => {
        if (classtab == "集体婚礼") {
          this.checkClassTabLists(
            { title: "集体婚礼", title_en: "Group Wedding" },
            4
          );
        }
        if (classtab == "拔河大赛") {
          this.checkClassTabLists(
            { title: "拔河大赛", title_en: "Tug of War" },
            5
          );
        }
        if (classtab == "音乐季") {
          this.checkClassTabLists(
            { title: "音乐季", title_en: "Music Season" },
            6
          );
        }
        if (classtab != "阿里日") {
          let height = this.$refs.scrollRef.scrollWidth;
          this.$refs.scrollRef.scrollTo(height, 0);
        }
      }, 500);
    }
    console.log("active_tab", this.active_tab);

    this.image_lists = this.alidayImgarr;
    let uploadshowfirst = this.$route.params.upload;
    if (uploadshowfirst) {
      console.log("发布完成回到首页，瀑布流第一个展示刚才发布过的作品");
      this.getUserComments();
    } else {
      this.getList();
    }
    console.log("______");
    console.log(window.localStorage.getItem("ali_jiashu_shareTips") == "show");
    console.log("______");
    if (window.localStorage.getItem("ali_jiashu_shareTips") == "show") {
      console.log("扫码分享提示");
      this.isShowShare = true;
      window.localStorage.setItem("ali_jiashu_shareTips", "hide");
    } else {
      console.log("no扫码分享提示");
    }
  },
  beforeDestroy() {},
};
</script>