<style>
.lang_box {
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  width: 1.8rem;
  height: 0.6rem;
  background-color: white;
  color: black;
  border-radius: 0.5rem;
}
.lang_bg {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 0.9rem;
  height: 0.5rem;
  background-color: #f65912;
  border-radius: 0.5rem;
  z-index: 1;
  transition: all 0.2s;
}
.lang_bg_left {
  left: 3%;
}
.lang_bg_right {
  width: 0.8rem;
  left: 50%;
}

.lang_cn,
.lang_en {
  width: 1rem;
  height: 0.6rem;
  text-align: center;
  font-size: 0.3rem;
  color: #f65912;
  font-weight: 500;
  z-index: 2;
  line-height: 0.6rem;
}
</style>
<template>
  <div id="home">
    <router-view v-if="isRouterAlive"></router-view>
  </div>
</template>

<script>
import { registerTourist } from "@/api/api"; // 导入我们的api接口
import * as dd from "dingtalk-jsapi";
export default {
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },
  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
    // 游客注册
    async registerTourist() {
      let res = await registerTourist({});
      if (res.code == 0) {
        console.log("完成亲友注册");
        window.localStorage.setItem("ali_jiashu_UserToken", res.data.token);
      }
    },
    // 判断当前使用端
    _isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    // 截取参数
    getlocationString(key, Url) {
      //key为要获取的字段，url为获取字段的链接
      var str = Url;
      str = str.split("?")[1];
      // str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      if (obj[key].indexOf("#") == -1) {
        return obj[key];
      } else {
        return obj[key].split("#")[0];
      }
    },

    // 获取微信sdk，注册分享
    getJsSDKConfig() {
      console.log("注册微信分享");
      let that = this;
      that.$axios
        .post("/api/auth/getWeiXinJSSdk", {
          url: location.href.split("#")[0],
        })
        .then((res) => {
          if (res.status === 200) {
            let JsSDKConfig = res.data;
            wx.config({
              beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
              debug: false,
              appId: JsSDKConfig.appId,
              timestamp: JsSDKConfig.timestamp,
              nonceStr: JsSDKConfig.nonceStr,
              signature: JsSDKConfig.signature,
              jsApiList: ["updateAppMessageShareData", "onMenuShareTimeline"],
            });
            wx.ready(function () {
              // console.log(window.localStorage.getItem("ali_jiashu_lang"));
              let title_text = "";
              let desc_text = "";
              if (window.localStorage.getItem("ali_jiashu_lang") == "en") {
                title_text = "More content on A Letter Home digital version.";
                desc_text = "Click to view";
              } else {
                title_text = "点击开启《2021阿里家书》";
                desc_text = "这一年的精彩瞬间，想第一时间与你分享";
              }
              // console.log(title_text,desc_text);
              // 转发
              wx.updateAppMessageShareData({
                title: title_text, // 分享标题
                desc: desc_text, // 分享描述
                link: "https://jiashu.alibaba.com#/", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl:
                  "https://img.alicdn.com/imgextra/i4/O1CN01hsTdS629If4vKEXIk_!!6000000008045-0-tps-1200-1200.jpg", // 分享图标
                success: function () {},
              });
              wx.onMenuShareTimeline({
                title: title_text, // 分享时的标题
                link: "https://jiashu.alibaba.com#/", // 分享时的链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl:
                  "https://img.alicdn.com/imgextra/i4/O1CN01hsTdS629If4vKEXIk_!!6000000008045-0-tps-1200-1200.jpg", // 分享时显示的图标
                //用户确认分享后执行的回调函数
                success: function () {},
                //用户取消分享后执行的回调函数
                cancel: function () {},
              });
            });
          }
        });
    },
    // 钉钉分享
    sharedingding() {
      console.log("注册钉钉分享");
      let title_text = "";
      let desc_text = "";
      if (window.localStorage.getItem("ali_jiashu_lang") == "en") {
        title_text = "More content on A Letter Home digital version.";
        desc_text = "Click to view";
      } else {
        title_text = "点击开启《2021阿里家书》";
        desc_text = "这一年的精彩瞬间，想第一时间与你分享";
      }
      // console.log(title_text,desc_text);
      window.dd.ready(function () {
        window.dd.biz.navigation.setRight({
          show: true,
          control: true,
          text: "分享",
          onSuccess: function () {
            window.dd.biz.util.share({
              type: 0, //分享类型，0:全部组件 默认；1:只能分享到钉钉；2:不能分享，只有刷新按钮
              url: "https://jiashu.alibaba.com#/",
              title: title_text,
              content: desc_text,
              image:
                "https://img.alicdn.com/imgextra/i4/O1CN01hsTdS629If4vKEXIk_!!6000000008045-0-tps-1200-1200.jpg",
              onSuccess: function () {},
              onFail: function () {},
            });
          },
        });
      });
    },
  },
  mounted() {},

  created() {
    if (location.href.indexOf("debug") > -1) {
      var new_element = document.createElement("script");
      new_element.setAttribute("type", "text/javascript");
      new_element.setAttribute(
        "src",
        "https://g.alicdn.com/code/lib/vConsole/3.4.0/vconsole.min.js"
      );
      document.body.appendChild(new_element);
      window.onload = function () {
        new window.VConsole();
      };
    }

    var search = window.location.href; //获取参数；结果：?xxxx=xxx
    // 中英文参数 标识:lang
    if (window.location.href.indexOf("lang") != -1) {
      let language = this.getlocationString("lang", search);
      window.localStorage.setItem("ali_jiashu_lang", language);
      this.lang = "en";
      console.log("中英文参数", language);
    } else {
      window.localStorage.setItem("ali_jiashu_lang", "cn");
      this.lang = "cn";
      console.log("中英文参数", "cn");
    }

    // 文化活动指定分类 标识:activity
    if (window.location.href.indexOf("activity") != -1) {
      let activity = this.getlocationString("activity", search);
      window.localStorage.setItem("ali_jiashu_activity", activity);
      console.log("文化指定分类", activity);
    }
    // 分享指定留言 标识:messageid
    if (window.location.href.indexOf("messageid") != -1) {
      let messageid = this.getlocationString("messageid", search);
      window.localStorage.setItem("ali_jiashu_messageid", messageid);
      console.log("指定留言", messageid);
    }
    // 消息推送通知 标识:messagepush
    if (window.location.href.indexOf("messagepush") != -1) {
      window._czc.push([
        "_trackEvent",
        "进去渠道",
        "类型",
        "消息推送通知",
        "1",
        "1",
      ]);
      console.log("消息推送通知进入活动");
    }
    // console.log(this.$route.query);
    // window.localStorage.setItem(
    //   "ali_jiashu_UserToken",
    //   "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDU2Njc0NTEsInVzZXJJZCI6MTB9.HFmBYaOuJRBLgQXR7ecRDFRG7I6BCtbDz0j6LZcXFQk"
    // );
    if (this._isMobile()) {
      console.log("Mobile，判断是否有缓存或者链接携带参数");
      var search = window.location.href; //获取参数；结果：?xxxx=xxx
      let token = "";
      if (window.location.href.indexOf("?") == -1) {
        token = "";
      } else {
        if (window.location.href.indexOf("token") == -1) {
          token = "";
        } else {
          token = this.getlocationString("token", search);
        }
      }
      if (token) {
        console.log("链接携带参数,存储缓存");
        window.localStorage.setItem("ali_jiashu_UserToken", token);
      }

      // 完成授权回掉进来
      if (window.location.href.indexOf("completelogin") != -1) {
        console.log("完成授权回掉进来");
        this.$router.replace({
          name: "upload",
        });
      }
    } else {
      console.log("PC,不进行授权，提示移动端打开");
    }

    console.log("读取缓存中的存储的指定参数，跳转不同页面");
    let classTab = window.localStorage.getItem("ali_jiashu_activity"); // 510:阿里日，520:集体婚礼，521:音乐节，910:拔河，
    if (classTab == "510") {
      window.localStorage.removeItem("ali_jiashu_activity");
      this.$router.replace({ name: "lists", params: { classTab: "阿里日" } });
      return;
    }
    if (classTab == "520") {
      window.localStorage.removeItem("ali_jiashu_activity");
      this.$router.replace({ name: "lists", params: { classTab: "集体婚礼" } });
      return;
    }
    if (classTab == "521") {
      window.localStorage.removeItem("ali_jiashu_activity");
      this.$router.replace({ name: "lists", params: { classTab: "音乐季" } });
      return;
    }
    if (classTab == "910") {
      window.localStorage.removeItem("ali_jiashu_activity");
      this.$router.replace({ name: "lists", params: { classTab: "拔河大赛" } });
      return;
    }
    let messageid = window.localStorage.getItem("ali_jiashu_messageid"); // 510:阿里日，520:集体婚礼，521:音乐节，910:拔河，
    if (messageid) {
      window.localStorage.removeItem("ali_jiashu_messageid");
      this.$router.replace({
        name: "details",
        params: { messageid: messageid },
      });
    }

    // 扫码分享 标识:scanning
    if (window.location.href.indexOf("scanning") != -1) {
      window._czc.push([
        "_trackEvent",
        "进去渠道",
        "扫码分享",
        "PC端二维码",
        "1",
        "1",
      ]);
      window.localStorage.setItem("ali_jiashu_shareTips", 'show');
      // console.log(window.location.host);
      // window.open(window.location.host)
      this.$router.replace({
        name: "lists",
      });
      console.log("扫码分享进入活动000000");
    }
    this.sharedingding();
    this.getJsSDKConfig();
  },
};
</script>

<style>
body {
  height: 100vh;
}
#home {
  position: fixed;
  top: 0;
  left: 0;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin: 0;
  width: 100%;
  height: 100vh;
  background-color: #fef7f2;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  font-weight: normal;
  vertical-align: baseline;
}
ol,
ul,
li {
  list-style: none;
}

a {
  text-decoration: none;
  backface-visibility: hidden;
}
/* html,
body {
  width: 100%;
  height: 100%;
  font-family: "Arial", "Microsoft YaHei", "黑体", "宋体", "微软雅黑",
    sans-serif;
} */
body {
  line-height: 1;
  -webkit-text-size-adjust: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
html {
  overflow-y: scroll;
}

/* flex布局 */
.flex {
  display: flex;
}
.flex-direction-row {
  flex-direction: row;
}
.flex-direction-column {
  flex-direction: column;
}
.flex-wrap {
  flex-wrap: wrap;
}
.flex-wrap-no {
  flex-wrap: nowrap;
}
.justify-content-start {
  justify-content: flex-start;
}
.justify-content-end {
  justify-content: flex-end;
}
.justify-content-center {
  justify-content: center;
}
.justify-content-between {
  justify-content: space-between;
}
.justify-content-around {
  justify-content: space-around;
}
.justify-content-evenly {
  justify-content: space-evenly;
}
.align-items-start {
  align-items: flex-start;
}
.align-items-end {
  align-items: flex-end;
}
.align-items-center {
  align-items: center;
}
.masking {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
  z-index: 100;
}

.vue-waterfall-easy-container .vue-waterfall-easy a > a {
  height: 0 !important;
}
.vue-waterfall-easy-container .vue-waterfall-easy a.img-wraper > img {
  display: none !important;
}
.vue-waterfall-easy-container .vue-waterfall-easy a.img-inner-box {
  box-shadow: none !important;
}
.vue-waterfall-easy-container .vue-waterfall-easy-scroll {
  height: 100% !important;
}

.vue-waterfall-easy-container
  .vue-waterfall-easy
  > .img-box.default-card-animation:last-child {
  padding-bottom: 10px !important;
}

::-webkit-scrollbar {
  width: 0 !important;
  height: 0 !important;
}

.swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;
}
.swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E") !important;
}

img[src=""],
img:not([src]) {
  opacity: 0;
}
@media only screen and (min-width: 600px) {
  .vue-waterfall-easy-container
    .vue-waterfall-easy
    > .img-box.default-card-animation {
    padding: 16px 8px 0 8px !important;
  }
  .vue-waterfall-easy-container > .loading {
    bottom: 13% !important;
  }
}
@media only screen and (max-width: 600px) {
  .vue-waterfall-easy-container .vue-waterfall-easy-scroll {
    width: 7.02rem !important;
    margin: 0 auto !important;
  }
  .vue-waterfall-easy-container > .loading {
    top: 80% !important;
    /* display: block !important; */
  }
}
.vue-waterfall-easy-container .vue-waterfall-easy-scroll {
  overflow: hidden !important;
}
.lists_scroll,
.vue-waterfall-easy-container .lists_scroll {
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}
.vue-waterfall-easy-container > .loading.ball-beat > .dot {
  /* display: none !important; */
  background-color: #ff5b00 !important;
}
.grayscale {
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  filter: gray;
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
}
</style>