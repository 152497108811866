/**   
 * api接口统一管理
 */
import { get, post } from '../untils/request'

// 游客注册
export function registerTourist(param) {
    return post(`/api/auth/registerTourist`, param)
}

// 评论列表
export function getCommentList(param) {
    return post(`/api/getCommentList`, param)
}

// 获取STSToken
export function getSTSToken(param) {
    return post(`/api/getSTSToken`, param)
}

// 获取签名
export function getSignedUrl(param) {
    return post(`/api/getSignedUrl`, param)
}

// 上传作品
export function create(param) {
    return post(`/api/create`, param)
}

// 获取我的作品
export function getUserInfo(param) {
    return post(`/api/getUserInfo`, param)
}

// 敏感词检测
export function sensitive(param) {
    return post(`/api/sensitive`, param)
}

// 获取用户上传的留言
export function getUserComments(param) {
    return post(`/api/getUserComments`, param)
}

// 删除上传的留言
export function deleteComment(param) {
    return post(`/api/deleteComment`, param)
}

// 分享获取留言详情
export function getCommentById(param) {
    return post(`/api/getCommentById`, param)
}












// 上传图片
export function upload(param) {
    return post(`/api/upload`, param)
}