import axios from "axios";

// 环境的切换
axios.defaults.headers.common['Accept'] = 'application/json'

axios.defaults.headers.get['Content-Type'] = 'application/json'
// post请求头
axios.defaults.headers.post["Content-Type"] =
    "application/x-www-form-urlencoded;charset=UTF-8";
// 请求拦截器
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("ali_jiashu_UserToken") || '';
        // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE2NDIzMDUwOTQsInVzZXJJZCI6N30.UR3LHcCrbFq8x4Bb1AkwcM2z1aoBJEyKdyUxaAegP40';
        if (token) {
            config.headers['Authorization'] = token // 让每个请求携带自定义token 请根据实际情况自行修改
        }
        // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
        // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
        return config;
    },
    (error) => {
        return Promise.error(error);
    }
);

// 响应拦截器
axios.interceptors.response.use(
    response => {
        if (response.status === 200) {
            return Promise.resolve(response);
        } else {
            return Promise.reject(response);
        }
    },
    // 服务器状态码不是200的情况    
    error => {
        if (error.response.status) {
            switch (error.response.status) {
                // 404请求不存在                
                case 404:
                    this.$toast(2000, 'error')
                    break;
                    // 其他错误，直接抛出错误提示                
                default:
                    this.$toast(2000, 'error')
            }
            return Promise.reject(error.response);
        }
    }
);

/** 
 * get方法，对应get请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function get(url, params) {
    return new Promise((resolve, reject) => {
        axios.get(url, {
                params: params
            })
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}

/** 
 * post方法，对应post请求 
 * @param {String} url [请求的url地址] 
 * @param {Object} params [请求时携带的参数] 
 */
export function post(url, params) {
    return new Promise((resolve, reject) => {
        axios.post(url, params)
            .then(res => {
                resolve(res.data);
            })
            .catch(err => {
                reject(err.data)
            })
    });
}