<style scoped>
@media only screen and (min-width: 600px) {
  .page {
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    height: 100vh;
    min-width: 1280px;
    min-height: 620px;
    background: url("https://img.alicdn.com/imgextra/i1/O1CN01Vme94m1akacoA9s1Y_!!6000000003368-2-tps-1920-900.png")
      center no-repeat;
    background-size: cover;
  }
}

/* 移动端样式 */
@media only screen and (max-width: 600px) {
  .page {
    position: fixed;
    top: 0;
    left: 0;
    width: 7.5rem;
    height: 100vh;
    background: url("https://img.alicdn.com/imgextra/i3/O1CN01jh6psC1hlEUMPXAr5_!!6000000004317-2-tps-3125-5558.png")
      no-repeat top;
    background-size: contain;
  }
}

.center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.center p {
  color: white;
  font-size: 0.5rem;
  line-height: 0.5rem;
  white-space: nowrap;
  margin: 0.6rem 0;
  font-weight: bold;
  text-shadow: #000 2px 0 0, #000 0 2px 0, #000 -2px 0 0, #000 0 -2px 0;
}
.logo {
  width: 3.66rem;
  height: 2.06rem;
  transform: scale(1.5);
  margin: 0 auto;
  background: url("https://res.xiaoxxx.com/test/m.xiaoxxx.com/20211201e726db1a5a664e31117237.png")
    no-repeat top;
  background-size: contain;
}
</style>
<template>
  <div class="page">
    <div class="center">
      <div class="logo" @click="gotoindex()"></div>
      <br />
      <p>{{ page_title }}</p>
      <p @touchstart="start">正在施工中......</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  components: {},
  props: {},
  data() {
    return {
      page_title: "",
    };
  },
  methods: {
    gotoindex() {
      this.$router.replace({
        name: "index",
      });
    },
    start(){
      console.log('souchstart')
    }
  },
  mounted() {},
  created() {
    // https://jiashu.alibaba.com#/?activity=910&lang=en
    console.log(this.$route.query);
    let type = this.$route.query.activity;
    let lang = this.$route.query.lang;
    if (type == "510") {
      this.page_title = lang == 'en' ? '阿里家书——阿里日_英文版' : "阿里家书——阿里日";
    } else if (type == "520") {
      this.page_title = lang == 'en' ? '阿里家书——集体婚礼_英文版' : "阿里家书——集体婚礼";
    } else if (type == "521") {
      this.page_title = lang == 'en' ? '阿里家书——音乐季_英文版' : "阿里家书——音乐季";
    } else if (type == "910") {
      this.page_title = lang == 'en' ? '阿里家书——拔河比赛_英文版' : "阿里家书——拔河比赛";
    } else {
      this.page_title = lang == 'en' ? '阿里家书_英文版' : "阿里家书";
    }
  },
  beforeDestroy() {},
};
</script>