<style scoped>
@media only screen and (min-width: 600px) {
  .page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff7f0;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .details_bg {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 7.5rem;
    height: 7.73rem;
    background: url("https://img.alicdn.com/imgextra/i3/O1CN01ixbbg21Jb712EgPt3_!!6000000001046-2-tps-750-773.png")
      no-repeat top;
    background-size: 100% 100%;
    z-index: -1;
  }
  .details {
    width: 7.02rem;
    /* height: 5.45rem; */
    background-color: #fff;
    border-radius: 0.15rem;
    margin: 0.3rem auto 0;
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
    padding: 0 0 0.15rem;
    overflow: hidden;
  }
  .details_user {
    margin: 0.3rem 0 0 0.3rem;
  }
  .details_user_avater {
    width: 0.44rem;
    height: 0.44rem;
    border-radius: 50%;
    overflow: hidden;
  }
  .details_user_avater img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .details_user_name {
    font-size: 0.24rem;
    color: black;
    opacity: 0.9;
    line-height: 0.44rem;
    margin: 0 0 0 0.1rem;
  }
  .details_desc {
    width: 6.5rem;
    margin: 0.2rem auto 0.2rem;
    color: black;
    /* font-size: 0.24rem; */
    font-size: 15px;
    text-align: justify;
    line-height: 0.36rem;
    word-break: break-all;
  }
  .details_video {
    position: relative;
    /* width: 2.32rem; */
    /* height: 2.32rem; */
    border-radius: 0.15rem;
    overflow: hidden;
    margin: 0.2rem 0.25rem 0;
  }
  /* .details_video img {
    display: block;
    width: 100%;
    height: 100%;
  } */

  .video_box {
    position: relative;
    /* width: 6.5rem; */
    /* height: 4.21rem; */
    border-radius: 0.15rem;
    overflow: hidden;
  }
  .video {
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    border-radius: 0.15rem;
  }
  .details_mask img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .details_mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 6.5rem;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .details_play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.65rem;
    height: 0.65rem;
    background: url("../assets/img/play.png") no-repeat top;
    background-size: contain;
  }
  .details_img_box {
    margin: 0.2rem 0.25rem 0;
  }
  .details_img {
    /* width: 2.1rem; */
    /* height: 2.1rem; */
    border-radius: 0.15rem;
    margin: 0 0 0.15rem 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .details_img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .return_list_box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.38rem;
    /* background-color: rgba(0, 0, 0, 0.4); */
  }
  .return_list {
    position: absolute;
    bottom: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 2.8rem;
    height: 0.92rem;
    /* background: url("../assets/img/return_list.png") no-repeat center; */
    background: url("https://img.alicdn.com/imgextra/i4/O1CN01lftXl01Zlg0J0cLqT_!!6000000003235-2-tps-280-92.png")
      no-repeat center;
    background-size: 100% 100%;
    color: white;
    font-size: 18px;
    line-height: 0.92rem;
    text-align: center;
    white-space: nowrap;
  }
}

/* 移动端样式 */
@media only screen and (max-width: 600px) {
  .page {
    position: fixed;
    top: 0;
    left: 0;
    width: 7.5rem;
    background-color: #fff7f0;
    height: 100vh;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  .details_bg {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 7.5rem;
    height: 7.73rem;
    background: url("https://img.alicdn.com/imgextra/i3/O1CN01ixbbg21Jb712EgPt3_!!6000000001046-2-tps-750-773.png")
      no-repeat top;
    background-size: 100% 100%;
    z-index: -1;
  }
  .details {
    width: 7.02rem;
    /* height: 5.45rem; */
    background-color: #fff;
    border-radius: 0.15rem;
    margin: 0.3rem auto 2rem;
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.1);
    padding: 0 0 0.15rem;
    overflow: hidden;
  }
  .details_user {
    margin: 0.3rem 0 0 0.3rem;
  }
  .details_user_avater {
    width: 0.44rem;
    height: 0.44rem;
    border-radius: 50%;
    overflow: hidden;
  }
  .details_user_avater img {
    display: block;
    width: 100%;
    height: 100%;
  }
  .details_user_name {
    font-size: 0.24rem;
    color: black;
    opacity: 0.9;
    line-height: 0.44rem;
    margin: 0 0 0 0.1rem;
  }
  .details_desc {
    width: 6.5rem;
    margin: 0.2rem auto 0.2rem;
    color: black;
    /* font-size: 0.24rem; */
    font-size: 15px;
    text-align: justify;
    line-height: 0.36rem;
    word-break: break-all;
  }
  .details_video {
    position: relative;
    /* width: 2.32rem; */
    /* height: 2.32rem; */
    border-radius: 0.15rem;
    overflow: hidden;
    margin: 0.2rem 0.25rem 0;
    /* margin: 0.2rem auto 0; */
  }
  /* .details_video img {
    display: block;
    width: 100%;
    height: 100%;
  } */
  .details_img_box {
    margin: 0.2rem 0.25rem 0;
  }
  .details_img {
    /* width: 2.1rem; */
    /* height: 2.1rem; */
    border-radius: 0.15rem;
    margin: 0 0 0.15rem 0;
    overflow: hidden;
    background-color: rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2);
  }
  .details_img img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .return_list_box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1.38rem;
    /* background-color: rgba(0, 0, 0, 0.4); */
  }
  .return_list {
    position: absolute;
    bottom: 0.2rem;
    left: 50%;
    transform: translateX(-50%);
    width: 2.8rem;
    height: 0.92rem;
    /* background: url("../assets/img/return_list.png") no-repeat center; */
    background: url("https://img.alicdn.com/imgextra/i4/O1CN01lftXl01Zlg0J0cLqT_!!6000000003235-2-tps-280-92.png")
      no-repeat center;
    background-size: 100% 100%;
    color: white;
    font-size: 18px;
    line-height: 0.92rem;
    text-align: center;
    white-space: nowrap;
  }

  .video_box {
    position: relative;
    /* width: 6.5rem; */
    /* height: 4.21rem; */
    border-radius: 0.15rem;
    overflow: hidden;
  }
  .video {
    /* position: absolute; */
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain;
    border-radius: 0.15rem;
  }

  .details_mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 6.5rem;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
  }
  .details_mask img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .details_play {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 0.65rem;
    height: 0.65rem;
    background: url("../assets/img/play.png") no-repeat top;
    background-size: 100% 100%;
  }

  .upload_loading_mask_text {
    margin-top: 2.4rem;
    font-size: 0.26rem;
    color: white;
    white-space: nowrap;
  }
  .upload_success_mask {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 999;
  }
  .upload_success_center {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 5.4rem;
    height: 3.6rem;
    background: url("https://img.alicdn.com/imgextra/i1/O1CN01UtSvHg22KlabUWKbF_!!6000000007102-2-tps-540-366.png")
      no-repeat top;
    background-size: 100% 100%;
  }
  .upload_success_icon {
    width: 0.52rem;
    height: 0.52rem;
    margin: 0.6rem auto 0;
    background: url("../assets/img/形状1873.png") no-repeat top;
    background-size: 100% 100%;
  }
  .upload_success_text {
    height: 0.38rem;
    margin: 0.6rem auto 0;
    color: black;
    text-align: center;
    white-space: nowrap;
    font-size: 0.32rem;
    line-height: 0.38rem;
  }
  .upload_success_btn {
    display: inline-block;
    height: 0.38rem;
    padding: 0.2rem 0.4rem;
    margin: 0.2rem auto 0;
    border-radius: 0.15rem;
    color: white;
    font-size: 0.32rem;
    line-height: 0.38rem;
    background-color: #ff6900;
  }
  .upload_success_close {
    position: absolute;
    bottom: -1.38rem;
    left: 50%;
    transform: translateX(-50%);
    width: 0.78rem;
    height: 0.78rem;
    margin: 0.6rem auto 0;
    background: url("../assets/img/关闭.png") no-repeat top;
    background-size: 100% 100%;
  }
  .share_icon {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    width: 3.72rem;
    height: 2.03rem;
    background: url("../assets/img/share.png") no-repeat top;
    background-size: 100% 100%;
  }
  .share_icon_en {
    width: 5.09rem;
    height: 2.08rem;
    background: url("../assets/img/share_en.png") no-repeat top;
    background-size: 100% 100%;
  }
}
</style>
<template>
  <div class="page">
    <div class="details_bg"></div>
    <div class="details" v-if="messageInfoDetails">
      <div class="details_user flex">
        <div class="details_user_avater">
          <img
            :src="
              messageInfoDetails.avatar ||
              'https://res.xiaoxxx.com/test/H5/UCAN/20211211cbfc4bf66cccbc56555432.png'
            "
          />
        </div>
        <div class="details_user_name">{{ messageInfoDetails.nickname }}</div>
      </div>
      <div class="details_desc">
        {{ messageInfoDetails.detail }}
      </div>
      <!-- 图片 flex flex-wrap justify-content-between-->
      <div
        class="details_img_box"
        v-if="
          messageInfoDetails.category == 2 &&
          messageInfoDetails.linkUrl.length != 0
        "
      >
        <div
          class="details_img"
          v-for="(item, index) in messageInfoDetails.linkUrl"
          :key="index"
        >
          <img :src="item" />
        </div>
      </div>

      <!-- 视频 -->
      <div
        class="details_video"
        v-if="
          messageInfoDetails.category == 1 &&
          messageInfoDetails.linkUrl.length != 0
        "
      >
        <!-- <img
          src="https://img.alicdn.com/imgextra/i1/O1CN01p0aiaW1zOwLk8gpvz_!!6000000006705-2-tps-662-372.png"
        /> -->
        <div class="video_box" ref="obtain" @click.stop="">
          <video
            :poster="messageInfoDetails.cover"
            class="video"
            controls
            controlslist="nodownload"
            ref="videoPlayer_1"
            preload
            x5-playsinline
            playsinline
            webkit-playsinline
            loop
            :src="messageInfoDetails.linkUrl[0]"
          >
          </video>
        </div>
        <div class="details_mask" v-if="isPlayVideoMask">
          <img :src="messageInfoDetails.cover" />
          <div class="details_play" @click="plsyVideo()"></div>
        </div>
      </div>
    </div>
    <div class="return_list_box">
      <div class="return_list" @click="goTolists()">
        {{ ali_jiashu_lang == "cn" ? "返回首页" : "Return" }}
      </div>
    </div>

    <!-- 上传完成 -->
    <div
      class="upload_success_mask"
      v-if="isShowUploadsuccess"
      @click.stop="isShowUploadsuccess == false"
    >
      <div class="upload_success_center">
        <div class="upload_success_icon"></div>
        <div class="upload_success_text">
          {{ ali_jiashu_lang == "cn" ? "发布成功" : "Comment submitted" }}
        </div>
        <div class="upload_success_btn" @click.stop="closeSuccess()">
          {{
            ali_jiashu_lang == "cn" ? "分享给家人" : "Share it with loved ones"
          }}
        </div>
        <div
          class="upload_success_close"
          @click.stop="isShowUploadsuccess = false"
        ></div>
      </div>
    </div>

    <!-- 分享亲友提示 -->
    <div
      class="upload_success_mask share_mask"
      v-if="isShowShare"
      @click="isShowShare = false"
    >
      <div
        :class="
          ali_jiashu_lang == 'cn' ? 'share_icon' : 'share_icon share_icon_en'
        "
      ></div>
    </div>
  </div>
</template>

<script>
import { getUserComments, getCommentById } from "@/api/api"; // 导入我们的api接口
export default {
  components: {},
  data() {
    return {
      ali_jiashu_lang: "cn",
      isPlayVideoMask: true,
      isShowUploadsuccess: false, // 上传完成提示
      isShowShare: false, // 分享蒙层提示
      messageInfoDetails: null, // 作品详情
    };
  },
  methods: {
    goTolists() {
      this.messageInfoDetails = null;
      if (this.$route.params.messageid) {
        this.$router.replace({
          name: "lists",
        });
      } else {
        this.$router.replace({
          name: "lists",
          params: {
            upload: true,
          },
        });
      }
    },
    // 点击播放视频
    plsyVideo() {
      this.isPlayVideoMask = false;
      this.$refs.videoPlayer_1.play();
    },
    // 获取用户上传的留言
    async getUserComments() {
      let that = this;
      let res = await getUserComments({});
      if (res.code == 0) {
        console.log(res.data.lists);
        res.data.lists.avatar =
          window.localStorage.getItem("ali_jiashu_avatar") || "";
        res.data.lists.nickname =
          window.localStorage.getItem("ali_jiashu_nickname") || "";
        this.messageInfoDetails = res.data.lists;
        this.isShowUploadsuccess = true;
      } else {
        // that.$toast(2000, res.message);
        that.$toast(2000, "error");
      }
    },
    // 获取微信sdk，注册分享
    getJsSDKConfig() {
      console.log("注册微信分享");
      let that = this;
      that.$axios
        .post("/api/auth/getWeiXinJSSdk", {
          url: location.href.split("#")[0],
        })
        .then((res) => {
          if (res.status === 200) {
            let JsSDKConfig = res.data;
            window.wx.config({
              beta: true, // 必须这么写，否则wx.invoke调用形式的jsapi会有问题
              debug: false,
              appId: JsSDKConfig.appId,
              timestamp: JsSDKConfig.timestamp,
              nonceStr: JsSDKConfig.nonceStr,
              signature: JsSDKConfig.signature,
              jsApiList: ["updateAppMessageShareData", "onMenuShareTimeline"],
            });
            window.wx.ready(function () {
              // console.log(window.localStorage.getItem("ali_jiashu_lang"));
              let title_text = "";
              let desc_text = "";
              if (window.localStorage.getItem("ali_jiashu_lang") == "en") {
                title_text = "More content on A Letter Home digital version.";
                desc_text = "I just posted a comment. Check it out!";
              } else {
                title_text = "点击开启《2021阿里家书》";
                desc_text = "我发布了一条留言，快来查看";
              }
              // console.log(title_text,desc_text);
              console.log(that.messageInfoDetails.id);
              // 转发
              window.wx.updateAppMessageShareData({
                title: title_text, // 分享标题
                desc: desc_text, // 分享描述
                link:
                  "https://jiashu.alibaba.com?messageid=" +
                  that.messageInfoDetails.id +
                  "#/", // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl:
                  "https://img.alicdn.com/imgextra/i4/O1CN01hsTdS629If4vKEXIk_!!6000000008045-0-tps-1200-1200.jpg", // 分享图标
                success: function () {},
              });
              wx.onMenuShareTimeline({
                title: title_text, // 分享时的标题
                link:
                  "https://jiashu.alibaba.com?messageid=" +
                  that.messageInfoDetails.id +
                  "#/", // 分享时的链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
                imgUrl:
                  "https://img.alicdn.com/imgextra/i4/O1CN01hsTdS629If4vKEXIk_!!6000000008045-0-tps-1200-1200.jpg", // 分享时显示的图标
                //用户确认分享后执行的回调函数
                success: function () {},
                //用户取消分享后执行的回调函数
                cancel: function () {},
              });
            });
          }
        });
    },
    // 钉钉分享
    sharedingding() {
      console.log("注册钉钉分享");
      let that = this;
      let title_text = "";
      let desc_text = "";
      if (window.localStorage.getItem("ali_jiashu_lang") == "en") {
        title_text = "More content on A Letter Home digital version.";
        desc_text = "I just posted a comment. Check it out!";
      } else {
        title_text = "点击开启《2021阿里家书》";
        desc_text = "我发布了一条留言，快来查看";
      }
      // console.log(title_text,desc_text);
      console.log(that.messageInfoDetails.id);
      window.dd.ready(function () {
        window.dd.biz.navigation.setRight({
          show: true,
          control: true,
          text: "分享",
          onSuccess: function () {
            window.dd.biz.util.share({
              type: 0, //分享类型，0:全部组件 默认；1:只能分享到钉钉；2:不能分享，只有刷新按钮
              url:
                "https://jiashu.alibaba.com?messageid=" +
                that.messageInfoDetails.id +
                "#/",
              title: title_text,
              content: desc_text,
              image:
                "https://img.alicdn.com/imgextra/i4/O1CN01hsTdS629If4vKEXIk_!!6000000008045-0-tps-1200-1200.jpg",
              onSuccess: function () {},
              onFail: function () {},
            });
          },
        });
      });
    },
    // 关闭上传成功提示，展示分享提示,重置分享链接
    closeSuccess() {
      this.isShowShare = true;
      this.isShowUploadsuccess = false;
      console.log("重置分享链接");
      this.getJsSDKConfig();
      this.sharedingding();
    },
    // 分享获取留言详情
    async getCommentById(id) {
      let that = this;
      let res = await getCommentById({
        cid: id,
      });
      if (res.code == 0) {
        console.log(res.data.info);
        this.messageInfoDetails = res.data.info;
      } else {
        that.$toast(2000, "error");
      }
    },
    // 截取参数
    getlocationString(key, Url) {
      //key为要获取的字段，url为获取字段的链接
      var str = Url;
      str = str.split("?")[1];
      // str = str.substring(1, str.length); // 获取URL中?之后的字符（去掉第一位的问号）
      // 以&分隔字符串，获得类似name=xiaoli这样的元素数组
      var arr = str.split("&");
      var obj = new Object();
      // 将每一个数组元素以=分隔并赋给obj对象
      for (var i = 0; i < arr.length; i++) {
        var tmp_arr = arr[i].split("=");
        obj[decodeURIComponent(tmp_arr[0])] = decodeURIComponent(tmp_arr[1]);
      }
      if (obj[key].indexOf("#") == -1) {
        return obj[key];
      } else {
        return obj[key].split("#")[0];
      }
    },
  },
  mounted() {},
  created() {
    this.ali_jiashu_lang =
      window.localStorage.getItem("ali_jiashu_lang") || "cn";
    // this.goTolists()
    // return
    let messageid = this.$route.params.messageid;
    let isUserUpload = this.$route.params.isUserUpload;
    console.log(messageid);
    if(isUserUpload){
      this.getUserComments();
      return
    }
    if (messageid) {
      this.getCommentById(messageid);
      return;
    } else {
      var search = window.location.href; //获取参数；结果：?xxxx=xxx
      let token = "";
      if (window.location.href.indexOf("?") == -1) {
        token = "";
      } else {
        if (window.location.href.indexOf("messageid") == -1) {
          messageid = "";
        } else {
          messageid = this.getlocationString("messageid", search);
        }
      }
      if (messageid) {
        console.log("链接携带参数,存储缓存");
        this.getCommentById(messageid);
        return;
      } else {
        this.getUserComments();
      }
    }
  },
  beforeDestroy() {},
};
</script>