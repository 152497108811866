import Vue from 'vue'
import VueRouter from 'vue-router'

import loading from '../views/loading.vue'
import lists from '../views/lists.vue'
import details from '../views/details.vue'
import upload from '../views/upload.vue'

import home from '../views/home.vue'

Vue.use(VueRouter)

const routes = [{
    path: '/',
    component: loading,
  },
  {
    path: '/loading',
    name: 'loading',
    component: loading
  },
  {
    path: '/lists',
    name: 'lists',
    component: lists,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/details',
    name: 'details',
    component: details
  },
  {
    path: '/upload',
    name: 'upload',
    component: upload
  },
  {
    path: '/home',
    name: 'home',
    component: home
  },
]
const router = new VueRouter({
  routes
})

export default router

// const router = new VueRouter({
//   routes,
//   scrollBehavior(to, from, savedPosition) {
//     if (savedPosition) {
//       return savedPosition
//     } else {
//       return {
//         x: 0,
//         y: 0
//       }
//     }
//   }
// })

// export default router