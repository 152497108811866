<style scoped>
@media only screen and (min-width: 600px) {
  .page {
    position: relative;
    top: 0%;
    left: 50%;
    transform: translate(-50%, 0%);
    width: 100%;
    height: 100vh;
    min-width: 1280px;
    min-height: 620px;
  }
  .loading_center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .logo {
    width: 148px;
    height: 107px;
    margin: 0 auto 0;
    background: url("https://img.alicdn.com/imgextra/i4/O1CN01f99Pyq1eCTdc7wZQp_!!6000000003835-2-tps-148-107.png")
      no-repeat top;
    background-size: contain;
  }
  .progress {
    position: relative;
    width: 530px;
    height: 20px;
    margin: 60px 0 0;
    background-color: #fadac2;
    border-radius: 15px;
    overflow: hidden;
  }
  .progressbar {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 0;
    height: 100%;
    border-radius: 15px;
    background-color: #ec6504;
    transition: all 0.1s;
  }

  .loading_center p {
    color: #e76405;
    line-height: 50px;
    white-space: nowrap;
    margin: 20px 0 0;
    font-size: 24px;
    font-weight: bold;
    /* text-shadow: #000 2px 0 0, #000 0 2px 0, #000 -2px 0 0, #000 0 -2px 0; */
  }
  .transition_animmate {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* width: 100%; */
    width: 1920px;
    /* height: 100%; */
    height: 1080px;
    /* background: url("../assets/img/PCBG_cn.png") no-repeat top; */
    background: url("https://img.alicdn.com/imgextra/i2/O1CN01QmVjvb1yE7NYGg5SF_!!6000000006546-0-tps-1920-1080.jpg")
      no-repeat top;
    background-size: 100% 100%;
  }
  .transition_animmate_en {
    background: url("https://img.alicdn.com/imgextra/i4/O1CN01rX9fuI1SPVWVJ6AX8_!!6000000002239-0-tps-1920-1080.jpg")
      no-repeat top;
    background-size: 100% 100%;
  }
  .pc_animate_text {
    opacity: 0;
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 43%;
    height: 28%;
    background: url("../assets/img/PCBG_cn_text.png") no-repeat top;
    background-size: 100% 100%;
    animation: pctextanimate 1.6s linear forwards;
  }
  .pc_animate_text_en {
    width: 864px;
    height: 382px;
    top: 44%;
    background: url("../assets/img/PCBG_en_text.png") no-repeat top;
    background-size: 100% 100%;
  }
  @keyframes pctextanimate {
    0% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .start_btn_front {
    position: absolute;
    bottom: 100px;
    left: 49.7%;
    transform: translateX(-50%);
    width: calc(433px / 2);
    height: calc(121px / 2);
    background: url("../assets/img/点击前.png") no-repeat bottom;
    background-size: 100% 100%;
    color: white;
    font-size: 22px;
    text-align: center;
    white-space: nowrap;
    font-weight: 500;
    line-height: 52.5px;
    cursor: pointer;
  }
  .start_btn_after {
    opacity: 1;
    position: absolute;
    bottom: 94px;
    left: 50%;
    transform: translateX(-50%);
    width: calc(424px / 2);
    height: calc(117px / 2);
    background: url("../assets/img/点击后.png") no-repeat bottom;
    background-size: 100% 100%;
    color: white;
    font-size: 22px;
    text-align: center;
    white-space: nowrap;
    font-weight: 500;
    line-height: 52.5px;
    cursor: pointer;
  }
  .left_top {
    position: absolute;
    top: -30px;
    left: -30px;
    width: 476px;
    height: 294px;
    background: url("https://img.alicdn.com/imgextra/i1/O1CN01Qnh93s1QPqaGPkofV_!!6000000001969-2-tps-476-294.png")
      no-repeat;
    background-size: 100% 100%;
    animation: left_top_animate 10s infinite linear;
  }
  @keyframes left_top_animate {
    0% {
      transform: rotate(0deg) translate(0, 0);
      transform-origin: 0% 0%;
    }
    50% {
      transform: rotate(-5deg) translate(-10px, -10px);
      transform-origin: 0% 0%;
    }
    100% {
      transform: rotate(0deg) translate(0, 0);
      transform-origin: 0% 0%;
    }
  }
  .left_bottom {
    position: absolute;
    bottom: -30px;
    left: -30px;
    width: 358px;
    height: 371px;
    background: url("https://img.alicdn.com/imgextra/i2/O1CN01vzAEJI1D4hI6QY5Hu_!!6000000000163-2-tps-358-371.png")
      no-repeat;
    background-size: 100% 100%;
    animation: left_bottom_animate 10s infinite linear;
  }
  @keyframes left_bottom_animate {
    0% {
      transform: rotate(0deg);
      transform-origin: 100% 0%;
    }
    50% {
      transform: rotate(5deg);
      transform-origin: 100% 0%;
    }
    100% {
      transform: rotate(0deg);
      transform-origin: 100% 0%;
    }
  }
  .right_bottom {
    position: absolute;
    bottom: -30px;
    right: -30px;
    width: 771px;
    height: 964px;
    background: url("https://img.alicdn.com/imgextra/i2/O1CN01ScXegT1RNO4by0z9R_!!6000000002099-2-tps-771-946.png")
      no-repeat;
    background-size: 100% 100%;
    animation: right_bottom_animate 10s infinite linear;
  }
  @keyframes right_bottom_animate {
    0% {
      transform: rotate(0deg) translate(0px, 0px);
      transform-origin: 100% 100%;
    }
    50% {
      transform: rotate(1deg) translate(-20px, -20px);
      transform-origin: 100% 100%;
    }
    100% {
      transform: rotate(0deg) translate(0px, 0px);
      transform-origin: 100% 100%;
    }
  }
  .grass {
    position: absolute;
    top: 50%;
    left: 15%;
    transform: translate(-50%, -50%);
    width: 212px;
    height: 403px;
    background: url("https://img.alicdn.com/imgextra/i3/O1CN01UFAXkp1tUztd8Wce6_!!6000000005906-2-tps-212-403.png")
      no-repeat;
    background-size: 100% 100%;
    animation: grass_animate 6s infinite linear;
  }
  @keyframes grass_animate {
    0% {
      transform: rotate(0deg) translate(-50%, -50%);
      transform-origin: 100% 100%;
    }
    50% {
      transform: rotate(-2deg) translate(-50%, -50%);
      transform-origin: 100% 100%;
    }
    100% {
      transform: rotate(0deg) translate(-50%, -50%);
      transform-origin: 100% 100%;
    }
  }
}

/* 移动端样式 */
@media only screen and (max-width: 600px) {
  .page {
    position: fixed;
    top: 0;
    left: 0;
    width: 7.5rem;
    height: 100vh;
  }
  .pc_animate_text {
    display: none;
  }
  .loading_center {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .logo {
    width: 1.48rem;
    height: 1.07rem;
    margin: 0 auto 0;
    background: url("https://img.alicdn.com/imgextra/i4/O1CN01f99Pyq1eCTdc7wZQp_!!6000000003835-2-tps-148-107.png")
      no-repeat top;
    background-size: contain;
  }
  .progress {
    position: relative;
    width: 5.3rem;
    height: 0.2rem;
    margin: 0.6rem 0 0;
    background-color: #fadac2;
    border-radius: 0.15rem;
    overflow: hidden;
  }
  .progressbar {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 0;
    height: 100%;
    border-radius: 0.15rem;
    background-color: #ec6504;
    transition: all 0.1s;
  }

  .loading_center p {
    color: #e76405;
    line-height: 0.5rem;
    white-space: nowrap;
    margin: 0.2rem 0 0;
    font-size: 0.28rem;
    font-weight: bold;
    /* text-shadow: #000 2px 0 0, #000 0 2px 0, #000 -2px 0 0, #000 0 -2px 0; */
  }
  .transition_animmate {
    position: relative;
    width: 7.5rem;
    height: 14.46rem;
    background: url("https://img.alicdn.com/imgextra/i4/O1CN01AYbrI71SqWtG6sqkM_!!6000000002298-2-tps-750-1450.png") no-repeat top;
    background-size: contain;
    animation: loadinganimate 3s linear forwards;
  }
  .transition_animmate_en {
    background: url("https://img.alicdn.com/imgextra/i4/O1CN018RvX9n1FasLdQCbL7_!!6000000000504-0-tps-750-1450.jpg") no-repeat top;
    background-size: contain;
  }

  @keyframes loadinganimate {
    0% {
      /* transform: translateX(0.1rem) scale(1); */
      transform: scale(1);
    }
    25% {
      /* transform: translateX(-0.4rem) scale(1.15); */
      transform: scale(1.05);
    }
    50% {
      transform: translate(0, 0) scale(1.1);
    }
    75% {
      transform: translateY(0.5rem) scale(1.15);
    }
    100% {
      transform: translateY(1rem) scale(1.2);
    }
  }

  .start_btn_front {
    position: absolute;
    bottom: 2rem;
    left: 49.5%;
    transform: translateX(-50%);
    width: 4.33rem;
    height: 1.21rem;
    background: url("../assets/img/点击前.png") no-repeat bottom;
    background-size: 100% 100%;
    color: white;
    font-size: 0.42rem;
    text-align: center;
    white-space: nowrap;
    font-weight: 500;
    line-height: 1.05rem;
  }
  .start_btn_after {
    opacity: 1;
    position: absolute;
    bottom: 1.94rem;
    left: 50%;
    transform: translateX(-50%);
    width: 4.24rem;
    height: 1.17rem;
    background: url("../assets/img/点击后.png") no-repeat bottom;
    background-size: 100% 100%;
    color: white;
    font-size: 0.42rem;
    text-align: center;
    white-space: nowrap;
    font-weight: 500;
    line-height: 1.05rem;
  }

  .left_top {
    display: none;
  }
  .left_bottom {
    display: none;
  }
  .right_bottom {
    display: none;
  }
  .grass {
    display: none;
  }
}
</style>
<template>
  <div class="page">
    <div class="loading_center" v-if="isShowPage == 'loading'">
      <div class="logo"></div>
      <div class="progress">
        <div
          class="progressbar"
          :style="'width:' + progressbar_width + '%;'"
        ></div>
      </div>
      <p>{{ ali_jiashu_lang == "cn" ? loadingTips : loadingTips_en }}</p>
    </div>
    <div
      :class="
        ali_jiashu_lang == 'cn'
          ? 'transition_animmate'
          : 'transition_animmate transition_animmate_en'
      "
      v-if="isShowPage == 'animate'"
    >
      <div
        :class="
          ali_jiashu_lang == 'cn'
            ? 'pc_animate_text'
            : 'pc_animate_text pc_animate_text_en'
        "
      ></div>
    </div>

    <!-- 左上 -->
    <div class="left_top" v-if="isShowPage == 'animate'"></div>
    <!-- 左下 -->
    <div class="left_bottom" v-if="isShowPage == 'animate'"></div>
    <!-- 右下 -->
    <div class="right_bottom" v-if="isShowPage == 'animate'"></div>
    <!-- 小草 -->
    <div class="grass" v-if="isShowPage == 'animate'"></div>

    <!-- 点击开启-点击后 -->
    <div
      class="start_btn_after"
      :style="isShowbtnafter ? 'opacity: 1;' : 'opacity: 0;'"
      v-if="isShowPage == 'animate'"
    >
      {{ ali_jiashu_lang == "cn" ? "点击开启" : "Enter" }}
    </div>
    <!-- 点击开启-点击前 -->
    <div
      class="start_btn_front"
      v-if="isShowbtnfront && isShowPage == 'animate'"
      @click.stop="startBtn()"
    >
      {{ ali_jiashu_lang == "cn" ? "点击开启" : "Enter" }}
    </div>

    <div class="lang_box flex" @click="setLang()">
      <div
        :class="
          ali_jiashu_lang == 'cn'
            ? 'lang_bg lang_bg_left'
            : 'lang_bg lang_bg_right'
        "
      ></div>
      <div
        class="lang_cn"
        :style="ali_jiashu_lang == 'cn' ? 'color: white' : ''"
      >
        中文
      </div>
      <div
        class="lang_en"
        :style="ali_jiashu_lang == 'en' ? 'color: white' : ''"
      >
        EN
      </div>
    </div>
  </div>
</template>

<script>
export default {
  inject: ["reload"],
  components: {},
  props: {},
  data() {
    return {
      ali_jiashu_lang: "cn",
      progressbar_width: 0,
      setIntervalFun: null,
      isShowPage: "loading",
      isShowbtnfront: true, // 按钮点击前
      isShowbtnafter: false, // 按钮点击后
      loadingTips: "走过千山万水，与你温暖相逢……", //进度条提示文案cn
      loadingTips_en: "A Letter Home is loading…", //进度条提示文案en
    };
  },
  methods: {
    //设置中英文
    setLang() {
      if (this.ali_jiashu_lang == "cn") {
        window.localStorage.setItem("ali_jiashu_lang", "en");
        this.ali_jiashu_lang = "en";
      } else if (this.ali_jiashu_lang == "en") {
        window.localStorage.setItem("ali_jiashu_lang", "cn");
        this.ali_jiashu_lang = "cn";
      }
      this.reload();
    },
    startBtn() {
      this.isShowbtnfront = false;
      this.isShowbtnafter = true;
      window.localStorage.setItem("ali_jiashu_loadinganimate", true)
      // setTimeout(() => {
      // this.isShowbtnfront = true;
      // this.isShowbtnafter = false;
      setTimeout(() => {
        this.$router.replace({
          name: "lists",
        });
        // }, 150);
      }, 150);
    },
  },
  mounted() {},
  created() {
    let that = this;
    this.ali_jiashu_lang =
      window.localStorage.getItem("ali_jiashu_lang") || "cn";
    that.setIntervalFun = setInterval(() => {
      if (that.progressbar_width < 100) {
        that.progressbar_width += 1;
      } else {
        clearInterval(that.setIntervalFun);
        setTimeout(() => {
          // 是否展示过动画
          if(window.localStorage.getItem("ali_jiashu_loadinganimate") || false){
          // if(false){
            this.$router.replace({
              name: "lists",
            });
          }else{
            that.isShowPage = "animate";
          }
        }, 100);
      }
    }, 10);
  },
  beforeDestroy() {},
};
</script>